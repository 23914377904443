import { UserType } from "src/containers_v2/user-management/user-list/store/slice";
import IPagination from "src/models/pagination";

export class FetchARProjectAuditListParams implements IPagination {
  page: number;
  pageSize: number;
  search: string;
  sort: string;

  constructor(
    page: number = 1,
    pageSize: number = 10,
    search: string = '',
    sort: string = 'asc',
  ) {
    this.page = page;
    this.pageSize = pageSize;
    this.search = search;
    this.sort = sort;
  }

  toString() {
    return `page=${this.page}&size=${this.pageSize}&search=${this.search}&sort=${this.sort}`;
  }
}

export type ARProjectAuditListPageResult = {
  data: UserType[],
  total: number
}
