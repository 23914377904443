import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { SnapsPageResult } from 'src/api_v2/snap/types';
import IPagination from 'src/models/pagination';

export type SnapType = {
  id: number;
  name: string;
  edge?: number;
  beta?: number;
  candidate?: number;
  stable?: number;
  versions?: VersionSnap[]
};

export type VersionSnap = {
  file_name?: string,
  name: string,
  revision: string
};

export interface SnapState extends IPagination {
  total: number;
  data: SnapType[];
  error: string;
  loading: boolean;
  deleted: boolean;
  saved: boolean;
}

export const initialState: SnapState = {
  data: [],
  error: '',
  loading: false,
  deleted: false,
  saved: false,
  total: 0,
  page: 1,
  pageSize: 50,
  sort: 'name|asc',
  search: ''
};

const snapSlice = createSlice({
  name: 'snap',
  initialState,
  reducers: {
    resetState: () => initialState,
    fetchSnaps(state: SnapState) {
      state.loading = true;
      state.error = '';
    },
    fetchAllSnaps(state: SnapState) {
      state.loading = true;
      state.error = '';
    },
    snapFetched(
      state: SnapState,
      action: PayloadAction<SnapsPageResult>
    ) {
      state.data = action.payload.data;
      state.total = action.payload.totalCount;
      state.loading = false;
    },
    snapFetchedError(state: SnapState) {
      state.loading = false;
      state.error = 'Fail to fetch snap!';
    },
    deleteSnap(state: SnapState, action: PayloadAction<number>) {
      state.loading = true;
      state.deleted = false;
      state.error = '';
    },
    snapDeleted(state: SnapState) {
      state.loading = false;
      state.deleted = true;
    },
    snapDeletedError(state: SnapState) {
      state.loading = false;
      state.error = 'Fail to delete snap!';
    },
    saveSnap(state: SnapState, action: PayloadAction<string>) {
      state.loading = true;
      state.error = '';
    },
    snapSaved(state: SnapState) {
      state.loading = false;
      state.saved = true;
    },
    resetSaved(state: SnapState) {
      state.saved = false
    },
    snapSavedError(state: SnapState, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    setCurrentPage(state: SnapState, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setSearch(state: SnapState, action: PayloadAction<string>) {
      state.search = action.payload;
    },
    setSort(state: SnapState, action: PayloadAction<string>) {
      state.sort = action.payload;
    },
    setPageSize(state: SnapState, action: PayloadAction<number>) {
      state.pageSize = action.payload;
    }
  }
});

export default snapSlice.reducer;
export const snapActions = snapSlice.actions;
