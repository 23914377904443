import { call, put, select, takeLatest } from 'redux-saga/effects';
import surveyApi from 'src/api_v2/survey-report';
import {
  FetchSurveyReportParams,
  SurveyReportPageResult
} from 'src/api_v2/survey-report/types';
import {
  selectQuery,
} from './selector';
import { surveyActions } from './slice';

function* fetchSurveyReport() {
  try {
    const {
      mirrorTypeFilter,
      contentFilter,
      genderFilter,
      from,
      to,
      page,
      pageSize,
      sort,
      shopNameFilter,
      selectedMirrors
    } = yield select(selectQuery);

    const queryParams = new FetchSurveyReportParams(
      page,
      pageSize,
      selectedMirrors.join(','),
      sort,
      mirrorTypeFilter,
      contentFilter,
      from.toString(),
      to.toString(),
      genderFilter,
      shopNameFilter
    );
    const result: SurveyReportPageResult = yield call(
      surveyApi.fetchSurveyReport,
      queryParams
    );

    yield put(surveyActions.surveyFetched(result));
  } catch (err) {
    console.log(err);
    yield put(surveyActions.surveyFetchedError());
  }
}

function* fetchTotalSurveyReport() {
  try {
    const {
      selectedMirrors,
      mirrorTypeFilter,
      contentFilter,
      genderFilter,
      from,
      to,
      sort,
      shopNameFilter
    } = yield select(selectQuery);

    const queryParams = new FetchSurveyReportParams(
      1,
      Number.MAX_SAFE_INTEGER,
      selectedMirrors.join(','),
      sort,
      mirrorTypeFilter,
      contentFilter,
      from.toString(),
      to.toString(),
      genderFilter,
      shopNameFilter
    );
    const result: SurveyReportPageResult = yield call(
      surveyApi.fetchSurveyReport,
      queryParams
    );

    yield put(surveyActions.surveyFetched(result));
  } catch (err) {
    console.log(err);
    yield put(surveyActions.surveyFetchedError());
  }
}

function* fetchFilters() {
  try {
    const result = yield call(surveyApi.fetchFilters);

    if (result) {
      yield put(surveyActions.filtersFetched(result));
    }
  } catch (err) {
    console.log(err);
    yield put(surveyActions.filtersFetchedError());
  }
}

function* exportCSV() {
  try {
    const {
      selectedMirrors,
      mirrorTypeFilter,
      contentFilter,
      genderFilter,
      from,
      to,
      page,
      pageSize,
      sort,
      shopNameFilter
    } = yield select(selectQuery);

    const queryParams = new FetchSurveyReportParams(
      page,
      pageSize,
      selectedMirrors.join(','),
      sort,
      mirrorTypeFilter,
      contentFilter,
      from.toString(),
      to.toString(),
      genderFilter,
      shopNameFilter
    );
    const result = yield call(
      surveyApi.exportCSV,
      queryParams
    );

    if(result) {
      yield put(surveyActions.csvExported())
    }

  } catch (err) {
    console.log(err);
  }
}

export default function* watchSurveyReportV2Saga() {
  yield takeLatest(surveyActions.fetchSurveyReport.type, fetchSurveyReport);
  yield takeLatest(
    surveyActions.fetchTotalSurveyReport.type,
    fetchTotalSurveyReport
  );
  yield takeLatest(
    surveyActions.fetchFilters.type,
    fetchFilters
  );
  yield takeLatest(
    surveyActions.exportCSV.type,
    exportCSV
  );
}
