import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import {
  actions,
  DATE_RANGE,
  InterfaceParamsFetchPeopleCounter,
  PeopleCounterRawData,
  PeopleNeutralVideoTrackingRawData
} from './slice';
import { PeopleCounterApi } from 'src/api_v2/people-counter/people-counter-api';
import { selectCustom, selectDateRange, selectMirrorIds, selectShopNameFilter } from './selector';
import moment from 'moment';
import { PayloadAction } from '@reduxjs/toolkit';
import { selectMirrorList } from 'src/containers_v2/mirror-configuration/mirror/mirror-list/store/selector';

function* fetchPeopleCounter(
  groupByHour: PayloadAction<InterfaceParamsFetchPeopleCounter>
) {
  try {
    const peopleCounterApi = new PeopleCounterApi();
    const mirrorIds = yield select(selectMirrorIds);
    const dateRange = yield select(selectDateRange);
    const dateCustom = yield select(selectCustom);
    const shopName = yield select(selectShopNameFilter);

    const [startDate, endDate] = getStartEndDate(dateRange, dateCustom);

    const counterRawData: { total: number; result: PeopleCounterRawData[], lastUpdated: string } =
      yield call(
        peopleCounterApi.fetchPeopleCounter,
        mirrorIds,
        startDate.clone(),
        endDate.clone(),
        groupByHour.payload.groupByHour,
        groupByHour.payload.countByTime,
        shopName
      );
    let updatedResult = [];
    if (counterRawData?.result?.length > 0) {
      // add date empty for range on startDate and endDate
      const addMissingDates = (result, startDate, endDate) => {
        const date = new Date(startDate);
        const endDateObj = new Date(endDate);

        while (date <= endDateObj) {
          const year = date.getFullYear();
          const month = date.getMonth() + 1; // Tháng trong JavaScript tính từ 0 đến 11, cần cộng 1
          const day = date.getDate();
          const entryDate = year * 10000 + month * 100 + day;

          // Kiểm tra xem entryDate đã tồn tại trong mảng result chưa
          const existingEntry = result.find(
            (obj) => obj.entry_date === entryDate
          );
          if (!existingEntry) {
            const newObject = {
              entry_date: entryDate,
              total: '0',
              age_1: '0',
              age_2: '0',
              age_3: '0',
              age_4: '0',
              age_5: '0',
              gender_male: '0',
              gender_famale: '0',
              gender_na: '0',
              emotion_surprise: '0',
              emotion_angry: '0',
              emotion_happy: '0',
              emotion_disgusty: '0',
              emotion_fear: '0',
              emotion_sad: '0',
              emotion_neutral: '0',
              emotion_na: '0'
            };

            result.push(newObject);
          }

          date.setDate(date.getDate() + 1); // Tăng ngày lên 1
        }

        return result;
      };
      updatedResult = addMissingDates(
        counterRawData.result,
        startDate.format('YYYY-MM-DD'),
        endDate.format('YYYY-MM-DD')
      );
    }

    yield delay(2000);
    yield put(
      actions.peopleCounterRawDataFetched({
        total: counterRawData.total,
        result: updatedResult,
        lastUpdated: counterRawData.lastUpdated
      })
    );
  } catch (err) {
    yield put(actions.peopleCounterFetchedError());
  }
}

function* fetchPeopleNeutralVideoTracking() {
  try {
    console.log('fetchPeopleNeutralVideoTracking');
    const peopleCounterApi = new PeopleCounterApi();
    const mirrorIds = yield select(selectMirrorIds);
    const dateRange = yield select(selectDateRange);
    const dateCustom = yield select(selectCustom);

    const [startDate, endDate] = getStartEndDate(dateRange, dateCustom);

    const videoRawData: PeopleNeutralVideoTrackingRawData[] = yield call(
      peopleCounterApi.fetchPeopleVideoTracking,
      mirrorIds,
      startDate.clone(),
      endDate.clone()
    );

    yield delay(2000);
    yield put(actions.peopleNeutralVideoFetched(videoRawData));
  } catch (err) {
    yield put(actions.peopleNeutralVideoFetchedError());
  }
}

export const getStartEndDate = (dateRange, dateCustom) => {
  const current =
    (dateCustom?.from && dateRange === DATE_RANGE.CUSTOM) ||
    (dateCustom?.from && dateRange === DATE_RANGE.CUSTOM_DAY)
      ? moment(dateCustom?.from)
      : moment();

  let startDate = current.clone().startOf('day');
  let endDate = current.clone().endOf('day');
  switch (dateRange) {
    case DATE_RANGE.SEVEN_DAYS_AGO:
      startDate.subtract(6, 'day');
      break;
    case DATE_RANGE.SEVEN_DAYS_AGO_TIME:
      startDate.subtract(6, 'day');
      break;
    case DATE_RANGE.THIRDTY_DAYS_AGO:
      startDate.subtract(1, 'month');
      break;
    case DATE_RANGE.THIRDTY_DAYS_AGO_TIME:
      startDate.subtract(1, 'month');
      break;
    case DATE_RANGE.CUSTOM:
      endDate = dateCustom?.to
        ? moment(dateCustom?.to).clone().endOf('day')
        : moment().clone().endOf('day');
      break;
    case DATE_RANGE.CUSTOM_DAY:
      endDate = dateCustom?.to
        ? moment(dateCustom?.to).clone().endOf('day')
        : moment().clone().endOf('day');
      break;
  }
  return [startDate, endDate];
};

function* exportCSV(
  data: PayloadAction<{
    groupByHour: boolean;
    isShowTotal: boolean;
    exportAll: boolean;
    // mirrorNames: string[];
  }>
) {
  try {
    const mirrorIds = yield select(selectMirrorIds);
    const dateRange = yield select(selectDateRange);
    const dateCustom = yield select(selectCustom);
    const shopName = yield select(selectShopNameFilter);

    const [startDate, endDate] = getStartEndDate(dateRange, dateCustom);

    const peopleCounterApi = new PeopleCounterApi();

    const counterRawData: { total: number; result: PeopleCounterRawData[] } =
      yield call(
        peopleCounterApi.exportCSV,
        mirrorIds,
        startDate.clone(),
        endDate.clone(),
        data.payload.groupByHour,
        data.payload.isShowTotal,
        data.payload.exportAll,
        shopName
      );

    if (counterRawData) {
      yield put(actions.csvExported());
    }
  } catch (err) {
    console.log(err);
    yield put(actions.peopleCounterFetchedError());
  }
}

function* exportNeutralVideoTracking(
  data: PayloadAction<{
    groupByHour: boolean;
    isShowTotal: boolean;
    exportAll: boolean;
  }>
) {
  try {
    const mirrorIds = yield select(selectMirrorIds);
    const dateRange = yield select(selectDateRange);
    const dateCustom = yield select(selectCustom);
    const mirrorsList = yield select(selectMirrorList);

    const [startDate, endDate] = getStartEndDate(dateRange, dateCustom);

    const peopleCounterApi = new PeopleCounterApi();

    const videoRawData: PeopleNeutralVideoTrackingRawData[] = yield call(
      peopleCounterApi.exportNeutralVideoTracking,
      mirrorIds,
      startDate.clone(),
      endDate.clone()
    );

    if (videoRawData) {
      yield put(actions.exportedNeutralVideoTracking());
    }
  } catch (err) {
    console.log(err);
    yield put(actions.peopleCounterFetchedError());
  }
}

function* fetchFilters() {
  const peopleCounterApi = new PeopleCounterApi();
  try {
    const result = yield call(peopleCounterApi.fetchFilters);

    if (result) {
      yield put(actions.filtersFetched(result));
    }
  } catch (err) {
    console.log(err);
    yield put(actions.fetchFiltersError());
  }
}

export default function* watchPeopleCounterReport() {
  yield takeLatest(actions.fetchPeopleCounter.type, fetchPeopleCounter);
  yield takeLatest(actions.fetchFilters.type, fetchFilters);
  yield takeLatest(
    actions.fetchPeopleNeutralVideoTracking.type,
    fetchPeopleNeutralVideoTracking
  );
  yield takeLatest(actions.exportCSV.type, exportCSV);
  yield takeLatest(actions.exportNeutralVideoTracking.type, exportNeutralVideoTracking);
}
