import { ARTemplateType } from "src/containers_v2/ar_creator/ARTemplate/ar-template-list/store/slice";
import { UserType } from "src/containers_v2/user-management/user-list/store/slice";
import IPagination from "src/models/pagination";

export class FetchARTemplateListParams implements IPagination {
  page: number;
  pageSize: number;
  search: string;
  sort: string;
  type: number | "all";
  enable: boolean | "all";

  constructor(
    page: number = 1,
    pageSize: number = 10,
    search: string = '',
    sort: string = 'asc',
    type: number | "all" = "all",
  ) {
    this.page = page;
    this.pageSize = pageSize;
    this.search = search;
    this.sort = sort;
    this.type = type;
  }

  toString() {
    return `page=${this.page}&size=${this.pageSize}&search=${this.search}&sort=${this.sort}&type=${this.type}`;
  }
}

export type ARTemplateListPageResult = {
  data: ARTemplateType[],
  totalItems?: number
}
