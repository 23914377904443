import { call, put, select, takeLatest } from 'redux-saga/effects';
import { PeopleCounterApi } from 'src/api_v2/people-counter/people-counter-api';
import {
  SummaryReportPageResult,
  FetchSummaryReportParams
} from 'src/api_v2/people-counter/types';
import { selectQuery } from './selector';
import { summaryActions } from './slice';
import moment from 'moment';

function* fetchSummaryReport() {
  try {
    const {
      search,
      mirrorTypeFilter,
      contentFilter,
      from,
      to,
      page,
      pageSize,
      sort,
      shopNameFilter
    } = yield select(selectQuery);

    const startDateFormat = moment(from,'x').format('YYYYMMDD');
    const endDateFormat = moment(to,'x').format('YYYYMMDD');
    
    const summaryApi = new PeopleCounterApi();
    const queryParams = new FetchSummaryReportParams(
      page,
      pageSize,
      search,
      sort,
      mirrorTypeFilter,
      contentFilter,
      startDateFormat,
      endDateFormat,
      shopNameFilter
    );
    const result: SummaryReportPageResult = yield call(
      summaryApi.fetchSummaryReport,
      queryParams
    );

    yield put(summaryActions.summaryFetched(result));
  } catch (err) {
    console.log(err);
    yield put(summaryActions.summaryFetchedError());
  }
}

function* fetchTotalSummaryReport() {
  try {
    const { search, mirrorTypeFilter, contentFilter, from, to, sort, shopNameFilter } =
      yield select(selectQuery);

      const startDateFormat = moment(from,'x').format('YYYYMMDD');
      const endDateFormat = moment(to,'x').format('YYYYMMDD');

    const queryParams = new FetchSummaryReportParams(
      1,
      Number.MAX_SAFE_INTEGER,
      search,
      sort,
      mirrorTypeFilter,
      contentFilter,
      startDateFormat,
      endDateFormat,
      shopNameFilter
    );
    const summaryApi = new PeopleCounterApi();
    const result: SummaryReportPageResult = yield call(
      summaryApi.fetchSummaryReport,
      queryParams
    );

    yield put(summaryActions.summaryFetched(result));
  } catch (err) {
    console.log(err);
    yield put(summaryActions.summaryFetchedError());
  }
}

function* fetchFilters() {
  const peopleCounterApi = new PeopleCounterApi();
  try {
    const result = yield call(peopleCounterApi.fetchFilters);

    if (result) {
      yield put(summaryActions.filtersFetched(result));
    }
  } catch (err) {
    console.log(err);
    yield put(summaryActions.fetchFiltersError());
  }
}

export default function* watchSummaryReportV2Saga() {
  yield takeLatest(summaryActions.fetchSummary.type, fetchSummaryReport);
  yield takeLatest(
    summaryActions.fetchTotalSummary.type,
    fetchTotalSummaryReport
  );
  yield takeLatest(summaryActions.fetchFilters.type, fetchFilters);
}
