import { createSelector } from '@reduxjs/toolkit';
import moment from 'moment';
import { RootState } from 'src/redux/store/configureStore';
import {
    COUNTER_TYPE,
    DATE_RANGE,
    PeopleCounterData,
    PeopleCounterRawData,
    PeopleCounterReportState,
    PeopleNeutralVideoTrackingRawData
} from './slice';

const selectDomain = (state: RootState) => {
    return state.peopleCounterReport;
};

export const selectMirrorIds = createSelector(
    [selectDomain],
    (peopleCounterReport: PeopleCounterReportState) =>
        peopleCounterReport.mirrorIds
);

export const selectTotal = createSelector(
    [selectDomain],
    (peopleCounterReport: PeopleCounterReportState) =>
        peopleCounterReport.total
);

export const selectLoading = createSelector(
    [selectDomain],
    (peopleCounterReport: PeopleCounterReportState) =>
        peopleCounterReport.loading
);

export const selectDateRange = createSelector(
    [selectDomain],
    (peopleCounterReport: PeopleCounterReportState) =>
        peopleCounterReport.dateRange
);

export const selectCustom = createSelector(
    [selectDomain],
    (peopleCounterReport: PeopleCounterReportState) =>
        peopleCounterReport.customDate
);

export const selectShopNameFilter = createSelector(
    [selectDomain],
    (peopleCounterReport: PeopleCounterReportState) =>
        peopleCounterReport.shopNameFilter
);

export const selectPeopleCounterRawData = createSelector(
    [selectDomain],
    (peopleCounterReport: PeopleCounterReportState) => peopleCounterReport.counterRawData
);

export const selectCounterType = createSelector(
    [selectDomain],
    (peopleCounterReport: PeopleCounterReportState) =>
        peopleCounterReport.counterType
);

export const selectPeopleCounterDataByCounter = createSelector(
    [selectPeopleCounterRawData, selectCounterType],
    (counterRawData: PeopleCounterRawData[], counterType: COUNTER_TYPE) => {
        return counterRawData;
    }
);

export const selectToExportPeopleCounterRawData = createSelector(
    [selectPeopleCounterDataByCounter],
    (counterRawData: PeopleCounterRawData[]) => {
        return counterRawData.map((x) => ({
            ...x,
            entry_time: moment(x.entry_time).format('YYYY-MM-DD HH:mm:ss'),
            exit_time: moment(x.exit_time).format('YYYY-MM-DD HH:mm:ss'),
            duration: moment(x.exit_time).diff(moment(x.entry_time), 'seconds')
        }));
    }
);

export const selectPeopleCounterData = createSelector(
    [selectPeopleCounterDataByCounter, selectDateRange, selectCustom],
    (rawDataByCounter: any[], dateRange: DATE_RANGE, dateCustom: {
        from: string,
        to: string
    }) => {
        return rawDataByCounter
    }
);


export const selectPeopleNeutralVideoTrakingRawData = createSelector(
    [selectDomain],
    (peopleCounterReport: PeopleCounterReportState) => peopleCounterReport.videoTrackingRawData
);

export const selectGroupByHour = createSelector(
    [selectDomain],
    (peopleCounterReport: PeopleCounterReportState) => peopleCounterReport.groupByHour
);

export const selectCountByTime = createSelector(
    [selectDomain],
    (peopleCounterReport: PeopleCounterReportState) => peopleCounterReport.countByTime
);

export const selectShopNames = createSelector(
    [selectDomain],
    (peopleCounterReport: PeopleCounterReportState) => peopleCounterReport.shopNames
);

export const selectExporting = createSelector(
    [selectDomain],
    (peopleCounterReport: PeopleCounterReportState) => peopleCounterReport.exporting
);

export const selectToExportPeopleNeutralVideoTrackingRawData = createSelector(
    [selectPeopleNeutralVideoTrakingRawData],
    (videoTrackingRawData: PeopleNeutralVideoTrackingRawData[]) => {
        return videoTrackingRawData.map((x) => ({
            ...x,
            datetime_utc_milis: moment(x.datetime_utc_milis).format('YYYY-MM-DD HH:mm:ss'),
        }));
    }
);

export const selectMirrors = createSelector(
    [selectDomain],
    (peopleCounterReport: PeopleCounterReportState) => peopleCounterReport.mirrors
);

export const selectLastUpdated = createSelector(
    [selectDomain],
    (peopleCounterReport: PeopleCounterReportState) =>
        peopleCounterReport.lastUpdated
);