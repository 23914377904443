import { ClickTrackingType } from 'src/containers_v2/user-click-tracking/store/model';
import IPagination from 'src/models/pagination';

export class FetchClickTrackingReportParams implements IPagination {
  page: number;
  pageSize: number;
  search: string; // mirrorId
  sort: string;
  mirrorType: string;
  content: string;
  from: string;
  to: string;
  shopName: string;

  constructor(
    page: number = 1,
    pageSize: number = 20,
    search: string = '',
    sort: string = 'mirrorId|asc',
    mirrorType: string,
    content: string,
    from: string,
    to: string,
    shopName: string,
  ) {
    this.page = page;
    this.pageSize = pageSize;
    this.search = search;
    this.sort = sort;
    this.mirrorType = mirrorType;
    this.content = content;
    this.from = from;
    this.to = to;
    this.shopName = shopName;
  }

  toString() {
    return `page=${this.page}&size=${this.pageSize}&mirrorId=${this.search}&mirrorType=${this.mirrorType}&content=${this.content}&from=${this.from}&to=${this.to}&sort=${this.sort}&shopName=${this.shopName}`;
  }
}

export type ClickTrackingReportPageResult = {
  data: ClickTrackingType[];
  totalCount: number;
};

export type FiltersResult = {
  mirrorContent: { [key: number]: string };
  gameNameEngList?: { [key: number]: string };
  gameNameJpnList?: { [key: number]: string };
  mirrorTypes: string[];
  mirrorIds: string[];
  shopNames: string[];
};
