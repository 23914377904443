import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "src/redux/store/configureStore";
import { MirrorListState } from "./slice";

const selectDomain = (state: RootState) => {
  return state.mirrorList;
};

export const selectQuery = createSelector(
  [selectDomain],
  (state: MirrorListState) => ({
    search: state.search,
    sort: state.sort,
    page: state.page,
    pageSize: state.pageSize,
    mirrorIdFilter: state.mirrorIdFilter,
    shopNameFilter: state.shopNameFilter,
    profileFilter: state.profileFilter,
    statusFilter: state.statusFilter
  })
);

export const selectMirrorList = createSelector(
  [selectDomain],
  (state: MirrorListState) => state.data
);

export const selectFilters = createSelector(
  [selectDomain],
  (state: MirrorListState) => state.filters
)

export const selectTotal = createSelector(
  [selectDomain],
  (state: MirrorListState) => state.total
)

export const selectDeleted = createSelector(
  [selectDomain],
  (state: MirrorListState) => state.deleted
)

export const selectLoading = createSelector(
  [selectDomain],
  (state: MirrorListState) => state.loading
)

export const selectError = createSelector(
  [selectDomain],
  (state: MirrorListState) => state.error
)

export const selectMirrorNames = createSelector(
  [selectDomain],
  (state: MirrorListState) => state.mirrorNames
)