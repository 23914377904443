import { MirrorType } from 'src/containers_v2/categories/mirror-type/mirror-type-list/store/slice';
import { SurveyType } from 'src/containers_v2/survey/store/model';
import IPagination from 'src/models/pagination';

export class FetchMirrorTypeParams implements IPagination {
  page: number;
  pageSize: number;
  search: string;
  sort: string;

  constructor(
    page: number = 1,
    pageSize: number = 20,
    search: string = '',
    sort: string = 'name|asc',
    
  ) {
    this.page = page;
    this.pageSize = pageSize;
    this.search = search;
    this.sort = sort;
  }

  toString() {
    return `page=${this.page}&size=${this.pageSize}&search=${this.search}&sort=${this.sort}`;
  }
}

export type MirrorTypePageResult = {
  data: MirrorType[];
  totalItems: number;
};

export type MirrorTypeResult = {
  mirrorTypes: string[];
};
