import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import mirrorTypeApi from 'src/api_v2/mirror-type';
import { mirrorTypeConfigActions, MirrorTypeConfigurationType } from './slice';

function* saveMirrorType(action: PayloadAction<MirrorTypeConfigurationType>) {
  try {
    const result = yield call(mirrorTypeApi.saveMirrorType, action.payload);

    if (result) {
      yield put(mirrorTypeConfigActions.mirrorTypeSaved());
    } else {
      yield put(mirrorTypeConfigActions.mirrorTypeSavedError('Fail to save mirror type'));
    }
  } catch (err) {
    if (err.response) {
      const message = err.response.data?.message as string[];
      yield put(mirrorTypeConfigActions.mirrorTypeSavedError(message[0]));
    }
  }
}

function* fetchMirrorType(action: PayloadAction<string>) {
  try {
    const result: MirrorTypeConfigurationType = yield call(mirrorTypeApi.getMirrorTypeById, action.payload);

    if(result) {
      yield put(mirrorTypeConfigActions.mirrorTypeFetched(result))
    } else {
      yield put(mirrorTypeConfigActions.mirrorTypeFetchedError())
    }
  } catch (err) {
    yield put(mirrorTypeConfigActions.mirrorTypeFetchedError())
  }
}

export default function* watchMirrorTypeConfigurationSaga() {
  yield takeLatest(mirrorTypeConfigActions.saveMirrorType.type, saveMirrorType);
  yield takeLatest(mirrorTypeConfigActions.fetchMirrorType.type, fetchMirrorType);
}
