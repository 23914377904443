import React, { FC, useState, createContext } from 'react';
type SidebarContext = {
  sidebarToggle: any;
  toggleSidebar: () => void;
  inforBoxSidebar: any;
  setInforSidebar: ({ width }: { width: number }) => void;
  open: boolean;
  handleDrawerOpen: () => void;
  handleDrawerClose: () => void;
  activeTitle: string;
  handleActiveTitle: (data:string) => void;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SidebarContext = createContext<SidebarContext>(
  {} as SidebarContext
);

export const SidebarProvider: FC = ({ children }) => {
  const [sidebarToggle, setSidebarToggle] = useState(false);
  const [inforBoxSidebar, setInforBoxSidebar] = useState({});
  const [open, setOpen] = React.useState(true);
  const [activeTitle, setActiveTitle] = useState<string>('');

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };
  
  const handleActiveTitle = (data) => {
    setActiveTitle(data);
  };

  const setInforSidebar = (data) => {
    setInforBoxSidebar({
      ...inforBoxSidebar,
      ...data
    });
  };

  return (
    <SidebarContext.Provider
      value={{
        sidebarToggle,
        toggleSidebar,
        setInforSidebar,
        inforBoxSidebar,
        open,
        handleDrawerOpen,
        handleDrawerClose,
        activeTitle,
        handleActiveTitle
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};
