import { BasePeopleCounterApi } from './api';
import { AxiosResponse } from 'axios';
import moment, { Moment } from 'moment';
import {
  DATE_RANGE,
  PeopleCounterRawData,
  PeopleNeutralVideoTrackingRawData
} from 'src/containers/people-counter-report/store/slice';
import {
  GetMirrorsResult,
  UserResponse,
  UserRoles
} from './mirrors-console-api';

class PeopleCounterApi extends BasePeopleCounterApi {
  fetchPeopleCounter = async (
    mirrorId: string,
    startDate: Moment,
    endDate: Moment
  ): Promise<PeopleCounterRawData[]> => {
    try {
      const startUtcMilis = startDate.utc().valueOf();
      const endUtcMilis = endDate.utc().valueOf();
      const dateQueryParams = `&startUtcMilis=${startUtcMilis}&endUtcMilis=${endUtcMilis}`;

      const response: AxiosResponse = await this.axios.get(
        `/people-counter?mirrorId=${mirrorId}${dateQueryParams}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }
      );

      if (response.status !== 200) {
        throw new Error(response.statusText);
      }

      return response.data.map(
        (x) =>
          ({
            ...x,
            entry_time: Number(x.entry_time),
            exit_time: Number(x.exit_time)
          } as PeopleCounterRawData[])
      );
    } catch (err) {
      console.log(err);
    }
  };

  fetchAllMirrors = async (user: UserResponse): Promise<GetMirrorsResult[]> => {
    const path = `mirrors/${
      user.role.includes(UserRoles.toString(UserRoles.Mirrors_owner))
        ? user.id.toString()
        : ''
    }`;
    const response: AxiosResponse = await this.axios.get(path, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });

    if (response.status !== 200) {
      throw new Error(response.statusText);
    }

    return response.data;
  };

  fetchPeopleVideoTracking = async (
    mirrorId: string,
    startDate: Moment,
    endDate: Moment
  ): Promise<PeopleNeutralVideoTrackingRawData[]> => {
    try {
      const startUtcMilis = startDate.utc().valueOf();
      const endUtcMilis = endDate.utc().valueOf();
      const dateQueryParams = `&startUtcMilis=${startUtcMilis}&endUtcMilis=${endUtcMilis}`;

      const response: AxiosResponse = await this.axios.get(
        `/people-video-tracking?mirrorId=${mirrorId}${dateQueryParams}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }
      );

      if (response.status !== 200) {
        throw new Error(response.statusText);
      }

      return response.data.map(
        (x) =>
          ({
            ...x,
            datetime_utc_milis: Number(x.datetime_utc_milis)
          } as PeopleNeutralVideoTrackingRawData[])
      );
    } catch (err) {
      console.log(err);
    }
  };
}

export { PeopleCounterApi };
