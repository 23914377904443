import { AxiosResponse } from 'axios';
import agent from 'src/api_v2/base';
import { UpdateContentVersion } from 'src/containers_v2/categories/content/content-configuration/store/query';
import {
  ContentType,
  ContentsFiltersType
} from 'src/containers_v2/categories/content/content-list/store/slice';
import { FetchContentsParams } from './types';

class ContentApi {
  async fetchContents(
    params: FetchContentsParams
  ): Promise<{ data: ContentType[]; totalItems: number }> {
    try {
      const response: AxiosResponse = await agent.get(
        `/contents?${params.toString()}`
      );

      if (response.status === 200) {
        return response.data;
      }

      return undefined;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async deleteContent(id: number) {
    try {
      const response: AxiosResponse = await agent.delete(`/contents/${id}`);

      if (response.status !== 200) {
        return false;
      }

      return true;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async saveContent(payload: ContentType) {
    try {
      const response: AxiosResponse = await agent.post(`/contents`, payload);

      if (response.status !== 200 && response.status !== 201) {
        return false;
      }

      return true;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async addContent(contents: FormData) {
    try {
      const response: AxiosResponse = await agent.post(`/contents`, contents);

      if (response.status !== 200 && response.status !== 201) {
        return {
          valid: false,
          data: response
        };
      }

      return {
        valid: true,
        data: response
      };
    } catch (err) {
      return {
        valid: false,
        data: err
      };
    }
  }

  async updateContent(contents: FormData) {
    try {
      const response: AxiosResponse = await agent.put(`/contents`, contents);

      if (response.status !== 200 && response.status !== 204) {
        return {
          valid: false,
          data: response
        };
      }

      return {
        valid: true,
        data: response
      };
    } catch (err) {
      return {
        valid: false,
        data: err
      };
    }
  }

  async getContent(id: string): Promise<any> {
    try {
      const response: AxiosResponse = await agent.get(`/contents/${id}`);

      if (response.status === 200) {
        return response.data;
      }

      return undefined;
    } catch (err) {
      throw err;
    }
  }

  async getContentVersions(contentName: string): Promise<any> {
    try {
      const response: AxiosResponse = await agent.get(
        `/contents/versions/${contentName}`
      );

      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      throw err;
    }
  }

  async updateContentVersion(payload: UpdateContentVersion): Promise<boolean> {
    try {
      const response: AxiosResponse = await agent.put(
        `/contents/versions/`,
        payload
      );

      if (response.status === 200) {
        return true;
      }
      return false;
    } catch (err) {
      throw err;
    }
  }

  async fetchContentFilters(): Promise<ContentsFiltersType> {
    try {
      const response: AxiosResponse = await agent.get(`/contents/filters`);

      if (response.status === 200) {
        let data = response.data as ContentsFiltersType;
        data.types = data.types.sort((a, b) => ('' + a).localeCompare(b));

        return data;
      }

      return undefined;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
}

const contentApi = new ContentApi();
export default contentApi;
