import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { CONTENT_TYPE, LED_COLOR } from '../../content-list/store/slice';

export type ContentConfigurationType = {
  id?: string
  description?: string;
  name?: string;
  gameNameEng?: string,
  gameNameJpn?: string,
  gameNameVi?: string,
  descriptionEng?: string,
  descriptionJpn?: string,
  descriptionVi?: string,
  fileLogo?: File,
  fileLogoName?: string
  ledColor?: LED_COLOR
  type: CONTENT_TYPE
};

export interface ContentConfigurationState {
  loading?: boolean;
  error?: string;
  saved?: boolean;
  editingContent?: ContentConfigurationType;
}

export const initialState: ContentConfigurationState = {
  loading: false,
  error: '',
  saved: false,
  editingContent: undefined
};

const contentConfigurationlice = createSlice({
  name: 'contentConfiguration',
  initialState,
  reducers: {
    reset:() => initialState,
    saveContent(state: ContentConfigurationState, action: PayloadAction<ContentConfigurationType>) {
      state.loading = true;
      state.error = ''
      state.saved = false
    },
    contentSaved(state: ContentConfigurationState) {
      state.loading = false;
      state.saved = true
    },
    contentSavedError(state: ContentConfigurationState, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload
    },
    getContent(state: ContentConfigurationState, action: PayloadAction<string>) {
      state.loading = true
    },
    contentGot(state: ContentConfigurationState, action: PayloadAction<ContentConfigurationType>) {
      state.loading = false;
      state.editingContent = action.payload
    },
    contentGotError(state: ContentConfigurationState, action: PayloadAction<string>) {
      state.loading = false
      state.error = action.payload
    },
    resetError(state: ContentConfigurationState) {
      state.error = '';
    }
  }
});

export const { actions, reducer, name: sliceKey } = contentConfigurationlice;
