import { createSelector } from '@reduxjs/toolkit';
import {
  questions,
  SurveyReportState
} from 'src/containers_v2/survey/store/slice';
import { RootState } from 'src/redux/store/configureStore';
import _ from 'lodash';
import { userReviewMap } from 'src/utils/constants';
import {
  AgeRangeCountType,
  GenderCountType,
  ReviewGroupByQuestionType,
  SurveyType
} from './model';

const selectDomain = (state: RootState) => {
  return state.surveyReportV2;
};

export const selectSurveyReport = createSelector(
  [selectDomain],
  (state: SurveyReportState) => state.data
);

export const selectSearch = createSelector(
  [selectDomain],
  (state: SurveyReportState) => state.search
);

export const selectSort = createSelector(
  [selectDomain],
  (state: SurveyReportState) => state.sort
);

export const selectPage = createSelector(
  [selectDomain],
  (state: SurveyReportState) => state.page
);

export const selectPageSize = createSelector(
  [selectDomain],
  (state: SurveyReportState) => state.pageSize
);

export const selectTotal = createSelector(
  [selectDomain],
  (state: SurveyReportState) => state.total
);

export const selectLoading = createSelector(
  [selectDomain],
  (state: SurveyReportState) => state.loading
);

export const selectMirrorTypeFitler = createSelector(
  [selectDomain],
  (state: SurveyReportState) => state.mirrorTypeFilter
);

export const selectContentFitler = createSelector(
  [selectDomain],
  (state: SurveyReportState) => state.contentFilter
);

export const selectFrom = createSelector(
  [selectDomain],
  (state: SurveyReportState) => state.from
);

export const selectTo = createSelector(
  [selectDomain],
  (state: SurveyReportState) => state.to
);

export const selectContents = createSelector(
  [selectDomain],
  (state: SurveyReportState) => state.contents
);

export const selectMirrorTypes = createSelector(
  [selectDomain],
  (state: SurveyReportState) => state.mirrorTypes
);

export const selectGenderFilter = createSelector(
  [selectDomain],
  (state: SurveyReportState) => state.genderFilter
);

export const selectQuery = createSelector(
  [selectDomain],
  (state: SurveyReportState) => ({
    search: state.search,
    sort: state.sort,
    page: state.page,
    pageSize: state.pageSize,
    from: state.from,
    to: state.to,
    mirrorTypeFilter: state.mirrorTypeFilter,
    contentFilter: state.contentFilter,
    genderFilter: state.genderFilter,
    shopNameFilter: state.shopNameFilter,
    selectedMirrors: state.selectedMirrors
  })
);

/* 
  [
    {
      label: 'aaaaaaaffffff',
      reviews: [
        {
          label: 'dfdfa',
          count: 23
        }
      ]
    }
  ]
*/
export const countReviewsGroupByQuestion = createSelector(
  [selectDomain],
  (state: SurveyReportState) => {
    const result: ReviewGroupByQuestionType[] = [];

    const dataGroupByQuestion = _.groupBy(
      state.data,
      (x: SurveyType) => x.questionNo
    );

    const questionKeys = Object.keys(dataGroupByQuestion);

    questionKeys.forEach((key: string, index: number) => {
      const questionItem: ReviewGroupByQuestionType = {
        label: questions[index],
        reviews: []
      };

      if (dataGroupByQuestion[key]) {
        const dataGroupByReview = _.groupBy(
          dataGroupByQuestion[key],
          (x: SurveyType) => x.review
        );

        Object.keys(dataGroupByReview).forEach((key: string, index: number) => {
          questionItem.reviews.push({
            label: userReviewMap[key],
            count: dataGroupByReview[key].length
          });
        });
      }

      result.push(questionItem);
    });

    return result;
  }
);

export const countGenders = createSelector(
  [selectDomain],
  (state: SurveyReportState) => {
    const result: GenderCountType[] = [];
    const genders = ['male', 'female', 'unknown'];

    genders.forEach((gender: string, index: number) => {
      const dataByGenders = state.data.filter(
        (x) => gender === x.gender.toLowerCase()
      );
      result.push({
        name: gender,
        value: dataByGenders.length
      });
    });

    return result;
  }
);

export const countAgeRanges = createSelector(
  [selectDomain],
  (state: SurveyReportState) => {
    const ageRanges = [
      { min: 0, max: 20 },
      { min: 21, max: 30 },
      { min: 31, max: 40 },
      { min: 41, max: 50 },
      {
        min: 51,
        max: 60
      },
      { min: 61, max: 100 }
    ];

    const result: AgeRangeCountType[] = [];

    ageRanges.forEach((range) => {
      const dataByAgeRange = state.data.filter(
        (x) => range.min <= x.age && x.age <= range.max
      );

      result.push({
        name: `${range.min} - ${range.max}`,
        value: dataByAgeRange.length
      });
    });

    return result;
  }
);


export const selectExporting = createSelector(
  [selectDomain],
  (state: SurveyReportState) => state.exporting
);

export const selectFilters = createSelector(
  [selectDomain],
  (state: SurveyReportState) => ({
    contents: state.contents,
    mirrorTypes: state.mirrorTypes,
    mirrorIds: state.mirrorIds,
    shopNames: state.shopNames,
  })
);

export const selectLastUpdated = createSelector(
  [selectDomain],
  (state: SurveyReportState) => state.data[0]?.datetime
);