import { createSlice } from '@reduxjs/toolkit';

export const HEADER_HEIGHT = 64;
export const MAIN_PADDING = 24;
export const ACTION_HEIGHT = 52.5; // include margin 16px
export const MAIN_HEIGHT = window.innerHeight - HEADER_HEIGHT - MAIN_PADDING * 2;
export const LIST_PADDING = 48;
export const LIST_FILTER_HEIGHT = 69.13; // include marginBottom 16px
export const LIST_PAGINATION_HEIGHT = 48;
export const TAB_MENU_HEIGHT = 38;
export const TAB_CONTENT_PADDING = 32;

/** PROFILE  **/
export const PROFILE_TOP_HEIGHT = 460; // include 16px margin bottom
/** PROFILE  **/

/** MIRROR  **/
export const MIRROR_TOP_HEIGHT = 361.9; // include 16px margin bottom
/** MIRROR  **/

const listHeight = (
  mainHeight: number,
  hasBottomAction: boolean,
  hasFilter: boolean,
  hasPagination: boolean
) => {
  let result = mainHeight - LIST_PADDING;

  if (hasBottomAction) {
    result -= ACTION_HEIGHT;
  }

  if (hasFilter) {
    result -= LIST_FILTER_HEIGHT;
  }

  if (hasPagination) {
    result -= LIST_PAGINATION_HEIGHT;
  }

  return result;
};

export interface UIConfigState {
  mainUI: {
    1: number; // default hieght
    2: number; // height for configuration with action
    3: number; // height for list with filter, pagination, action
    4: number; // height for list with pagination, action
    5: number; // height for list with action
    6: number; // height for list with filter, pagination
  };
  profileUI: {
    tabMinHeight: number;
    tabHeight: number;
  };
  mirrorUI: {
    tabMinHeight: number;
    tabHeight: number;
  };
}

export const initialState: UIConfigState = {
  mainUI: {
    1: MAIN_HEIGHT,
    2: MAIN_HEIGHT - ACTION_HEIGHT,
    3: listHeight(MAIN_HEIGHT, true, true, true),
    4: listHeight(MAIN_HEIGHT, true, false, true),
    5: listHeight(MAIN_HEIGHT, true, false, false),
    6: listHeight(MAIN_HEIGHT, false, true, true)
  },
  profileUI: {
    tabMinHeight: 300,
    tabHeight:
      MAIN_HEIGHT -
      ACTION_HEIGHT -
      PROFILE_TOP_HEIGHT -
      TAB_MENU_HEIGHT
  },
  mirrorUI: {
    tabMinHeight: 300,
    tabHeight:
      MAIN_HEIGHT -
      ACTION_HEIGHT -
      MIRROR_TOP_HEIGHT -
      TAB_MENU_HEIGHT
  }
};

const UISlice = createSlice({
  name: 'uiConfig',
  initialState,
  reducers: {
    calculate(state: UIConfigState) {
      const defaultHeight =
        window.innerHeight - HEADER_HEIGHT - MAIN_PADDING * 2;
      return {
        ...state,
        mainUI: {
          1: defaultHeight,
          2: defaultHeight - ACTION_HEIGHT,
          3: listHeight(defaultHeight, true, true, true),
          4: listHeight(defaultHeight, true, false, true),
          5: listHeight(defaultHeight, true, false, false),
          6: listHeight(defaultHeight, false, true, true)
        },
      };
    }
  }
});

export default UISlice.reducer;
export const UIActions = UISlice.actions;
