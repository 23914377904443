import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { QrImagesPageResult } from 'src/api_v2/qr-image/types';
import { ProfileType } from 'src/containers_v2/mirror-configuration/profile/profile-list/store/slice';
import IPagination from 'src/models/pagination';

export type LED_COLOR = 'green' | 'yellow' | 'red' | ""

export type QrImageType = {
  id: number;
  name?: string;
  qrImageName?: string;
  fileLocation?: string;
  qrDuration?: string;
  qrDescription?: string;
  size?: string;
  expiredDate?: string;
  profiles?: ProfileType[];
  createdAt?: string;
  qrDescriptionColor?: string;
};

export interface QrImageState extends IPagination {
  total: number;
  data: QrImageType[];
  error: string;
  loading: boolean;
  deleted: boolean;
  saved: boolean;
  expiredDate?: string;
  includeProfiles?: boolean,
  addedQrImageIds?: string
}

export const initialState: QrImageState = {
  data: [],
  error: '',
  loading: false,
  deleted: false,
  saved: false,
  total: 0,
  page: 1,
  pageSize: 50,
  sort: 'qrImageName|asc',
  search: '',
  expiredDate: '',
  includeProfiles: false,
  addedQrImageIds: ''
};

const qrImageSlice = createSlice({
  name: 'qr-images',
  initialState,
  reducers: {
    resetState: () => initialState,
    fetchQrImages(state: QrImageState) {
      state.loading = true;
      state.error = '';
    },
    fetchAllQrImages(state: QrImageState) {
      state.loading = true;
      state.error = '';
    },
    qrImagesFetched(
      state: QrImageState,
      action: PayloadAction<QrImagesPageResult>
    ) {
      state.data = action.payload.data;
      state.total = action.payload.totalCount;
      state.loading = false;
    },
    qrImagesFetchedError(state: QrImageState) {
      state.loading = false;
      state.error = 'Fail to fetch qr image!';
    },
    deleteQrImages(state: QrImageState, action: PayloadAction<number>) {
      state.loading = true;
      state.deleted = false;
      state.error = '';
    },
    qrImagesDeleted(state: QrImageState) {
      state.loading = false;
      state.deleted = true;
    },
    qrImagesDeletedError(state: QrImageState) {
      state.loading = false;
      state.error = 'Fail to delete videos!';
    },
    saveQrImages(state: QrImageState, action: PayloadAction<string>) {
      state.loading = true;
      state.error = '';
    },
    qrImagesSaved(state: QrImageState) {
      state.loading = false;
      state.saved = true;
    },
    resetSaved(state: QrImageState) {
      state.saved = false
    },
    qrImagesSavedError(state: QrImageState, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    setCurrentPage(state: QrImageState, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setSearch(state: QrImageState, action: PayloadAction<string>) {
      state.search = action.payload;
    },
    setSort(state: QrImageState, action: PayloadAction<string>) {
      state.sort = action.payload;
    },
    setPageSize(state: QrImageState, action: PayloadAction<number>) {
      state.pageSize = action.payload;
    },
    setExpiredDate(state: QrImageState, action: PayloadAction<string>) {
      state.expiredDate = action.payload;
    },
    setIncludeProfiles(state: QrImageState, action: PayloadAction<boolean>) {
      state.includeProfiles = action.payload;
    },
    setAddedQrImageIds(state: QrImageState, action: PayloadAction<string>) {
      state.addedQrImageIds = action.payload;
    },
  }
});

export default qrImageSlice.reducer;
export const qrImageActions = qrImageSlice.actions;
