import { call, put, select, takeLatest } from 'redux-saga/effects';
import clickTrackingApi from 'src/api_v2/user-click-tracking';
import {
  ClickTrackingReportPageResult,
  FetchClickTrackingReportParams
} from 'src/api_v2/user-click-tracking/types';
import { selectQuery } from './selector';
import { clickTrackingActions } from './slice';

function* fetchClickTrackingReport() {
  try {
    const {
      mirrorTypeFilter,
      contentFilter,
      from,
      to,
      page,
      pageSize,
      sort,
      shopNameFilter,
      selectedMirrors
    } = yield select(selectQuery);

    const queryParams = new FetchClickTrackingReportParams(
      page,
      pageSize,
      selectedMirrors.join(','),
      sort,
      mirrorTypeFilter,
      contentFilter,
      from.toString(),
      to.toString(),
      shopNameFilter
    );
    const result: ClickTrackingReportPageResult = yield call(
      clickTrackingApi.fetchClickTrackingReport,
      queryParams
    );

    yield put(clickTrackingActions.clickTrackingFetched(result));
  } catch (err) {
    console.log(err);
    yield put(clickTrackingActions.clickTrackingFetchedError());
  }
}

function* fetchTotalClickTrackingReport() {
  try {
    const {
      selectedMirrors,
      mirrorTypeFilter,
      contentFilter,
      from,
      to,
      sort,
      shopNameFilter
    } = yield select(selectQuery);

    const queryParams = new FetchClickTrackingReportParams(
      1,
      Number.MAX_SAFE_INTEGER,
      selectedMirrors.join(','),
      sort,
      mirrorTypeFilter,
      contentFilter,
      from.toString(),
      to.toString(),
      shopNameFilter
    );
    const result: ClickTrackingReportPageResult = yield call(
      clickTrackingApi.fetchClickTrackingReport,
      queryParams
    );

    yield put(clickTrackingActions.clickTrackingFetched(result));
  } catch (err) {
    console.log(err);
    yield put(clickTrackingActions.clickTrackingFetchedError());
  }
}

function* fetchFilters() {
  try {
    const result = yield call(clickTrackingApi.fetchFilters);

    if (result) {
      yield put(clickTrackingActions.filtersFetched(result));
    }
  } catch (err) {
    console.log(err);
    yield put(clickTrackingActions.fetchFiltersError());
  }
}

function* exportCSV() {
  try {
    const {
      mirrorTypeFilter,
      contentFilter,
      from,
      to,
      page,
      pageSize,
      sort,
      shopNameFilter,
      selectedMirrors
    } = yield select(selectQuery);

    const queryParams = new FetchClickTrackingReportParams(
      page,
      pageSize,
      selectedMirrors.join(','),
      sort,
      mirrorTypeFilter,
      contentFilter,
      from.toString(),
      to.toString(),
      shopNameFilter
    );
    const result = yield call(clickTrackingApi.exportCSV, queryParams);

    if (result) {
      yield put(clickTrackingActions.csvExported());
    }
  } catch (err) {
    console.log(err);
    yield put(clickTrackingActions.clickTrackingFetchedError());
  }
}

export default function* watchClickTrackingReportV2Saga() {
  yield takeLatest(
    clickTrackingActions.fetchClickTracking.type,
    fetchClickTrackingReport
  );
  yield takeLatest(
    clickTrackingActions.fetchTotalClickTracking.type,
    fetchTotalClickTrackingReport
  );
  yield takeLatest(clickTrackingActions.fetchFilters.type, fetchFilters);
  yield takeLatest(clickTrackingActions.exportCSV.type, exportCSV);
}
