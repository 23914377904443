export enum USER_ROLE {
  Admin,
  Mirrors_owner,
  AR_owner,
  AR_admin,
  Mirror_viewer
}

export enum USER_GENDER {
  MALE,
  FEMALE,
  UNKNOWN
}

export enum USER_REVIEW {
  GOOD = 'GOOD',
  SOSO = 'SOSO',
  NOT_GOOD = 'NOT_GOOD',
  BETTER = 'BETTER',
  MAJOR_INSCREASE = 'MAJOR_INSCREASE',
  SLIGHT_INSCREASE = 'SLIGHT_INSCREASE',
  NO_CHANGE = 'NO_CHANGE'
}

export const userReviewMap = {
  [USER_REVIEW.GOOD]: '面白かった！',
  [USER_REVIEW.SOSO]: 'まぁまぁだった',
  [USER_REVIEW.NOT_GOOD]: 'うーん・・・',
  [USER_REVIEW.BETTER]: '向上した',
  [USER_REVIEW.NO_CHANGE]: '変わらない',
  [USER_REVIEW.MAJOR_INSCREASE]: 'とても関心を持った',
  [USER_REVIEW.SLIGHT_INSCREASE]: 'とても関心を持った'
};

export enum SNAP_BRANCH {
  EDGE = 'edge',
  CANDIDATE = 'candidate',
  BETA = 'beta',
  STABLE = 'stable'
}

export enum UserRoles {
  Admin,
  Mirrors_owner,
  AR_owner_namecard,
  AR_admin,
  User_admin,
  Mirror_viewer,
  AR_owner_pamphlet,
}

export namespace UserRoles {
  export function toString(role: UserRoles): string {
    switch (role) {
      case UserRoles.Admin:
        return UserRoles[role].toLowerCase();
      case UserRoles.Mirrors_owner:
        return 'mirrors-owner';
      case UserRoles.AR_owner_namecard:
        return UserRoles[role].toLowerCase();
      case UserRoles.AR_admin:
        return UserRoles[role].toLowerCase();
      case UserRoles.User_admin:
        return 'user-admin';
      case UserRoles.Mirror_viewer:
        return 'mirror-viewer';
      case UserRoles.AR_owner_pamphlet:
        return UserRoles[role].toLowerCase();
      default:
        return 'admin';
    }
  }
}


export type UserResponse = {
  id: number;
  uuid: string;
  role: string;
};

export enum CATEGORY_TYPE_AR_TEMPLATE {
  NameCard = 0,
  PAMPHLET = 1
}