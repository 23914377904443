import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export type ResetPasswordType = {
  key: string;
  newPassword: string;
};

export interface ForgotPasswordState {
  loading: boolean;
  error: boolean;
  sent: boolean;
  reseted: boolean;
}

export const initialState: ForgotPasswordState = {
  loading: false,
  error: false,
  sent: false,
  reseted: false
};

const forgotPasswordSlice = createSlice({
  name: 'forgotPassword',
  initialState,
  reducers: {
    resetState(state: ForgotPasswordState) {
      state = {
        loading: false,
        error: false,
        sent: false,
        reseted: false
      };
    },
    sendResetLink(state: ForgotPasswordState, action: PayloadAction<string>) {
      state.loading = true;
      state.sent = false;
    },
    linkSent(state: ForgotPasswordState) {
      state.loading = false;
      state.sent = true;
      state.error = false;
    },
    linkSentError(state: ForgotPasswordState) {
      state.error = true;
      state.loading = false;
    },
    linkResetError(state: ForgotPasswordState) {
      state.error = false;
    },
    resetPassword(
      state: ForgotPasswordState,
      action: PayloadAction<ResetPasswordType>
    ) {
      state.loading = true;
      state.reseted = false;
    },
    passwordReseted(state: ForgotPasswordState) {
      state.loading = false;
      state.reseted = true;
      state.error = false;
    },
    passwordResetError(state: ForgotPasswordState) {
      state.loading = false;
      state.error = true;
    }
  }
});

export const { actions } = forgotPasswordSlice;
export default forgotPasswordSlice.reducer;
