import { AxiosResponse } from 'axios';
import agent from 'src/api_v2/base';
import { GroupConfigurationType } from 'src/containers_v2/ar_creator/group/group-configuration/store/slice';
import { FetchGroupListParams, GroupListPageResult } from './types';

class GroupApi {
  async fetchGroupList(params: FetchGroupListParams) {
    try {
      const response: AxiosResponse = await agent.get(
        `/group?${params.toString()}`
      );

      if (response.status === 200) {
        return response.data as GroupListPageResult;
      }

      return []
    } catch (err) {
      throw new Error(err);
    }
  }

  async fetchRemainingCredit(user_id: number) {
    try {
      const response: AxiosResponse = await agent.get(
        `/group/${user_id}/remaining-credit`
      );

      if (response.status === 200) {
        return response.data as GroupListPageResult;
      }

      return []
    } catch (err) {
      throw new Error(err);
    }
  }

  async deleteGroup(id: number) {
    try {
      const response: AxiosResponse = await agent.delete(
        `/group/${id}`
      );

      if(response.status !== 200) {
        return false
      } 

      return true
    } catch (err) {
      throw err;
    }
  }

  async addGroup(group: GroupConfigurationType) {
    try {
      const response: AxiosResponse = await agent.post(
        `/group/create`,
        group
      );

      if(response.status !== 200 && response.status !== 201) {
        return {
          valid : false,
          data: response
        }
      }

      return {
        valid : true,
        data: response
      }
    } catch (err) {
      return {
        valid : false,
        data: err
      };
    }
  }

  async updateGroup(group: GroupConfigurationType) {
    try {
      const response: AxiosResponse = await agent.put(
        `/group`,
        group
      );

      if(response.status !== 200 && response.status !== 204) {
        return {
          valid : false,
          data: response
        }
      }

      return {
        valid : true,
        data: response
      }
    } catch (err) {
      return {
        valid : false,
        data: err
      };
    }
  }

  async getGroup(id: string): Promise<any> {
    try {
      const response: AxiosResponse = await agent.get(
        `/group/${id}`
      );

      if (response.status === 200) {
        return response.data;
      }

      return undefined
    } catch (err) {
      throw err;
    }
  }

  async getGroupByUser(id: string): Promise<any> {
    try {
      const response: AxiosResponse = await agent.get(
        `/group/user/${id}`
      );

      if (response.status === 200) {
        return response.data;
      }

      return undefined
    } catch (err) {
      throw err;
    }
  }
}

const groupListApi = new GroupApi();
export default groupListApi;
