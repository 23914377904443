import React, { FC, ReactNode, useContext, useEffect, memo } from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import {
  Box,
  ClickAwayListener,
  CssBaseline,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery
} from '@mui/material';
import { Outlet } from 'react-router-dom';
import Header from './Header';
import Uploader from 'src/containers_v2/uploader';
import { SidebarContext } from 'src/contexts/SidebarContext';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Logo from 'src/components/Logo';
import { messages } from 'src/locales/messages';
import { useTranslation } from 'react-i18next';
import SidebarMenu from './Sidebar/SidebarMenu';
import { positionValues } from 'react-custom-scrollbars-2';
import appConfig from '../../config';
import MetaverseMediaModal from 'src/containers_v2/metaverse/MetaverseMediaModal';
import { useDispatch, useSelector } from 'react-redux';
import { selectMetaverseImage } from 'src/containers_v2/metaverse/store/selector';
import { metaverseActions } from 'src/containers_v2/metaverse/store/slice';

interface SidebarLayoutProps {
  children?: ReactNode;
}

const MainContent = styled<any>(Box)(
  ({ theme, width, open, matches }) => `
        flex: 1 1 auto;
        overflow: auto;
        filter: ${open && matches ? 'blur(4px)' : 'inherit'};
        @media (min-width: ${theme.breakpoints.values.lg}px) {
          margin-left: ${width}px;
      }
`
);

const drawerWidth = 290;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}));

const Side = memo(SidebarMenu);

const SidebarLayoutV2: FC<SidebarLayoutProps> = () => {
  const matches = useMediaQuery('(max-width:1280px)');
  const { handleDrawerClose, open } = useContext(SidebarContext);
  const theme = useTheme();
  const { t } = useTranslation();
  const mediaUrl = useSelector(selectMetaverseImage);
  const dispatch = useDispatch();

  useEffect(() => {
    if (matches) {
      handleDrawerClose();
    }
  }, [matches]);

  const getBackgroundColorByEnv = () => {
    if (appConfig.IsProduction) {
      return theme.colors.primary.main;
    } else if (appConfig.IsStaging) {
      return theme.colors.primary.staging;
    } else if (appConfig.IsQA) {
      return theme.colors.primary.qa;
    } else {
      return theme.colors.primary.local;
    }
  };

  const getBannerByEnv = () => {
    if (appConfig.IsProduction) {
      return '';
    } else if (appConfig.IsStaging) {
      return t(messages.main.version['staging']());
    } else if (appConfig.IsQA) {
      return t(messages.main.version['qa']());
    } else {
      return t(messages.main.version['local']());
    }
  };

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" open={open}>
          <Toolbar>
            <Header />
          </Toolbar>
        </AppBar>
        <ClickAwayListener
          onClickAway={(e) => {
            if (matches && open && (e.target as any).id != 'collapse-icon') {
              handleDrawerClose();
            }
          }}
        >
          <Drawer variant="permanent" open={open}>
            <Box
              sx={{
                backgroundColor: getBackgroundColorByEnv()
              }}
            >
              <DrawerHeader>
                <Box>
                  <Logo />
                  <Typography sx={{ paddingLeft: 1, color: 'white' }}>
                    {getBannerByEnv()}
                  </Typography>
                </Box>
                <IconButton onClick={handleDrawerClose}>
                  {theme.direction === 'rtl' ? (
                    <ChevronRightIcon />
                  ) : (
                    <ChevronLeftIcon />
                  )}
                </IconButton>
              </DrawerHeader>
            </Box>
            <Side />
          </Drawer>
        </ClickAwayListener>
        <Box component="main" sx={{ flexGrow: 1, overflow: 'hidden' }}>
          <DrawerHeader />
          <MainContent matches={matches} open={open}>
            <Outlet />
            <Uploader />
            <MetaverseMediaModal
              mediaUrl={mediaUrl}
              onClose={() => dispatch(metaverseActions.setMediaUrl(''))}
            />
          </MainContent>
        </Box>
      </Box>
    </>
  );
};

export default memo(SidebarLayoutV2);
