import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { ARProjectListPageResult } from 'src/api_v2/ar-project/types';
import IPagination from 'src/models/pagination';

export type ARProjectType = {
  id: number;
  description?: string;
  name?: string;
  user_id: number;
  shopName: string;
  shopAddress: string;
  shopPhone: string;
  shop_url: string;
  voiceover_name: string;
  video_name: string;
  arproj_id: number;
  templateName: string;
  templateLink: string;
  qr_link: string | null;
  video_text: string | null;
  created: number;
  group_id?: number | null;
  aflacInternalId?: string | null
  enable: boolean;
};
export interface State extends IPagination {
  total: number;
  data: ARProjectType[];
  loading: boolean;
  error: boolean;
  deleted: boolean;
  template_id: string;
  user_id: string
  exporting: boolean;
}

export const initialState: State = {
  data: [],
  total: 0,
  page: 1,
  pageSize: 50,
  sort: 'created|desc',
  search: '',
  loading: false,
  error: false,
  deleted: false,
  template_id: '',
  user_id: '',
  exporting: false
};

const ListSlice = createSlice({
  name: 'arProject',
  initialState,
  reducers: {
    resetState: () => initialState,
    fetchList(state: State) {
      state.loading = true;
    },
    listFetched(
      state: State,
      action: PayloadAction<ARProjectListPageResult>
    ) {
      state.loading = false;
      state.error = false;
      state.data = action.payload.data;
      state.total = action.payload.totalItems;
    },
    listFetchedError(state: State) {
      state.loading = false;
      state.error = true;
    },
    setCurrentPage(state: State, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setSearch(state: State, action: PayloadAction<string>) {
      state.search = action.payload;
    },
    setSort(state: State, action: PayloadAction<string>) {
      state.sort = action.payload;
    },
    setTemplateId(state: State, action: PayloadAction<string>) {
      state.template_id = action.payload;
    },
    setUserId(state: State, action: PayloadAction<string>) {
      state.user_id = action.payload;
    },
    deleteItem(state: State, action: PayloadAction<number>) {
      state.loading = true;
      state.error = false;
      state.deleted = false;
    },
    deletedItem(state: State) {
      state.deleted = true;
    },
    useDeletedError(state: State) {
      state.loading = false;
      state.error = true;
    },
    resetDeleted(state: State) {
      state.deleted = false;
    },
    setPageSize(state: State, action: PayloadAction<number>) {
      state.pageSize = action.payload;
    },
    fetchTotalList(state: State) {
      state.loading = true;
    },
    exportUnscreenHistory(state: State) {
      state.exporting = true
    },
    unscreenHistoryExported(state: State) {
      state.exporting = false
    },
    unscreenHistoryExportedFail(state: State) {
      state.exporting = false
    },
    setDataList(state: State, action: PayloadAction<ARProjectType[]>) {
      state.data = action.payload
    },
    updateStatus(state: State, action: PayloadAction<{id: number, status: boolean}>) {
      
    }
  }
});

export const { actions, reducer, name: sliceKey } = ListSlice;
