import { createSelector } from '@reduxjs/toolkit';
import { SummaryState } from 'src/containers_v2/people-counter/summary/store/slice';
import { RootState } from 'src/redux/store/configureStore';
import _ from 'lodash';
import { ContentCountType } from './model';

const selectDomain = (state: RootState) => {
  return state.userSummaryV2;
};

export const selectSummaryReport = createSelector(
  [selectDomain],
  (state: SummaryState) => state.data
);

export const selectSearch = createSelector(
  [selectDomain],
  (state: SummaryState) => state.search
);

export const selectSort = createSelector(
  [selectDomain],
  (state: SummaryState) => state.sort
);

export const selectPage = createSelector(
  [selectDomain],
  (state: SummaryState) => state.page
);

export const selectPageSize = createSelector(
  [selectDomain],
  (state: SummaryState) => state.pageSize
);

export const selectTotal = createSelector(
  [selectDomain],
  (state: SummaryState) => state.total
);

export const selectLoading = createSelector(
  [selectDomain],
  (state: SummaryState) => state.loading
);

export const selectMirrorTypeFitler = createSelector(
  [selectDomain],
  (state: SummaryState) => state.mirrorTypeFilter
);

export const selectContentFitler = createSelector(
  [selectDomain],
  (state: SummaryState) => state.contentFilter
);

export const selectFrom = createSelector(
  [selectDomain],
  (state: SummaryState) => state.from
);

export const selectTo = createSelector(
  [selectDomain],
  (state: SummaryState) => state.to
);

export const selectContents = createSelector(
  [selectDomain],
  (state: SummaryState) => state.contents
);

export const selectMirrorTypes = createSelector(
  [selectDomain],
  (state: SummaryState) => state.mirrorTypes
);

export const selectFilters = createSelector(
  [selectDomain],
  (state: SummaryState) => ({
    contents: state.contents,
    mirrorTypes: state.mirrorTypes,
    mirrorIds: state.mirrorIds
  })
);

export const selectQuery = createSelector(
  [selectDomain],
  (state: SummaryState) => ({
    search: state.search,
    sort: state.sort,
    page: state.page,
    pageSize: state.pageSize,
    from: state.from,
    to: state.to,
    mirrorTypeFilter: state.mirrorTypeFilter,
    contentFilter: state.contentFilter,
    shopNameFilter: state.shopNameFilter,
  })
);

export const countMirrorsHighest = createSelector(
  [selectDomain],
  (state: SummaryState) => {
    const result: ContentCountType[] = [];

    state.data.forEach((item: any, index: number) => {
      result.push({
        name: item?.name?.replaceAll('_', ' ').toLowerCase(),
        value: item?.people_count
      });
    });

    const sortedData = result.sort(function (a, b) {
      return b.value - a.value;
    });

    return {
      dataGroupByContent: [],
      sortedData
    };
  }
);

export const selectExporting = createSelector(
  [selectDomain],
  (state: SummaryState) => state.exporting
);

export const selectShopNames = createSelector(
  [selectDomain],
  (state: SummaryState) => state.shopNames
);
