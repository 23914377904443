import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import userApi from 'src/api_v2/user-management/user-management-api';
import { actions, UserConfigurationType, UserMirrorsType } from './slice';

function* saveUser(action: PayloadAction<UserConfigurationType>) {
  try {
    const result = yield call(
      action.payload.id ? userApi.updateUser : userApi.addUser,
      action.payload
    );

    if (result?.data?.status) {
      yield put(actions.userSaved());
    } else {
      yield put(actions.userSavedError('Fail to save user.'));
    }
  } catch (err) {
    if (err.response) {
      const message = err.response.data?.message as string[];
      if (message?.includes('Email is duplicated')) {
        yield put(actions.userSavedError('Email is duplicated'));
      } else {
        yield put(actions.userSavedError('Fail to save user.'));
      }
    }
  }
}

function* getUser(action: PayloadAction<string>) {
  try {
    const result = yield call(userApi.getUser, action.payload);

    if (result) {
      yield put(
        actions.userGot({
          ...result,
          confirmPassword: result.password
        })
      );
    } else {
    }
  } catch (err) {
    yield put(actions.userGotError('Fail to get user.'));
  }
}

function* getMirrorsAssigned(action: PayloadAction<string>) {
  try {
    const result = yield call(userApi.getMirrorsAssigned, action.payload);
    if (result) {
      yield put(
        actions.getMirrorsAssignedSuccess(result)
      );
    } else {
    }
  } catch (err) {
    yield put(actions.getMirrorsAssignedError('Fail to get user mirrors.'));
  }
}

export default function* watchUserConfigurationSaga() {
  yield takeLatest(actions.saveUser.type, saveUser);
  yield takeLatest(actions.getUser.type, getUser);
  yield takeLatest(actions.getMirrorsAssigned.type, getMirrorsAssigned);
}
