import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store/configureStore';
import { ARTemplateListState } from './slice';

const selectDomain = (state: RootState) => {
  return state.arTemplate;
};

export const selectARTemplateList = createSelector(
  [selectDomain],
  (arTemplate: ARTemplateListState) => arTemplate.data
);

export const selectPagination = createSelector(
  [selectDomain],
  (arTemplate: ARTemplateListState) => ({
    page: arTemplate.page,
    pageSize: arTemplate.pageSize,
    search: arTemplate.search,
    sort: arTemplate.sort,
  })
);

export const selectSearch = createSelector(
  [selectDomain],
  (arTemplate: ARTemplateListState) => arTemplate.search
);

export const selectType = createSelector(
  [selectDomain],
  (arTemplate: ARTemplateListState) => arTemplate.type
);

export const selectSort = createSelector(
  [selectDomain],
  (arTemplate: ARTemplateListState) => arTemplate.sort
);

export const selectPage = createSelector(
  [selectDomain],
  (arTemplate: ARTemplateListState) => arTemplate.page
);

export const selectPageSize = createSelector(
  [selectDomain],
  (arTemplate: ARTemplateListState) => arTemplate.pageSize
);

export const selectTotal = createSelector(
  [selectDomain],
  (arTemplate: ARTemplateListState) => arTemplate.total
)

export const selectLoading = createSelector(
  [selectDomain],
  (arTemplate: ARTemplateListState) => arTemplate.loading
)

export const selectDeleted = createSelector(
  [selectDomain],
  (arTemplate: ARTemplateListState) => arTemplate.deleted
)