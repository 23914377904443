import { AxiosResponse } from 'axios';
import agent from 'src/api_v2/base';
import {
  AddVideosType,
  DeleteVoiceoverFiles,
  NeutralVideoType,
  ProfileConfigurationType,
  ProfileContentType,
  ProfileFiltersType,
  ProfilePhotoType,
  ProfileSnapConfigType,
  ProfileSnapType,
  QrImageType,
  UpdateNeutralVideoType,
  VoiceoverType,
  AddQrImagesType,
  UpdateQrImageType,
} from 'src/redux/store/models';
import {
  FetchProfileListParams,
  ProfileListPageResult,
  ProfileVoiceoverResponseType
} from './types';

class ProfileApi {
  async fetchProfiles(
    params: FetchProfileListParams
  ): Promise<ProfileListPageResult> {
    try {
      const response: AxiosResponse = await agent.get(
        `/profiles?${params.toString()}`
      );

      if (response.status === 200) {
        return response.data as ProfileListPageResult;
      }

      return undefined;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async fetchMirrorsByProfile(profileId: number): Promise<string[]> {
    try {
      const response: AxiosResponse = await agent.get(
        `/profiles/${profileId}/mirrors`
      );

      if (response.status === 200) {
        return response.data;
      }

      return [];
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async fetchSnapsByProfile(profileId: number): Promise<ProfileSnapType[]> {
    try {
      const response: AxiosResponse = await agent.get(
        `/profiles/${profileId}/snaps`
      );

      if (response.status === 200) {
        return response.data as ProfileSnapType[];
      }

      return [];
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async fetchContentsByProfile(
    profileId: number
  ): Promise<ProfileContentType[]> {
    try {
      const response: AxiosResponse = await agent.get(
        `/profiles/${profileId}/contents`
      );

      if (response.status === 200) {
        return response.data as ProfileContentType[];
      }

      return [];
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async fetchProfileFilters(): Promise<ProfileFiltersType> {
    try {
      const response: AxiosResponse = await agent.get(`/profiles/filters`);

      if (response.status === 200) {
        return response.data as ProfileFiltersType;
      }

      return undefined;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async saveProfile(payload: ProfileConfigurationType): Promise<any> {
    try {
      const response: AxiosResponse = await agent.put(`/profiles`, payload);

      if (response.status === 200) {
        return response.data;
      }

      return undefined;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async addProfile(contents: FormData) {
    try {
      const response: AxiosResponse = await agent.put(`/profiles`, contents);

      if (response.status !== 200 && response.status !== 201) {
        return {
          valid: false,
          data: response
        };
      }

      return {
        valid: true,
        data: response
      };
    } catch (err) {
      return {
        valid: false,
        data: err
      };
    }
  }

  async saveSnaps(payload: ProfileSnapConfigType): Promise<boolean> {
    try {
      const response: AxiosResponse = await agent.post(
        `/profiles/update-snaps`,
        payload
      );

      if (response.status === 200 || response.status === 201) {
        return true;
      }

      return false;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async getProfileById(
    profileId: string | number
  ): Promise<ProfileConfigurationType> {
    try {
      const response: AxiosResponse = await agent.get(`/profiles/${profileId}`);

      if (response.status === 200) {
        return response.data as ProfileConfigurationType;
      }

      return undefined;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async deleteProfile(profileId: string | number): Promise<boolean> {
    try {
      const response: AxiosResponse = await agent.delete(
        `/profiles/${profileId}`
      );

      if (response.status === 200) {
        return true;
      }

      return false;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async saveContents(
    profileId: string | number,
    payload: ProfileContentType[]
  ): Promise<boolean> {
    try {
      const response: AxiosResponse = await agent.put(
        `/profiles/${profileId}/contents`,
        payload
      );

      if (response.status === 200 || response.status === 204) {
        return true;
      }

      return false;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async uploadNeutralVideo(
    profileId: string | number,
    payload: FormData
  ): Promise<boolean> {
    try {
      const response: AxiosResponse = await agent.post(
        `/profiles/${profileId}/upload-video`,
        payload,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );

      if (response.status === 200 || response.status === 201) {
        return true;
      }

      return false;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async getNeutralVideos(
    profileId: string | number
  ): Promise<NeutralVideoType[]> {
    try {
      const response: AxiosResponse = await agent.get(
        `/profiles/${profileId}/neutral-videos`
      );

      if (response.status === 200) {
        return response.data
          .sort((a, b) => a.videoSequence - b.videoSequence)
          .map(
            (x) =>
              ({
                id: x.id,
                videoLocation: x.videoLocation,
                videoName: x.videoName,
                enable: x.enable,
                expiredDate: x.expiredDate,
                duration: x.duration,
                size: x.size,
                videoId: x.videoId,
                videoSequence: x.videoSequence
              } as NeutralVideoType)
          );
      }

      return [];
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async updateNeutralVideos(payload: UpdateNeutralVideoType): Promise<boolean> {
    try {
      const response: AxiosResponse = await agent.post(
        `/profiles/update-videos`,
        payload
      );

      if (response.status === 200 || response.status === 201) {
        return true;
      }

      return false;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async deleteNeutralVideo(videoId: string | number): Promise<boolean> {
    try {
      const response: AxiosResponse = await agent.delete(
        `/profiles/remove-video/${videoId}`
      );

      if (response.status === 200) {
        return true;
      }

      return false;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async uploadVoiceoverFiles(payload: FormData): Promise<any> {
    try {
      const response: AxiosResponse = await agent.post(
        `/profiles/upload-voiceover`,
        payload
      );

      if (response.status === 200 || response.status === 201) {
        return response.data;
      }

      return false;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async uploadVoiceovers(payload: VoiceoverType): Promise<ProfileVoiceoverResponseType> {
    try {
      const response: AxiosResponse = await agent.put(
        `/profiles/voice-over`,
        payload
      );

      if (response.status === 200 || response.status === 201) {
        return response.data;
      }

      return undefined;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async getProfileVoiceovers(
    profileId: string | number
  ): Promise<ProfileVoiceoverResponseType> {
    try {
      const response: AxiosResponse = await agent.get(
        `/profiles/${profileId}/voice-over`
      );

      if (response.status === 200) {
        return response.data;
      }

      return undefined;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async uploadPhotos(payload: FormData): Promise<boolean> {
    try {
      const response: AxiosResponse = await agent.put(
        `/profiles/photos`,
        payload
      );

      if (response.status === 200 || response.status === 201) {
        return true;
      }

      return false;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async getPhotos(profileId: string | number): Promise<ProfilePhotoType> {
    try {
      const response: AxiosResponse = await agent.get(
        `/profiles/${profileId}/photos`
      );

      if (response.status === 200 || response.status === 201) {
        return {
          headerName: response.data.headerName,
          headerPath: response.data.headerPath,
          fullScreenName: response.data.modalName,
          fullScreenPath: response.data.modalPath,
          bottomName: response.data.bottomName,
          bottomPath: response.data.bottomPath,
        } as ProfilePhotoType;
      }

      return undefined;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async deleteVoiceovers(
    profileId: string | number,
    files: DeleteVoiceoverFiles
  ): Promise<boolean> {
    try {
      const response: AxiosResponse = await agent({
        method: 'delete',
        url: `/profiles/${profileId}/voice-over`,
        data: { ...files }
      });

      if (response.status === 200) {
        return true;
      }

      return false;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async addVideos(
    profileId: string | number,
    payload: AddVideosType[]
  ): Promise<boolean> {
    try {
      const response: AxiosResponse = await agent.post(
        `/profiles/${profileId}/add-videos`,
        payload
      );

      if (response.status === 200 || response.status === 201) {
        return true;
      }

      return false;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
  async addQrImages(
    profileId: string | number,
    payload: AddQrImagesType[]
  ): Promise<boolean> {
    try {
      const response: AxiosResponse = await agent.post(
        `/profiles/${profileId}/add-qr-images`,
        payload
      );

      if (response.status === 200 || response.status === 201) {
        return true;
      }

      return false;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async getQrImages(
    profileId: string | number
  ): Promise<QrImageType[]> {
    try {
      const response: AxiosResponse = await agent.get(
        `/profiles/${profileId}/qr-images`
      );

      if (response.status === 200) {
        return response.data
          .sort((a, b) => a.qrImageSequence - b.qrImageSequence)
          .map(
            (x) =>
              ({
                id: x.id,
                qrImageLocation: x.qrImageLocation,
                qrImageName: x.qrImageName,
                enable: x.enable,
                expiredDate: x.expiredDate,
                qrDuration: x.qrDuration,
                qrImageId: x.qrImageId,
                qrImageSequence: x.qrImageSequence,
                qrImageDescription: x.qrImageDescription,
                qrImageDescriptionColor: x.qrImageDescriptionColor,
              } as QrImageType)
          );
      }

      return [];
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async deleteQrImage(qrImageId: string | number): Promise<boolean> {
    try {
      const response: AxiosResponse = await agent.delete(
        `/profiles/remove-qr-image/${qrImageId}`
      );

      if (response.status === 200) {
        return true;
      }

      return false;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
  async updateQrImages(payload: UpdateQrImageType): Promise<boolean> {
    try {
      const response: AxiosResponse = await agent.post(
        `/profiles/update-qr-images`,
        payload
      );

      if (response.status === 200 || response.status === 201) {
        return true;
      }

      return false;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
}

const profileApi = new ProfileApi();
export default profileApi;
