import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import snapApi from 'src/api_v2/snap';
import { actions, SnapConfigurationType } from './slice';

function* saveSnap(action: PayloadAction<SnapConfigurationType>) {
  try {
    const result = yield call(
      action.payload.id ? snapApi.updateSnap : snapApi.addSnap,
      action.payload
    );

    if (result.valid) {
      yield put(actions.snapSaved());
    } else {
      yield put(actions.snapSavedError(result?.data?.response?.data?.message?.[0] ? result.data.response.data.message?.[0] : 'Fail to save snap.'));
    }
  } catch (err) {
    if (err.data.response) {
      const message = err.data.response.data?.message as string[];
      if (message?.includes('Email is duplicated')) {
        yield put(actions.snapSavedError('Email is duplicated'));
      } else {
        yield put(actions.snapSavedError('Fail to save snap.'));
      }
    }
  }
}

function* getSnap(action: PayloadAction<string>) {
  try {
    const result = yield call(snapApi.getSnap, action.payload);

    if (result) {
      yield put(
        actions.snapGot({
          ...result,
          edge: `${result.edge}`,
          beta: `${result.beta}`,
          candidate: `${result.candidate}`,
          stable: `${result.stable}`,
          confirmPassword: result.password
        })
      );
    } else {
    }
  } catch (err) {
    yield put(actions.snapGotError('Fail to get snap.'));
  }
}

export default function* watchSnapConfigurationSaga() {
  yield takeLatest(actions.saveSnap.type, saveSnap);
  yield takeLatest(actions.getSnap.type, getSnap);
}
