import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import videosApi from 'src/api_v2/videos';
import { FetchVideosParams } from 'src/api_v2/videos/types';
import { selectQuery } from './selector';
import { videosActions } from './slice';

function* fetchVideos() {
  try {
    const { search, page, pageSize, sort, expiredDate, includeProfiles, addedVideoIds } = yield select(selectQuery);

    const queryParams = new FetchVideosParams(page, pageSize, search, sort, expiredDate, includeProfiles, addedVideoIds);
    const result = yield call(videosApi.fetchVideos, queryParams);

    if (result && result.data) {
      yield put(videosActions.videosFetched(result));
    } else {
      yield put(videosActions.videosFetchedError());
    }
  } catch (err) {
    yield put(videosActions.videosFetchedError());
  }
}

function* fetchAllVideos() {
  try {
    const queryParams = new FetchVideosParams(
      1,
      Number.MAX_SAFE_INTEGER,
      '',
      'name|asc'
    );
    const result = yield call(videosApi.fetchVideos, queryParams);

    if (result && result.data) {
      yield put(videosActions.videosFetched(result));
    } else {
      yield put(videosActions.videosFetchedError());
    }
  } catch (err) {
    yield put(videosActions.videosFetchedError());
  }
}

function* deleteVideos(action: PayloadAction<number>) {
  try {
    const result = yield call(videosApi.deleteVideos, action.payload);

    if (result) {
      yield put(videosActions.videosDeleted());
      yield call(fetchVideos);
    } else {
      yield put(videosActions.videosDeletedError());
    }
  } catch (err) {
    yield put(videosActions.videosDeletedError());
  }
}

function* saveVideos(action: PayloadAction<string>) {
  try {
    const result = yield call(videosApi.saveVideos, {
      name: action.payload,
      id: 0
    });

    if (result) {
      yield put(videosActions.videosSaved());
      yield call(fetchVideos);
    } else {
      yield put(videosActions.videosSavedError(''));
    }
  } catch (err) {
    if (err.response) {
      const message = err.response.data?.message as string[];

      yield put(videosActions.videosSavedError(message[0]));
    } else {
      yield put(videosActions.videosSavedError(''));
    }
  }
}

export default function* watchVideosListSaga() {
  yield takeLatest(videosActions.fetchVideos.type, fetchVideos);
  yield takeLatest(videosActions.fetchAllVideos.type, fetchAllVideos);
  yield takeLatest(videosActions.deleteVideos.type, deleteVideos);
  yield takeLatest(videosActions.saveVideos.type, saveVideos);
}
