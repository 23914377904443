import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  FetchARTemplateListParams,
  ARTemplateListPageResult
} from 'src/api_v2/ar-template/types';
import arTemplateApi from 'src/api_v2/ar-template/ar-template-list-api';
import {
  selectPage,
  selectPageSize,
  selectSearch,
  selectSort,
  selectType
} from './selector';
import { actions } from './slice';

function* fetchARTemplateList() {
  try {
    const page = yield select(selectPage);
    const pageSize = yield select(selectPageSize);
    const search = yield select(selectSearch);
    const sort = yield select(selectSort);
    const type = yield select(selectType);
    const queryParams = new FetchARTemplateListParams(
      page,
      pageSize,
      search,
      sort,
      type
    );
    const result: ARTemplateListPageResult = yield call(
      arTemplateApi.fetchARTemplateList,
      queryParams
    );

    yield put(actions.arTemplateListFetched(result));
  } catch (err) {
    console.log(err);
    yield put(actions.arTemplateListFetchedError());
  }
}

function* deleteARTemplate(action: PayloadAction<number>) {
  try {
    const userId = action.payload;
    if (userId) {
      yield call(arTemplateApi.deleteARTemplate, userId);

      yield put(actions.arTemplateDeleted());
      yield call(fetchARTemplateList)
    }
  } catch (err) {
    console.log(err);
    yield put(actions.useDeletedError());
  }
}

function* fetchTotalARTemplateList() {
  try {
    const search = yield select(selectSearch);
    const sort = yield select(selectSort);

    const queryParams = new FetchARTemplateListParams(
      1,
      Number.MAX_SAFE_INTEGER,
      search,
      sort,
    );
    const result: ARTemplateListPageResult = yield call(
      arTemplateApi.fetchARTemplateList,
      queryParams
    );

    yield put(actions.arTemplateListFetched(result));
  } catch (err) {
    console.log(err);
    yield put(actions.arTemplateListFetchedError());
  }
}

export default function* watchARTemplateListSaga() {
  yield takeLatest(actions.fetchARTemplateList.type, fetchARTemplateList);
  yield takeLatest(actions.deleteARTemplate.type, deleteARTemplate);
  yield takeEvery(actions.fetchTotalARTemplateList.type, fetchTotalARTemplateList);
}
