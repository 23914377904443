import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import groupApi from 'src/api_v2/group/group-list-api';
import { actions, GroupConfigurationType } from './slice';
import moment from 'moment';
import { authActions } from 'src/containers_v2/auth/store/slice';
import config from 'src/config';

function* saveGroup(action: PayloadAction<GroupConfigurationType>) {
  try {
    const result = yield call(
      action.payload.id ? groupApi.updateGroup : groupApi.addGroup,
      action.payload
    );

    if (result.valid) {
      yield put(actions.groupSaved());

      if (
        action.payload.createExpirationDate &&
        moment().isAfter(action.payload.createExpirationDate)
      ) {
        yield put(authActions.userArProjectCreationExpiredChecked(true));
      } else {
        yield put(authActions.userArProjectCreationExpiredChecked(false));
      }
    } else {
      yield put(
        actions.groupSavedError(
          result?.data?.response?.data?.message?.[0]
            ? result.data.response.data.message?.[0]
            : 'Fail to save group.'
        )
      );
    }
  } catch (err) {
    if (err.data.response) {
      const message = err.data.response.data?.message as string[];
      if (message?.includes('Email is duplicated')) {
        yield put(actions.groupSavedError('Email is duplicated'));
      } else {
        yield put(actions.groupSavedError('Fail to save group.'));
      }
    }
  }
}

function* getGroup(action: PayloadAction<string>) {
  try {
    const result = yield call(groupApi.getGroup, action.payload);

    if (result) {
      yield put(
        actions.groupGot({
          ...result,
          confirmPassword: result.password
        })
      );
    } else {
    }
  } catch (err) {
    yield put(actions.groupGotError('Fail to get group.'));
  }
}

export default function* watchGroupConfigurationSaga() {
  yield takeLatest(actions.saveGroup.type, saveGroup);
  yield takeLatest(actions.getGroup.type, getGroup);
}
