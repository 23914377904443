import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { profileConfigActions } from './slice';
import * as _ from 'lodash';
import profileApi from 'src/api_v2/profile';
import {
  NeutralVideoType,
  ProfileConfigurationType,
  ProfileContentType,
  ProfileFiltersType,
  ProfileSnapConfigType,
  UpdateNeutralVideoType,
  QrImageType,
  UpdateQrImageType,
} from 'src/redux/store/models';
import {
  selectContentTabData,
  selectGeneralProfile,
  selectSnapTabData,
  selectVideoTabData,
  selectQrImageTabData
} from './selector';
import mirrorTypeApi from 'src/api_v2/mirror-type';
import { MirrorTypeConfigurationType } from 'src/containers_v2/categories/mirror-type/mirror-type-configuration/store/slice';
import mirrorApi from 'src/api_v2/mirror';

function* fetchProfileFilters() {
  try {
    const result: ProfileFiltersType = yield call(
      profileApi.fetchProfileFilters
    );

    if (result) {
      yield put(profileConfigActions.filtersFetched(result));
    }
  } catch (err) {
    console.log(err);
    yield put(profileConfigActions.filtersFetchedError());
  }
}

function* fetchMirrorsByProfile(action: PayloadAction<number>) {
  try {
    const result = yield call(profileApi.fetchMirrorsByProfile, action.payload);

    yield put(profileConfigActions.mirrorsFetched(result));
  } catch (err) {
    console.log(err);
    yield put(profileConfigActions.mirrorsFetchedError());
  }
}

function* fetchSnapsByProfile(action: PayloadAction<number>) {
  try {
    const result = yield call(profileApi.fetchSnapsByProfile, action.payload);

    if (result) {
      yield put(profileConfigActions.snapsFetched(result));
    }
  } catch (err) {
    console.log(err);
    yield put(profileConfigActions.snapsFetchedError());
  }
}

function* fetchContentsByProfile(
  action: PayloadAction<ProfileConfigurationType>
) {
  try {
    const result = yield call(
      profileApi.fetchContentsByProfile,
      action.payload.id
    );

    if (result) {
      yield put(profileConfigActions.contentsFetched(result));
    }

    const mirrorTypeResult: MirrorTypeConfigurationType = yield call(
      mirrorTypeApi.getMirrorTypeById,
      action.payload.mirrorTypeId
    );

    if (mirrorTypeResult && mirrorTypeResult.content) {
      yield put(
        profileConfigActions.setMirrorTypeContents(
          mirrorTypeResult.content.map(
            (x) => ({ ...x, enabled: true } as ProfileContentType)
          )
        )
      );
    }
  } catch (err) {
    console.log(err);
    yield put(profileConfigActions.contentsFetchedError());
  }
}

function* saveProfile(action: PayloadAction<ProfileConfigurationType>) {
  try {
    const profile = _.clone(action.payload);
    const result = yield call(profileApi.saveProfile, profile);

    if (result && result.id > 0) {
      profile.id = result.id;
      yield put(profileConfigActions.profileSaved(profile));
      yield put(profileConfigActions.fetchProfile(profile.id.toString()));
    }
  } catch (err) {
    console.log(err);
    if (err.response) {
      const message = err.response.data?.message as string[];
      yield put(profileConfigActions.profileSavedError(message[0]));
    } else {
      yield put(profileConfigActions.profileSavedError(''));
    }
  }
}

function* saveProfileSnaps() {
  try {
    const profile = yield select(selectGeneralProfile);
    const { data: snaps } = yield select(selectSnapTabData);
    const payload: ProfileSnapConfigType = {
      profileId: profile.id,
      snaps: snaps
        .filter((x) => x.enable)
        .map((x) => ({ id: x.id, branch: x.branch }))
    };

    const result = yield call(profileApi.saveSnaps, payload);

    if (result) {
      yield put(profileConfigActions.snapsSaved());
    }
  } catch (err) {
    console.log(err);
    yield put(profileConfigActions.snapsSavedError());
  }
}

function* fetchProfileById(action: PayloadAction<string>) {
  try {
    const result = yield call(profileApi.getProfileById, action.payload);

    if (result) {
      yield put(profileConfigActions.profileFetched(result));
    }
  } catch (err) {
    console.log(err);
    yield put(profileConfigActions.profileFetchedError());
  }
}

function* saveProfileContents() {
  try {
    const profile = yield select(selectGeneralProfile);
    const { data: contents } = yield select(selectContentTabData);

    const result = yield call(profileApi.saveContents, profile.id, contents);

    if (result) {
      yield put(profileConfigActions.contentsSaved());
    }
  } catch (err) {
    console.log(err);
    yield put(profileConfigActions.contentsSavedError());
  }
}

function* syncMirrorTypeContents(action: PayloadAction<ProfileContentType[]>) {
  try {
    const profile = yield select(selectGeneralProfile);
    const result = yield call(
      profileApi.saveContents,
      profile.id,
      action.payload
    );

    if (result) {
      yield put(profileConfigActions.setContents(action.payload));
      yield put(profileConfigActions.contentsSaved());
    }
  } catch (err) {
    console.log(err);
    yield put(profileConfigActions.contentsSavedError());
  }
}

function* populateContentsOfMirrorType(action: PayloadAction<string>) {
  try {
    const result: MirrorTypeConfigurationType = yield call(
      mirrorTypeApi.getMirrorTypeById,
      action.payload
    );

    if (result && result.content) {
      yield put(
        profileConfigActions.contentsFetched(
          result.content.map(
            (x) => ({ ...x, enabled: true } as ProfileContentType)
          )
        )
      );
      yield put(
        profileConfigActions.setMirrorTypeContents(
          result.content.map(
            (x) => ({ ...x, enabled: true } as ProfileContentType)
          )
        )
      );
    }
  } catch (err) {
    console.log(err);
    yield put(profileConfigActions.contentsFetchedError());
  }
}

function* fetchNeutralVideos(action: PayloadAction<string | number>) {
  try {
    const result: NeutralVideoType[] = yield call(
      profileApi.getNeutralVideos,
      action.payload
    );

    if (result) {
      yield put(profileConfigActions.videosFetched(result));
    }
  } catch (err) {
    console.log(err);
    yield put(profileConfigActions.videoFetchedError());
  }
}

function* updatedNeutalVideos() {
  try {
    const { data: videos } = yield select(selectVideoTabData);
    const profile = yield select(selectGeneralProfile);
    const payload: UpdateNeutralVideoType = {
      videos: videos.map((x, index) => ({ ...x, sequence: index + 1 }))
    };

    const result = yield call(profileApi.updateNeutralVideos, payload);

    if (result) {
      yield put(profileConfigActions.videosUpdated());
      yield call(mirrorApi.syncProfileVideosToOverrideOnMirrors, profile.id);
    }
  } catch (err) {
    console.log(err);
    yield put(profileConfigActions.videosUpdatedError());
  }
}

function* fetchProfileVoiceovers(action: PayloadAction<string | number>) {
  try {
    const result: any = yield call(
      profileApi.getProfileVoiceovers,
      action.payload
    );

    if (result) {
      const setting: any = {
        id: result.id,
        profileId: result.profileId,
        isOverride: result.isOverride || false
      };

      yield put(profileConfigActions.setVoiceSetting(setting));

      yield put(
        profileConfigActions.setVoiceovers(result.profileVoiceovers || [])
      );
    }
  } catch (err) {}
}

function* deleteNeutralVideo(action: PayloadAction<string | number>) {
  try {
    const profile = yield select(selectGeneralProfile);
    const result = yield call(profileApi.deleteNeutralVideo, action.payload);

    if (result) {
      yield put(profileConfigActions.videoDeleted());

      yield put(profileConfigActions.fetchVideos(profile.id));
    }
  } catch (err) {
    yield put(profileConfigActions.videoDeletedError());
  }
}

function* fetchPhotos(action: PayloadAction<string | number>) {
  try {
    const result = yield call(profileApi.getPhotos, action.payload);

    if (result) {
      yield put(profileConfigActions.photosFetched(result));
    } else {
      yield put(profileConfigActions.photosFetchedError());
    }
  } catch (err) {
    yield put(profileConfigActions.photosFetchedError());
  }
}

function* updatedQrImages() {
  try {
    const { data: qrImages } = yield select(selectQrImageTabData);
    const profile = yield select(selectGeneralProfile);
    const payload: UpdateQrImageType = {
      qrImages: qrImages.map((x, index) => ({ ...x, sequence: index + 1 }))
    };

    const result = yield call(profileApi.updateQrImages, payload);

    if (result) {
      yield put(profileConfigActions.qrImagesUpdated());
      yield call(mirrorApi.syncProfileQrImagesToOverrideOnMirrors, profile.id);
    }
  } catch (err) {
    console.log(err);
    yield put(profileConfigActions.qrImagesUpdatedError());
  }
}

function* fetchQrImages(action: PayloadAction<string | number>) {
  try {
    const result: QrImageType[] = yield call(
      profileApi.getQrImages,
      action.payload
    );

    if (result) {
      yield put(profileConfigActions.qrImageFetched(result));
    }
  } catch (err) {
    console.log(err);
    yield put(profileConfigActions.qrImageFetchedError());
  }
}

function* deleteQrImage(action: PayloadAction<string | number>) {
  try {
    const profile = yield select(selectGeneralProfile);
    const result = yield call(profileApi.deleteQrImage, action.payload);

    if (result) {
      yield put(profileConfigActions.qrImageDeleted());

      yield put(profileConfigActions.fetchQrImages(profile.id));
    }
  } catch (err) {
    yield put(profileConfigActions.qrImageDeletedError());
  }
}

export default function* watchProfileConfigurationSaga() {
  yield takeLatest(profileConfigActions.fetchFilters.type, fetchProfileFilters);
  yield takeLatest(
    profileConfigActions.fetchMirrors.type,
    fetchMirrorsByProfile
  );
  yield takeLatest(profileConfigActions.fetchSnaps.type, fetchSnapsByProfile);
  yield takeLatest(
    profileConfigActions.fetchContentsByProfile.type,
    fetchContentsByProfile
  );
  yield takeLatest(profileConfigActions.saveProfile.type, saveProfile);
  yield takeLatest(profileConfigActions.saveSnaps.type, saveProfileSnaps);
  yield takeLatest(profileConfigActions.fetchProfile.type, fetchProfileById);
  yield takeLatest(profileConfigActions.saveContents.type, saveProfileContents);
  yield takeLatest(
    profileConfigActions.populateContentsOfMirrorType.type,
    populateContentsOfMirrorType
  );
  yield takeLatest(profileConfigActions.fetchVideos.type, fetchNeutralVideos);
  yield takeLatest(profileConfigActions.updateVideos.type, updatedNeutalVideos);
  yield takeLatest(
    profileConfigActions.fetchVoiceovers.type,
    fetchProfileVoiceovers
  );
  yield takeLatest(profileConfigActions.deleteVideo.type, deleteNeutralVideo);
  yield takeLatest(profileConfigActions.fetchPhotos.type, fetchPhotos);
  yield takeLatest(
    profileConfigActions.syncMirrorTypeContents.type,
    syncMirrorTypeContents
  );
  yield takeLatest(profileConfigActions.fetchQrImages.type, fetchQrImages);
  yield takeLatest(profileConfigActions.deleteQrImage.type, deleteQrImage);
  yield takeLatest(profileConfigActions.updateQrImages.type, updatedQrImages);
}
