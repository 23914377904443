import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import mirrorTypeApi from 'src/api_v2/mirror-type';
import { mirrorTypeActions } from './slice';
import { selectQuery } from './selector';
import { FetchMirrorTypeParams } from 'src/api_v2/mirror-type/types';

function* fetchMirrorTypes() {
  try {
    const { search, page, pageSize, sort } = yield select(selectQuery);
    const queryParams = new FetchMirrorTypeParams(page, pageSize, search, sort);
    const result = yield call(mirrorTypeApi.fetchMirrorTypes, queryParams);

    if (result) {
      yield put(mirrorTypeActions.mirrorTypesFetched(result));
    } else {
      yield put(mirrorTypeActions.mirrorTypesFetchedError());
    }
  } catch (err) {
    yield put(mirrorTypeActions.mirrorTypesFetchedError());
  }
}

function* deleteMirrorType(action: PayloadAction<number>) {
  try {
    const result = yield call(mirrorTypeApi.deleteMirrorType, action.payload);

    if(result) {
      yield put(mirrorTypeActions.mirrorTypeDeleted());
      yield call(fetchMirrorTypes)
    }
  } catch (err) {
    yield put(mirrorTypeActions.mirrorTypeDeletedError());
  }
} 

export default function* watchMirrorTypeListSaga() {
  yield takeLatest(mirrorTypeActions.fetchMirrorTypes.type, fetchMirrorTypes);
  yield takeLatest(mirrorTypeActions.deleteMirrorType.type, deleteMirrorType);
}
