import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { ARTemplateListPageResult } from 'src/api_v2/ar-template/types';
import IPagination from 'src/models/pagination';

export enum ARTEMPLATE_ROLE {
  ADMIN = 'admin',
  MIRROR_OWNER = 'mirrors-owner',
  AR_OWNER = 'ar_owner',
  AR_ADMIN = 'ar_admin'
}

export type ARTemplateType = {
  id?: number;
  description?: string;
  name?: string;
  link?: string;
  preview_link?: string;
  enable?: boolean;
  number_of_qrcode?: string | number;
  type?: string;
};
export interface ARTemplateListState extends IPagination {
  total: number;
  data: ARTemplateType[];
  loading: boolean;
  error: boolean;
  deleted: boolean;
  type?: string | "all"
}

export const initialState: ARTemplateListState = {
  data: [],
  total: 0,
  page: 1,
  pageSize: 50,
  sort: 'asc',
  search: '',
  loading: false,
  error: false,
  deleted: false,
  type: "all"
};

const ARTemplateListSlice = createSlice({
  name: 'arTemplate',
  initialState,
  reducers: {
    resetState: () => initialState,
    fetchARTemplateList(state: ARTemplateListState) {
      state.loading = true;
    },
    arTemplateListFetched(
      state: ARTemplateListState,
      action: PayloadAction<ARTemplateListPageResult>
    ) {
      state.loading = false;
      state.error = false;
      state.data = action.payload.data
      state.total = action.payload.totalItems;
    },
    arTemplateListFetchedError(state: ARTemplateListState) {
      state.loading = false;
      state.error = true;
    },
    setCurrentPage(state: ARTemplateListState, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setSearch(state: ARTemplateListState, action: PayloadAction<string>) {
      state.search = action.payload;
    },
    setSort(state: ARTemplateListState, action: PayloadAction<string>) {
      state.sort = action.payload;
    },
    deleteARTemplate(state: ARTemplateListState, action: PayloadAction<number>) {
      state.loading = true;
      state.error = false;
      state.deleted = false;
    },
    arTemplateDeleted(state: ARTemplateListState) {
      state.deleted = true;
    },
    useDeletedError(state: ARTemplateListState) {
      state.loading = false;
      state.error = true;
    },
    resetDeleted(state: ARTemplateListState) {
      state.deleted = false;
    },
    setPageSize(state: ARTemplateListState, action: PayloadAction<number>) {
      state.pageSize = action.payload;
    },
    fetchTotalARTemplateList(state: ARTemplateListState) {
      state.loading = true;
    },
    updateData(state: ARTemplateListState,action: PayloadAction<ARTemplateType[]>) {
      state.data = action.payload;
    },
    setType(state: ARTemplateListState, action: PayloadAction<string | "all">) {
      state.type = action.payload;
    },
  }
});

export const { actions, reducer, name: sliceKey } = ARTemplateListSlice;
