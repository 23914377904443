import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  FetchGroupListParams,
  GroupListPageResult
} from 'src/api_v2/group/types';
import groupApi from 'src/api_v2/group/group-list-api';
import {
  selectPage,
  selectPageSize,
  selectSearch,
  selectSort
} from './selector';
import { actions } from './slice';

function* fetchGroupList() {
  try {
    const page = yield select(selectPage);
    const pageSize = yield select(selectPageSize);
    const search = yield select(selectSearch);
    const sort = yield select(selectSort);
    const queryParams = new FetchGroupListParams(page, pageSize, search, sort);
    const result: GroupListPageResult = yield call(
      groupApi.fetchGroupList,
      queryParams
    );

    yield put(actions.groupListFetched(result));
  } catch (err) {
    console.log(err);
    yield put(actions.groupListFetchedError());
  }
}

function* fetchRemainingCredit(action: PayloadAction<number>) {
  try {
    const userId = action.payload;
    const result: number = yield call(
      groupApi.fetchRemainingCredit,
      userId
    );

    yield put(actions.fetchedRemainingCredit(result));
  } catch (err) {
    console.log(err);
    yield put(actions.fetchedRemainingCreditError());
  }
}

function* deleteGroup(action: PayloadAction<number>) {
  try {
    const userId = action.payload;
    if (userId) {
      yield call(groupApi.deleteGroup, userId);

      yield put(actions.groupDeleted());
      yield call(fetchGroupList);
    }
  } catch (err) {
    if (err.response) {
      const message = err.response.data?.message as string[];
      console.log(message);

      if (message[0]) {
        yield put(actions.groupDeletedError(message[0]));
      }
    } else {
      yield put(actions.groupDeletedError(''));
    }
  }
}

function* fetchTotalGroupList() {
  try {
    const search = yield select(selectSearch);
    const sort = yield select(selectSort);

    const queryParams = new FetchGroupListParams(
      1,
      Number.MAX_SAFE_INTEGER,
      search,
      sort
    );
    const result: GroupListPageResult = yield call(
      groupApi.fetchGroupList,
      queryParams
    );

    yield put(actions.groupListFetched(result));
  } catch (err) {
    console.log(err);
    yield put(actions.groupListFetchedError());
  }
}

export default function* watchGroupListSaga() {
  yield takeLatest(actions.fetchGroupList.type, fetchGroupList);
  yield takeLatest(actions.deleteGroup.type, deleteGroup);
  yield takeEvery(actions.fetchTotalGroupList.type, fetchTotalGroupList);
  yield takeEvery(actions.fetchRemainingCredit.type, fetchRemainingCredit);
}
