import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export type SnapConfigurationType = {
  id?: string;
  name?: string;
  edge?: string;
  beta?: string;
  candidate?: string;
  stable?: string;
  versions?: VersionSnap[];
};

export type VersionSnap = {
  file_name?: string;
  name: string;
  revision: string;
};

export interface SnapConfigurationState {
  loading?: boolean;
  error?: string;
  saved?: boolean;
  editingSnap?: SnapConfigurationType;
  versions?: VersionSnap[];
}

export const initialState: SnapConfigurationState = {
  loading: false,
  error: '',
  saved: false,
  editingSnap: undefined,
  versions: []
};

const snapConfigurationlice = createSlice({
  name: 'snapConfiguration',
  initialState,
  reducers: {
    reset(state: SnapConfigurationState) {
      state.saved = false;
      state.editingSnap = undefined;
    },
    saveSnap(
      state: SnapConfigurationState,
      action: PayloadAction<SnapConfigurationType>
    ) {
      state.loading = true;
      state.error = '';
      state.saved = false;
    },
    snapSaved(state: SnapConfigurationState) {
      state.loading = false;
      state.saved = true;
    },
    snapSavedError(
      state: SnapConfigurationState,
      action: PayloadAction<string>
    ) {
      state.loading = false;
      state.error = action.payload;
    },
    getSnap(state: SnapConfigurationState, action: PayloadAction<string>) {
      state.loading = true;
    },
    snapGot(
      state: SnapConfigurationState,
      action: PayloadAction<SnapConfigurationType>
    ) {
      state.loading = false;
      state.editingSnap = action.payload;
      state.versions = action.payload.versions
        ? action.payload.versions.sort(
            (a, b) => Number(b.revision) - Number(a.revision)
          )
        : [];
    },
    snapGotError(state: SnapConfigurationState, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    }
  }
});

export const { actions, reducer, name: sliceKey } = snapConfigurationlice;
