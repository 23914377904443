import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

// please add new contents at the bottom
export enum MIRROR_CONTENT {
  CHILD_TEMPLE_GAME = 0,
  CHILD_SQUAT_GAME = 1,
  CHILD_FLAG_GAME = 2,
  CHILD_QUIZ = 3,
  ADULT_TEMPLE_GAME = 4,
  ADULT_SQUAT_GAME = 5,
  ADULT_FLAG_GAME = 6,
  ADULT_QUIZ = 7,
  ADULT_SKIN_AGE = 8,
  ADULT_COMPATIBILITY = 9,
  ADULT_INSURANCE = 10,
  ADULT_FACE_READING = 11,
  ADULT_OMIKUJI = 12,
  ADULT_AGING = 13,
  ADULT_AFLAC_JP_POST = 14,
  UNKNOWN = 15,
  CHILD_DARUMA_GAME = 16,
  CHILD_BUTTERFLY_GAME = 17,
  ADULT_DARUMA_GAME = 18,
  ADULT_BUTTERFLY_GAME = 19,
  ADULT_AFLAC_SOUDAN_VIDEO = 20,
}

export enum USER_REVIEW {
  GOOD,
  SOSO,
  NOT_GOOD,
  BETTER,
  MAJOR_INSCREASE,
  SLIGHT_INSCREASE,
  NO_CHANGE,
}

export enum USER_GENDER {
  MALE,
  FEMALE,
  UNKNOWN,
}

export interface SurveyReportData {
  appVersion: string,
  mirrorId: string,
  content: MIRROR_CONTENT,
  gender: USER_GENDER,
  age: number,
  review: USER_REVIEW,
  questionNo: number,
  datetimeUtcMilis: number,
}

export interface SurveyReportState {
  data: SurveyReportData[],
  loading: boolean,
  error: boolean
}

export const initialState: any = {
  data: [],
  loading: false,
  error: false,
}

const surveyReportSlice = createSlice({
  name: "surveyReport",
  initialState,
  reducers: {
    fetchSurveys(state: SurveyReportState) {
      state.loading = true
    },
    surveyFetched(state: SurveyReportState, action: PayloadAction<SurveyReportData[]>) {
      state.loading = false
      state.error = false
      state.data = action.payload
    },
    surveyFetchedError(state: SurveyReportState) {
      state.loading = false
      state.error = false
    }
  }
})

export const { actions, reducer, name: sliceKey } = surveyReportSlice;