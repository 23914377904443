import { AxiosResponse } from 'axios';
import moment, { Moment } from 'moment';
// import {
//   GetMirrorsResult,
//   UserResponse,
//   UserRoles
// } from './mirrors-console-api';
import agent from 'src/api_v2/base';
import { UserRoles, UserResponse } from 'src/utils/constants';
import {
  FetchSummaryReportParams,
  FiltersPeopleCounterResult,
  SummaryReportPageResult
} from './types';
import { helpers } from 'src/utils/helpers';
class PeopleCounterApi {
  fetchPeopleCounter = async (
    mirrorIds: string[],
    startDate: Moment,
    endDate: Moment,
    groupByHour: boolean = false,
    countByTime: boolean = false,
    shopName: string
  ) => {
    try {
      const startDateFormat = startDate.format('YYYYMMDD');
      const endDateFormat = endDate.format('YYYYMMDD');
      const dateQueryParams = `&from=${startDateFormat}&to=${endDateFormat}`;

      const response: AxiosResponse = await agent.post(
        `/people-counter?${dateQueryParams}&groupByHour=${groupByHour}&countByTime=${countByTime}&shopName=${shopName}`,
        {
          mirrorIds
        }
      );

      if (response.status !== 201) {
        throw new Error(response.statusText);
      }

      return response.data;
    } catch (err) {
      console.log(err);
    }
  };

  fetchAllMirrors = async (user: UserResponse) => {
    const path = `mirrors/${
      user.role.includes(UserRoles.toString(UserRoles.Mirrors_owner))
        ? user.id.toString()
        : ''
    }`;

    const response: AxiosResponse = await agent.get(path);

    if (response.status !== 200) {
      throw new Error(response.statusText);
    }

    return response.data;
  };

  fetchPeopleVideoTracking = async (
    mirrorId: string,
    startDate: Moment,
    endDate: Moment
  ) => {
    try {
      const startUtcMilis = startDate.utc().valueOf();
      const endUtcMilis = endDate.utc().valueOf();
      const dateQueryParams = `&startUtcMilis=${startUtcMilis}&endUtcMilis=${endUtcMilis}`;

      const response: AxiosResponse = await agent.get(
        `/people-video-tracking?mirrorId=${mirrorId}${dateQueryParams}`
      );

      if (response.status !== 200) {
        throw new Error(response.statusText);
      }

      return response.data.map((x) => ({
        ...x,
        datetime_utc_milis: Number(x.datetime_utc_milis)
      }));
    } catch (err) {
      console.log(err);
    }
  };

  async fetchSummaryReport(params: FetchSummaryReportParams) {
    try {
      const response: AxiosResponse = await agent.get(
        `/people-counter/summary?${params.toString()}`
      );

      if (response.status === 200) {
        return response.data as SummaryReportPageResult;
      }

      return [];
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  exportCSV = async (
    mirrorIds: string[],
    startDate: Moment,
    endDate: Moment,
    groupByHour: boolean = false,
    isShowTotal: boolean = false,
    exportAll: boolean = false,
    shopName: string = ''
  ) => {
    const startDateFormat = startDate.format('YYYYMMDD');
    const endDateFormat = endDate.format('YYYYMMDD');
    const dateQueryParams = `&from=${startDateFormat}&to=${endDateFormat}`;

    const response = await agent.post(
      `/people-counter/export-csv?${dateQueryParams}&groupByHour=${groupByHour}&isShowTotal=${isShowTotal}&exportAll=${exportAll}&shopName=${shopName}`,
      {
        mirrorIds
      },
      {
        responseType: 'blob'
      }
    );

    if (response.status === 201) {
      const name = !exportAll
        ? `people-counter-raw-data-duration_${moment(startDateFormat).format(
            'YYYYMMDD'
          )}-${moment(endDateFormat).format('YYYYMMDD')}.csv`
        : `people-counter-data-${moment().format('YYYY-MM-DD, HH-mm')}.csv`;
      
      helpers.exportCSV(
        response.data,
        name
      );

      return true;
    }
    return false;
  };

  exportNeutralVideoTracking = async (
    mirrorIds: number[],
    startDate: Moment,
    endDate: Moment
  ) => {
    try {
      const startUtcMilis = startDate.utc().valueOf();
      const endUtcMilis = endDate.utc().valueOf();
      const dateQueryParams = `&startUtcMilis=${startUtcMilis}&endUtcMilis=${endUtcMilis}`;

      const response: AxiosResponse = await agent.post(
        `/people-video-tracking?${dateQueryParams}`,
        {
          mirrorIds
        },
        {
          responseType: 'blob'
        }
      );

      if (response.status === 201) {
        helpers.exportCSV(
          response.data,
          `neutral-video-tracking-${moment().format('YYYY-MM-DD, HH-mm')}.csv`
        );

        return true;
      }
      return false;
    } catch (err) {
      console.log(err);
    }
  };

  async fetchFilters() {
    try {
      const response: AxiosResponse = await agent.get(
        `/people-counter/filters`
      );

      if (response.status === 200) {
        return response.data as FiltersPeopleCounterResult;
      }

      return undefined;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
}

export { PeopleCounterApi };
