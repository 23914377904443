import { QuizType } from "src/containers_v2/puzzle/quiz/quiz-list/store/slice";
import { UserType } from "src/containers_v2/user-management/user-list/store/slice";
import IPagination from "src/models/pagination";

export class FetchQuizListParams implements IPagination {
  page: number;
  category: string;
  search: string;
  count: number;
  pageSize: number;
  sort: string;

  constructor(
    page: number = 1,
    category: string = "8",
    search: string = '',
    count: number = 1,
  ) {
    this.page = page;
    this.category = category;
    this.search = search;
    this.count = count;
  }

  toString() {
    return `${this.page}/${this.category}/${this.search}/${this.count}`;
  }
}

export type QuizListPageResult = {
  data: QuizType[],
  totalCount: number
}
