import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store/configureStore';
import { MirrorConfigurationState } from './slice';

const selectDomain = (state: RootState) => {
  return state.mirrorConfig;
};

export const selectFilters = createSelector(
  [selectDomain],
  (state: MirrorConfigurationState) => state.filters
)

export const selectSaved = createSelector(
  [selectDomain],
  (state: MirrorConfigurationState) => state.saved
)

export const selectError = createSelector(
  [selectDomain],
  (state: MirrorConfigurationState) => state.error
)

export const selectLoading = createSelector(
  [selectDomain],
  (state: MirrorConfigurationState) => state.loading
)

export const selectVoiceoverTabData = createSelector(
  [selectDomain],
  (state: MirrorConfigurationState) => state.voiceoverTab
);

export const selectGeneralMirror = createSelector(
  [selectDomain],
  (state: MirrorConfigurationState) => state.mirror
);

export const selectPhotoTabData = createSelector(
  [selectDomain],
  (state: MirrorConfigurationState) => state.photoTab
);

export const selectSelectedProfileId = createSelector(
  [selectDomain],
  (state: MirrorConfigurationState) => state.selectedProfileId
);

export const selectReservationTabData = createSelector(
  [selectDomain],
  (state: MirrorConfigurationState) => state.reservationTab
);

export const selectVideoTabData = createSelector(
  [selectDomain],
  (state: MirrorConfigurationState) => state.videoTab
);

export const selectQrImageTabData = createSelector(
  [selectDomain],
  (state: MirrorConfigurationState) => state.qrImageTab
);