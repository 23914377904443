import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  FetchARProjectList,
  ARProjectListPageResult
} from 'src/api_v2/ar-project/types';
import arPamphletApi from 'src/api_v2/ar-pamphlet';
import { selectTemplateId, selectUserId, selectQuery } from './selector';
import { actions } from './slice';
import {
  ARPamphletListPageResult,
  FetchARPamphletParams
} from 'src/api_v2/ar-pamphlet/types';

function* fetchList() {
  try {
    const { search, sort, page, pageSize } = yield select(selectQuery);
    const template_id = yield select(selectTemplateId);
    const user_id = yield select(selectUserId);
    const queryParams = new FetchARPamphletParams(
      page,
      pageSize,
      search,
      sort,
      template_id === 'all' ? '' : template_id,
      user_id === 'all' ? '' : user_id,
      1
    );
    const result: ARPamphletListPageResult = yield call(
      arPamphletApi.fetchList,
      queryParams
    );

    yield put(actions.listFetched(result));
  } catch (err) {
    console.log(err);
    yield put(actions.listFetchedError());
  }
}

function* deleteItem(action: PayloadAction<number>) {
  try {
    const userId = action.payload;
    if (userId) {
      yield call(arPamphletApi.deleteItem, userId);

      yield put(actions.deletedItem());
      yield call(fetchList);
    }
  } catch (err) {
    console.log(err);
    yield put(actions.useDeletedError());
  }
}

function* fetchTotalList() {
  try {
    const { search, sort } = yield select(selectQuery);

    const queryParams = new FetchARProjectList(
      1,
      Number.MAX_SAFE_INTEGER,
      search,
      sort
    );
    const result: ARProjectListPageResult = yield call(
      arPamphletApi.fetchList,
      queryParams
    );

    yield put(actions.listFetched(result));
  } catch (err) {
    console.log(err);
    yield put(actions.listFetchedError());
  }
}

function* exportCSV() {
  try {
    const { search, template_id, user_id, page, pageSize, sort } = yield select(
      selectQuery
    );

    const queryParams = new FetchARPamphletParams(
      page,
      pageSize,
      search,
      sort,
      template_id === 'all' ? '' : template_id,
      user_id === 'all' ? '' : user_id,
      1
    );
    const result = yield call(arPamphletApi.exportArPamphletCsv, queryParams);

    if (result) {
      yield put(actions.csvExported());
    }
  } catch (err) {
    console.log(err);
  }
}

function* updateStatus(action: PayloadAction<{id: number, status: boolean}>) {
  try {
    yield call(arPamphletApi.updateStatus, action.payload.id, action.payload.status)
  } catch (err) {
    console.log(err);
  }
}

export default function* watchListSaga() {
  yield takeLatest(actions.fetchList.type, fetchList);
  yield takeLatest(actions.deleteItem.type, deleteItem);
  yield takeEvery(actions.fetchTotalList.type, fetchTotalList);
  yield takeEvery(actions.exportCSV.type, exportCSV);
  yield takeEvery(actions.updateStatus.type, updateStatus);
}
