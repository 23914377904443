import { AxiosResponse } from 'axios';
import agent from 'src/api_v2/base';
import { ARTemplateConfigurationType } from 'src/containers_v2/ar_creator/ARTemplate/ar-configuration/store/slice';
import { FetchARTemplateListParams, ARTemplateListPageResult } from './types';

class ARTemplateApi {
  async fetchARTemplateList(params: FetchARTemplateListParams) {
    try {
      const response: AxiosResponse = await agent.get(
        `/artemplates?${params.toString()}`
      );

      if (response.status === 200) {
        return response.data as ARTemplateListPageResult;
      }

      return []
    } catch (err) {
      throw new Error(err);
    }
  }

  async deleteARTemplate(id: number) {
    try {
      const response: AxiosResponse = await agent.delete(
        `/artemplates/${id}`
      );

      if(response.status !== 200) {
        return false
      } 

      return true
    } catch (err) {
      throw err;
    }
  }

  async addARTemplate(arTemplate: ARTemplateConfigurationType) {
    try {
      const response: AxiosResponse = await agent.post(
        `/artemplates`,
        arTemplate
      );

      if(response.status !== 200 && response.status !== 201) {
        return false
      }

      return true
    } catch (err) {
      return false;
    }
  }

  async updateARTemplate(arTemplate: ARTemplateConfigurationType) {
    try {
      const response: AxiosResponse = await agent.put(
        `/artemplates`,
        arTemplate
      );

      if(response.status !== 200 && response.status !== 204) {
        return false
      }

      return true
    } catch (err) {
      return false;
    }
  }

  async getARTemplate(id: string): Promise<any> {
    try {
      const response: AxiosResponse = await agent.get(
        `/artemplates/${id}`
      );

      if (response.status === 200) {
        return response.data;
      }

      return undefined
    } catch (err) {
      throw err;
    }
  }
}

const arTemplateListApi = new ARTemplateApi();
export default arTemplateListApi;
