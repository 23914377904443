import { VideosType } from 'src/containers_v2/categories/videos/videos-list/store/slice';
import { SurveyType } from 'src/containers_v2/survey/store/model';
import IPagination from 'src/models/pagination';

export class FetchVideosParams implements IPagination {
  page: number;
  pageSize: number;
  search: string; // videos
  sort: string;
  expiredDate: string;
  includeProfiles: boolean;
  addedVideoIds: string;

  constructor(
    page: number = 1,
    pageSize: number = 20,
    search: string = '',
    sort: string = 'name|asc',
    expiredDate: string = '',
    includeProfiles: boolean = false,
    addedVideoIds: string = ""
  ) {
    this.page = page;
    this.pageSize = pageSize;
    this.search = search;
    this.sort = sort;
    this.expiredDate = expiredDate;
    this.includeProfiles = includeProfiles;
    this.addedVideoIds = addedVideoIds;

  }

  toString() {
    const data = [{
      param: `page=${this.page}`,
      value: this.page
    }, {
      param: `size=${this.pageSize}`,
      value: this.pageSize
    }, {
      param: `name=${this.search}`,
      value: this.search
    }, {
      param: `sort=${this.sort}`,
      value: this.sort
    }, {
      param: `expiredDate=${this.expiredDate}`,
      value: this.expiredDate
    }, {
      param: `includeProfiles=${this.includeProfiles}`,
      value: this.includeProfiles
    }, {
      param: `addedVideoIds=${this.addedVideoIds}`,
      value: this.addedVideoIds
    }]

    const dataParams = data.reduce((init, item) => {
      if (!!item.value) {
        return [...init, item.param]
      }
      return [...init]
    }, [])?.join("&")

    return dataParams;
  }
}

export type VideosPageResult = {
  data: VideosType[];
  totalCount: number;
};

export type VideosResult = {
  mirrorTypes: string[];
};
