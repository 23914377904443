import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import contentApi from 'src/api_v2/content';
import { actions, ContentConfigurationType } from './slice';

function* saveContent(action: PayloadAction<ContentConfigurationType>) {
  
}

function* getContent(action: PayloadAction<string>) {
  try {
    const result = yield call(contentApi.getContent, action.payload);

    if (result) {
      yield put(
        actions.contentGot({
          ...result,
          confirmPassword: result.password
        })
      );
    } else {
    }
  } catch (err) {
    yield put(actions.contentGotError('Fail to get content.'));
  }
}

export default function* watchContentConfigurationSaga() {
  yield takeLatest(actions.saveContent.type, saveContent);
  yield takeLatest(actions.getContent.type, getContent);
}
