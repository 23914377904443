import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store/configureStore';
import { ConfigurationState } from './slice';

const selectDomain = (state: RootState) => {
  return state.arProjectConfiguration;
};

export const selectLoading = createSelector(
  [selectDomain],
  (state: ConfigurationState) => state.loading
);

export const selectError = createSelector(
  [selectDomain],
  (state: ConfigurationState) => state.error
);

export const selectSaved = createSelector(
  [selectDomain],
  (state: ConfigurationState) => state.saved
);

export const selectEditting = createSelector(
  [selectDomain],
  (state: ConfigurationState) => state.editing
);

export const selectDataTracking = createSelector(
  [selectDomain],
  (state: ConfigurationState) => state.dataTracking
);

export const selectQuery = createSelector(
  [selectDomain],
  (state: ConfigurationState) => ({
    from: state.from,
    to: state.to,
  })
);