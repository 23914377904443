import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  FetchProfileAuditListParams,
  ProfileAuditListPageResult
} from 'src/api_v2/profile-audit/types';
import profileAuditApi from 'src/api_v2/profile-audit/profile-audit-list-api';
import {
  selectPage,
  selectPageSize,
  selectSearch,
  selectSort
} from './selector';
import { actions } from './slice';

function* fetchProfileAuditList() {
  try {
    const page = yield select(selectPage);
    const pageSize = yield select(selectPageSize);
    const search = yield select(selectSearch);
    const sort = yield select(selectSort);
    const queryParams = new FetchProfileAuditListParams(page, pageSize, search, sort);
    const result: ProfileAuditListPageResult = yield call(
      profileAuditApi.fetchProfileAuditList,
      queryParams
    );

    yield put(actions.profileAuditListFetched(result));
  } catch (err) {
    console.log(err);
    yield put(actions.profileAuditListFetchedError());
  }
}

function* fetchTotalProfileAuditList() {
  try {
    const search = yield select(selectSearch);
    const sort = yield select(selectSort);

    const queryParams = new FetchProfileAuditListParams(
      1,
      Number.MAX_SAFE_INTEGER,
      search,
      sort
    );
    const result: ProfileAuditListPageResult = yield call(
      profileAuditApi.fetchProfileAuditList,
      queryParams
    );

    yield put(actions.profileAuditListFetched(result));
  } catch (err) {
    console.log(err);
    yield put(actions.profileAuditListFetchedError());
  }
}

function* exportFile() {
  const page = yield select(selectPage);
  const pageSize = yield select(selectPageSize);
  const search = yield select(selectSearch);
  const sort = yield select(selectSort);
  const queryParams = new FetchProfileAuditListParams(page, pageSize, search, sort);

  try {
    yield call(profileAuditApi.exportFile,queryParams)

    yield put(actions.unscreenHistoryExported())
  } catch (err) {
    console.log(err);
    yield put(actions.unscreenHistoryExportedFail());
  }
}

export default function* watchProfileAuditListSaga() {
  yield takeLatest(actions.fetchProfileAuditList.type, fetchProfileAuditList);
  yield takeEvery(actions.fetchTotalProfileAuditList.type, fetchTotalProfileAuditList);
  yield takeEvery(actions.exportFile.type, exportFile);
}
