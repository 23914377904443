import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import { actions as peopleCounterActions } from '../../people-counter-report/store/slice';
import { PeopleCounterApi } from 'src/api/people-counter-api';
import { SelectUser } from 'src/redux/store/user/selector';
import { GetMirrorsResult } from 'src/api/mirrors-console-api';

function* fetchAllMirrors() {
  try {
    const peopleCounterApi = new PeopleCounterApi();
    const user = (yield select(SelectUser)).account;
    const result: GetMirrorsResult[] = yield call(
      peopleCounterApi.fetchAllMirrors,
      user
    );

    if (result.length > 0) {
      const mirrors = result.map((x) => x.uuid);
      yield put(actions.allMirrorsFetched(mirrors));
      yield put(peopleCounterActions.setMirrorId(mirrors[0]));
    }
  } catch (err) {
    yield put(actions.allMirrorsFetchedError());
  }
}

export default function* watchMirror() {
  yield takeLatest(actions.fetchAllMirrors.type, fetchAllMirrors);
}
