import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import authApi from 'src/api_v2/auth/auth-api';
import { actions, ResetPasswordType } from './slice';

function* sendLink(action: PayloadAction<string>) {
  try {
    const email = action.payload;
    const result = yield call(authApi.sendResetPasswordLink, email);

    if (result) {
      yield put(actions.linkSent());
    } else {
      yield put(actions.linkSentError());
    }
  } catch (e) {
    yield put(actions.linkSentError());
  }
}

function* resetPassword(action: PayloadAction<ResetPasswordType>) {
  try {
    const result = yield call(authApi.resetPassword, action.payload);
    if (result) {
      yield put(actions.passwordReseted());
    } else {
      yield put(actions.passwordResetError());
    }
  } catch (err) {
    yield put(actions.passwordResetError());
  }
}

export default function* watchUserListSaga() {
  yield takeLatest(actions.sendResetLink.type, sendLink);
  yield takeLatest(actions.resetPassword.type, resetPassword);
}
