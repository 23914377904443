import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { TYPE_VOICEOVER_ENUM } from 'src/containers_v2/mirror-configuration/mirror/mirror-configuration/store/slice';
import { VOICEOVER_NAME_NUM, VOICEOVER_STEP } from 'src/redux/store/models';

type UploaderType = {
  uploading: boolean;
  success: boolean;
  error: boolean;
};

export interface UploaderState {
  voiceover: {
    [key: string]: UploaderType;
  };
  video: UploaderType;
  photos: UploaderType;
}

export const initialState: UploaderState = {
  voiceover: {
    step1: {
      uploading: false,
      success: false,
      error: false
    },
    step2: {
      uploading: false,
      success: false,
      error: false
    },
    step_nobody: {
      uploading: false,
      success: false,
      error: false
    },
    step_fixed_duration: {
      uploading: false,
      success: false,
      error: false
    }
  },
  video: {
    uploading: false,
    success: false,
    error: false
  },
  photos: {
    uploading: false,
    success: false,
    error: false
  }
};

const uploaderSlice = createSlice({
  name: 'uploader',
  initialState,
  reducers: {
    uploadVoiceover(
      state: UploaderState,
      action: PayloadAction<VOICEOVER_STEP>
    ) {
      state.voiceover[action.payload].uploading = true;
    },
    voiceoverUploaded(
      state: UploaderState,
      action: PayloadAction<VOICEOVER_STEP>
    ) {
      state.voiceover[action.payload].uploading = false;
      state.voiceover[action.payload].success = true;
    },
    uploadVoiceoverMirror(
      state: UploaderState,
      action: PayloadAction<TYPE_VOICEOVER_ENUM>
    ) {
      state.voiceover[VOICEOVER_NAME_NUM[action.payload]].uploading = true;
    },
    voiceoverMirrorUploaded(
      state: UploaderState,
      action: PayloadAction<TYPE_VOICEOVER_ENUM>
    ) {
      state.voiceover[VOICEOVER_NAME_NUM[action.payload]].uploading = false;
      state.voiceover[VOICEOVER_NAME_NUM[action.payload]].success = true;
    },
    voiceoverUploadedFail(
      state: UploaderState,
      action: PayloadAction<VOICEOVER_STEP>
    ) {
      state.voiceover[action.payload].uploading = false;
      state.voiceover[action.payload].error = true;
    },
    resetVoicever(state: UploaderState, action: PayloadAction<VOICEOVER_STEP>) {
      state.voiceover[action.payload] = initialState.voiceover[action.payload];
    },
    uploadVideo(state: UploaderState) {
      state.video.uploading = true;
    },
    videoUploaded(state: UploaderState) {
      state.video.uploading = false;
      state.video.success = true;
    },
    videoUploadedFail(state: UploaderState) {
      state.video.uploading = false;
      state.video.error = true;
    },
    resetVideo(state: UploaderState) {
      state.video = initialState.video
    },
    uploadPhotos(state: UploaderState) {
      state.photos.uploading = true;
    },
    photosUploaded(state: UploaderState) {
      state.photos.uploading = false;
      state.photos.success = true;
    },
    photosUploadedFail(state: UploaderState) {
      state.photos.uploading = false;
      state.photos.error = true;
    },
    resetPhoto(state: UploaderState) {
      state.photos = initialState.photos
    },
  }
});

export default uploaderSlice.reducer;
export const uploaderActions = uploaderSlice.actions;
