import { Box, Dialog } from '@mui/material';
import React from 'react';

type ModalImageProps = {
  mediaUrl: string;
  onClose: () => void;
};

const MetaverseMediaModal: React.FC<ModalImageProps> = ({
  mediaUrl,
  onClose
}) => {
  const isVideo = (/.(ogm|wmv|mpg|webm|ogv|mov|asx|mpeg|mp4|m4v|avi)$/).test(mediaUrl);
  return (
    <Dialog fullWidth maxWidth="md" open={!!mediaUrl} onClose={onClose}>
      <Box width="100%" sx={{ backgroundColor: '#000' }}>
        {mediaUrl &&
          (isVideo ? (
            <video
              src={mediaUrl}
              width="100%"
              controls
              style={{ height: 'calc(100vh - 200px)' }}
            />
          ) : (
            <img
              src={mediaUrl}
              alt="image"
              width="100%"
              style={{ objectFit: 'contain', height: 'calc(100vh - 200px)' }}
            />
          ))}
      </Box>
    </Dialog>
  );
};

export default MetaverseMediaModal;
