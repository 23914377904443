import { BaseSurveyApi } from './api';
import { AxiosResponse } from 'axios';

export interface ClickTrackingResult {
  snapname: string;
  device: string;
  content: number;
  datatime: string;
  clickedCount: number;
}

export interface ClickTrackingQueryResponse {
  tracks: ClickTrackingResult[];
}

class UserClickTrackingApi extends BaseSurveyApi {
  fetchUserClickTracking = async (): Promise<ClickTrackingQueryResponse> => {
    const response: AxiosResponse = await this.axios.get(
      `/user-click-tracking/query-all`
    );

    if (response.status !== 200) {
      throw new Error(response.statusText);
    }

    return response.data;
  };
}

export { UserClickTrackingApi };
