import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import mirrorApi from 'src/api_v2/mirror';
import { FetchMirrorListParams, MirrorListPageResult } from 'src/api_v2/mirror/types';
import { selectQuery } from "./selector";
import { mirrorActions } from './slice';

function* fetchMirrorList() {
  try {
    const {
      shopNameFilter,
      mirrorIdFilter,
      profileFilter,
      statusFilter,
      page,
      pageSize,
      sort
    } = yield select(selectQuery);

    const queryParams = new FetchMirrorListParams(
      page,
      pageSize,
      sort,
      mirrorIdFilter,
      profileFilter,
      statusFilter,
      shopNameFilter
    );
    const result: MirrorListPageResult = yield call(
      mirrorApi.fetchMirrors,
      queryParams
    );

    yield put(mirrorActions.mirrorListFetched(result));
  } catch (err) {
    console.log(err);
    yield put(mirrorActions.mirrorListFetchedError());
  }
}

function* fetchMirrorFilters() {
  try {
    const result = yield call(mirrorApi.fetchMirrorFilters);

    if (result) {
      yield put(mirrorActions.filtersFetched(result));
    }
  } catch (err) {
    console.log(err);
  }
}

function* deleteMirror(action: PayloadAction<string | number>) {
  try {
    const result = yield call(mirrorApi.deleteMirror, action.payload);

    if (result) {
      yield put(mirrorActions.mirrorDeleted());
      yield call(fetchMirrorList);
    } else {
      yield put(mirrorActions.mirrorDeletedError('Fail to delete profile'));
    }
  } catch (err) {
    yield put(mirrorActions.mirrorDeletedError("Fail to delete mirror"));

  }
}

function* fetchAllMirrors() {
  try {
    const {
      mirrorIdFilter,
      profileFilter,
      statusFilter,
      page,
      pageSize,
      sort
    } = yield select(selectQuery);
    const queryParams = new FetchMirrorListParams(
      1,
      Number.MAX_SAFE_INTEGER,
      sort,
      mirrorIdFilter,
      profileFilter,
      statusFilter
    );
    const result: MirrorListPageResult = yield call(
      mirrorApi.fetchMirrors,
      queryParams
    );

    yield put(mirrorActions.mirrorListFetched(result));
  } catch (err) {
    console.log(err);
    yield put(mirrorActions.mirrorListFetchedError());
  }
}


export default function* watchMirrorListSaga() {
  yield takeLatest(mirrorActions.fetchMirrorList.type, fetchMirrorList);
  yield takeLatest(
    mirrorActions.fetchMirrorFilters.type,
    fetchMirrorFilters
  );
  yield takeLatest(mirrorActions.deleteMirror.type, deleteMirror);
  yield takeLatest(mirrorActions.fetchAllMirrors.type, fetchAllMirrors);
}