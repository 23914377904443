import axios, { AxiosResponse } from 'axios';
import { number } from 'prop-types';
import appConfig from '../config';

export enum UserRoles {
  Admin,
  Mirrors_owner,
  AR_owner_namecard,
  AR_admin,
  User_admin,
  Mirror_viewer
}

export namespace UserRoles {
  export function toString(role: UserRoles): string {
    switch (role) {
      case UserRoles.Admin:
        return UserRoles[role].toLowerCase();
      case UserRoles.Mirrors_owner:
        return 'mirrors-owner';
      case UserRoles.AR_owner_namecard:
        return UserRoles[role].toLowerCase();
      case UserRoles.AR_admin:
        return UserRoles[role].toLowerCase();
      case UserRoles.User_admin:
        return 'user-admin';
      case UserRoles.Mirror_viewer:
        return 'mirror-viewer';
      default:
        return 'admin';
    }
  }

  export function getRoles(): string[] {
    return Object.keys(UserRoles).filter((item) => {
      return (
        isNaN(Number(item)) &&
        item !== 'toString' &&
        item !== 'parse' &&
        item !== 'getRoles'
      );
    });
  }

  export function parse(role: string): UserRoles {
    switch (role) {
      case 'admin':
        return UserRoles.Admin;
      case 'mirrors-owner':
        return UserRoles.Mirrors_owner;
      case 'Mirrors_owner':
        return UserRoles.Mirrors_owner;
      case 'Admin':
        return UserRoles.Admin;
      case 'AR_owner_namecard':
        return UserRoles.AR_owner_namecard;
      case 'ar_owner_namecard':
        return UserRoles.AR_owner_namecard;
      case 'AR_admin':
        return UserRoles.AR_admin;
      case 'ar_admin':
        return UserRoles.AR_admin;
      case 'mirror-viewer':
        return UserRoles.Mirror_viewer;
      default:
        return UserRoles.Admin;
    }
  }
}

export type GetUsersResult = {
  id: string;
  email: string;
  uuid: string;
  password: string;
  description: string;
  zipcode: string;
  role: UserRoles;
  is_activated: boolean;
};

export type PostUsersResult = {
  email: string;
  uuid: string;
  password: string;
  description: string;
  zipcode: string;
  role: string;
  is_activated: boolean;
};

export type CreateUsersResult = {
  zipcode: string;
  email: string;
  uuid: string;
  password: string;
  description: string;
  role: string;
  is_activated: boolean;
};

export type UserResponse = {
  id: number;
  uuid: string;
  role: string[];
};

export type AuthResponse = {
  accessToken: string;
  refreshToken: string;
  user: UserResponse;
};

export type UserLogin = {
  uuid: string;
  email: string;
  password: string;
};

export type GetMirrorsResult = {
  id: number;
  uuid: string;
  name: string;
  description: string;
  teamviewer_id: string;
  profile_id: number;
  teamviewer_lastupdated: string;
  is_modal_enabled: boolean;
  modal_image_location: string;
  photo_name: string;
  header_name: string;
  reservation_status: string;
  video_record: boolean;
  status?: string;
  last_online?: string;
};

export type PostMirrors = {
  uuid: string;
  name: string;
  description: string;
  teamviewer_id: string;
  profile_id: number;
  is_modal_enabled: boolean;
  reservation_status: string;
  video_record: boolean;
};

export type MiddleSettings = {
  id: number | null;
  profile_id: number;
  name: string;
  enabledbydefault: boolean;
};

export type GetProfilesResult = {
  id: number;
  name: string;
  description: string;
  profile_owner: number;
  version: number;
  type: number;
  input: number;
  language: string;
};

export type GetSnapsResult = {
  id: number;
  type: string;
  name: string;
};

export type PostSettingsBody = {
  uuid: string;
  profile_id: number;
  value: string;
  enable: boolean;
  snap_id: number;
};

export type CreateSnap = {
  name: string;
  type: string;
};

export type ChangePasswordType = {
  uuid: string;
  password: string;
};

export type ChangePasswordAuthType = {
  id: number;
  newPass: string;
};

export type GetMirrorSnaps = {
  id: number;
  uuid: string;
  profile_id: string;
  enable: boolean;
  snap_id: number;
  value: string;
  name: string | null;
};

export type PostMirrorSnaps = {
  uuid: string;
  profile_id: number;
  enable: boolean;
  snap_id: number;
  value: string;
};

export type PostProfileType = {
  name: string;
  description: string;
  version: number;
  profile_owner: number;
  type: number;
  input: number;
  language: string;
};

export type CreateBeforeSettings = {
  name: string;
  enable: boolean;
};

export type PostSettings = {
  id: number;
  profile_id: number;
  name: string;
  enable: boolean;
  order_number: number;
  enabledbydefault: boolean;
};

export type GetMirrorTypes = {
  id: number;
  type: string;
};

export type GetMirrorControls = {
  id: number;
  control_method: string;
};

export type CreateSettings = {
  profile_id: number;
  name: string;
  enable: boolean;
  order_number: number;
};

export type PostVideoWithTempId = {
  id: number;
  profile_id: number;
  nameid: string;
  description: string;
  video_sequence: number;
  enable: boolean;
};

export type PostVideo = {
  profile_id: number;
  nameid: string;
  description: string;
  video_sequence: number;
  enable: boolean;
  expired: string | null;
};

export type GetVideo = {
  id: number;
  profile_id: number;
  video_name: string;
  nameid: string;
  description: string;
  video_location: string;
  video_sequence: number;
  enable: boolean;
  expired: string | null;
  photo_name: string;
  photo_location: string;
  duration: number;
  size: number;
};

export type GetMirror = {
  id: number;
  uuid: string;
  name: string;
  profile_id: number;
};

export type GetProfileWithMirrors = {
  id: number;
  name: string;
  mirrors: GetMirror[];
};

export type ReservationJson = {
  daysCount: number;
  data: [];
  dates: [];
};

export type ReservationData = {
  mirror: string;
  value: DataExp;
};

export type ARCreator = {
  id: number;
  user_id: number;
  shop_name: string;
  shop_address: string;
  shop_phone: string;
  shop_url: string;
  voiceover_name: string;
  video_name: string;
  arproj_id: number;
  arproj_name: string;
  qr_link: string | null;
  video_text: string | null;
  created: number;
  group_id?: number | null;
};

export type VoiceOver = {
  id: number;
  step1: number;
  step2: number;
  mirror_id: number;
  voiceover1_path: string;
  voiceover1_name: string;
  voiceover2_path: string;
  voiceover2_name: string;
  voiceover3_path: string;
  voiceover3_name: string;
  voiceover4_path: string;
  voiceover4_name: string;
  voiceover5_path: string;
  voiceover5_name: string;
  voiceover6_path: string;
  voiceover6_name: string;
  gif1_path: string;
  gif1_name: string;
  gif2_path: string;
  gif2_name: string;
  gif3_path: string;
  gif3_name: string;
  gif4_path: string;
  gif4_name: string;
  gif5_path: string;
  gif5_name: string;
  gif6_path: string;
  gif6_name: string;
  gif_nobody_path: string;
  gif_nobody_name: string;
  voiceover_nobody_path: string;
  voiceover_nobody_name: string;
  enable_nobody: boolean;
  step_nobody: number;
  enable_voiceover: boolean;
};

export type VoiceOverPost = {
  step1: number;
  step2: number;
  mirror_id: number;
  voiceover1: any;
  voiceover2: any;
  voiceover3: any;
  voiceover4: any;
  voiceover5: any;
  voiceover6: any;
  gif1: any;
  gif2: any;
  gif3: any;
  gif4: any;
  gif5: any;
  gif6: any;
};

export type ImageReturn = {
  photo: string;
  duration: number;
  size: number;
};

export type TimeValues = {
  time: number;
  value: valueOfTime;
};

export type DataExp = {
  type: string;
  dates: DateTimeValues[];
};

export type DateTimeValues = {
  date: string;
  name: string;
  link: string;
  timeValuesV: TimeValues[];
  timeValuesN: TimeValues[];
};

export enum valueOfTime {
  '○',
  '△',
  '×'
}

export type PostAudio = {
  text: string;
  id: number;
};

export type Content = {
  id: number;
  name: string;
};

export type ArProjGet = {
  id: number;
  name: string;
  link: string;
  enable?: boolean;
};

export type ArProjPost = {
  name: string;
  link: string;
};

export type GetQA = {
  id: number;
  question: string;
  answer: string;
  options: string[];
  explanation: string;
  explanation_image: string;
  category: number;
  diff: number | null;
};

export type GetQAList = {
  id: number;
  question: string;
  answer: string;
  options: string[];
  explanation: string;
  explanation_image: string;
  c_name: string;
  d_name: string | null;
};

export type GetQAListResult = {
  qa: GetQAList[];
  pageCount: number;
};

export type PostQA = {
  question: string;
  answer: string;
  options: string[];
  explanation: string;
  explanation_image: string;
  category: number;
  diff: number | null;
};

export type Diffs = {
  id: number;
  name: string;
};

export type Categories = {
  id: string;
  name: string;
};

const UsersPath = 'users/';
const UserPath = 'user/';
const MirrorsPath = 'mirrors/';
const MirrorPath = 'mirror/';
const SnapsPath = 'snaps/';
const SnapPath = 'snap/';
const Validate = 'validate/';
const ChangePass = 'change-password/';
const ChangePassAuth = '/user/change-pass';
const ProfilesPath = 'profiles/';
const ProfilePath = 'profile/';
const MirrorSnaps = 'profile/snap/';
const MirrorArrSnaps = 'profile/snaps/';
const ProfileSettings = 'profile-settings/';
const ProfileSettingsDel = 'del-profile-settings/';
const Video = 'profile/video/';
const Videos = 'profile/videos/';
const ProfilesVideos = 'an-profiles-videos/';
const ProfileSettingsArray = 'profile-settings-array/';
const ProfileSettingsPost = 'an-profile-settings/';
const ProfilesMirrors = 'an-profiles/mirrors/';
const ProfileMirrors = 'profile/mirrors/';
const Reservation = 'reservation/';
const ReservationImage = 'reservation-image/';
const MirrorTypes = 'mirrortypes';
const MirrorControls = 'mirrorcontrols';
const VoiceOver = 'voiceover';
const ARCreators = 'arcontents';
const ARCreator = 'arcontent';
const ARCreatorAudio = 'arcontent-audio';
const ContentProfile = 'content/profiles';
const ContentUCT = 'content/user-click-tracking';
const ArProjects = 'arprojects/';
const ArProject = 'arproject/';
const QA = 'qa/';
const QAReturn = 'qa-return/';
const QACategory = 'qa-category';
const QADifficulty = 'qa-difficulty';
const Content = 'content';

const contentTypeJson = { 'Content-Type': 'application/json' };
const contentTypeFormData = { 'Content-Type': 'multipart/form-data' };

const instance = axios.create({
  withCredentials: true,
  headers: {}
});

const baseURL = appConfig.MirrorApi;
// const baseURL = "http://localhost:5000/api";
instance.defaults.baseURL = baseURL;

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response.status === 401 &&
      error.config &&
      !originalRequest._isRetry
    ) {
      originalRequest._isRetry = true;
      try {
        const refresh = await new Promise((resolve, reject) => {
          MirrorsConsoleApi.refresh(
            async (res) => {
              resolve(res);
            },
            (error) => {
              reject(error);
            }
          );
        });
        originalRequest.headers['Authorization'] = 'Bearer ' + refresh;
        return instance(originalRequest);
      } catch (e) {
        return Promise.reject(error);
      }
    }
    if (originalRequest._isRetry) {
      localStorage.removeItem('token');
      window.location.reload();
    }

    return Promise.reject(error);
  }
);

const translator = {
  en: {
    error1: 'Request failed with status code',
    error2: 'Request failed with message',
    error3: 'Request failed'
  },
  jp: {
    error1: 'リクエストがステータスコードで失敗しました',
    error2: 'リクエストはメッセージで失敗しました',
    error3: '要求が失敗しました'
  }
};

export function ErrorHandler(error, message = '', lang = 'en') {
  if (error?.response || error?.data)
    return `${translator[lang].error1} ${error.request.status} - "${
      message !== '' ? message : error.response.data.messages
    }"`;
  else if (error?.body && error?.messages)
    return `${translator[lang].error1} ${error.status} - "${
      message !== '' ? message : error.body.messages
    }"`;
  else if (error?.messages)
    return `${translator[lang].error2} - "${
      message !== '' ? message : error.messages
    }"`;
  return `${translator[lang].error3}`;
}

export const MirrorsConsoleApi = {
  login(
    user: UserLogin,
    onResult: (res: AuthResponse) => void,
    onErr: (err: any) => void
  ) {
    const baseURL = appConfig.MirrorApi;
    instance.defaults.baseURL = baseURL;
    instance
      .post('login', user)
      .then((res) => {
        let responseBody = res.data;
        localStorage.setItem('token', responseBody.accessToken);
        onResult({
          refreshToken: responseBody.refreshToken,
          accessToken: responseBody.accessToken,
          user: {
            id: responseBody.user.id,
            uuid: responseBody.user.uuid,
            role: responseBody.user.role
          }
        });
        window.location.reload();
      })
      .catch((err) => {
        onErr(err);
      });
  },

  refresh(onResult: (res: string) => void, onErr: (err: any) => void) {
    instance
      .get<AuthResponse>(`refresh`)
      .then((res) => {
        localStorage.removeItem('token');
        localStorage.setItem('token', res.data.accessToken);
        onResult(res.data.accessToken);
      })
      .catch((err) => {
        onErr(err);
      });
  },

  getMe(onResult: (res: any) => void, onErr: (err: any) => void) {
    // localStorage.removeItem('token');
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    instance
      .get<UserResponse>('me', { headers: { ...newParam, ...contentTypeJson } })
      .then((res) => {
        onResult(res.data);
      })
      .catch((err) => {
        onErr(err);
      });
  },

  logout(onResult: (res: any) => void, onErr: (err: any) => void) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    instance
      .post('logout', {}, { headers: { ...newParam, ...contentTypeJson } })
      .then((res) => {
        localStorage.removeItem('token');
        localStorage.removeItem('sketchfabData');
        onResult(true);
      })
      .catch((err) => {
        onErr(err);
      });
  },

  getUsers(
    onResult: (res: Array<GetUsersResult>) => void,
    onErr: (err: any) => void
  ) {
    const _rows = [];
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token} ` };
    instance
      .get(UsersPath, { headers: { ...newParam, ...contentTypeJson } })
      .then((res) => {
        const result = res.data;
        result.forEach((el: any) => {
          if (el.is_activated !== '0')
            _rows.push({
              id: el.id.toString(),
              email: el.email,
              uuid: el.uuid,
              password: el.password,
              description: el.description,
              zipcode: el.zipcode,
              role: UserRoles.parse(el.role),
              is_activated: el.is_activated
            });
        });
        onResult(_rows);
      })
      .catch((err) => {
        onErr(err);
      });
  },

  createUser(
    user: CreateUsersResult,
    onResult: (res: GetUsersResult) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    instance
      .post(UserPath + 'add', user, {
        headers: { ...newParam, ...contentTypeJson }
      })
      .then((res) => {
        const result = res.data;
        onResult({
          id: result.id.toString(),
          email: result?.email,
          uuid: result?.uuid,
          password: result?.password,
          zipcode: result?.zipcode,
          description: result.description,
          role: UserRoles?.parse(result?.role),
          is_activated: result?.is_activated
        });
      })
      .catch((err) => {
        onErr(err);
      });
  },

  getUserContent(
    userId: string,
    onResult: (res: GetUsersResult) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    instance
      .get(UserPath + userId, { headers: { ...newParam, ...contentTypeJson } })
      .then((res) => {
        const result = res.data;
        onResult({
          id: result.id.toString(),
          email: result?.email,
          uuid: result?.uuid,
          password: result?.password,
          zipcode: result?.zipcode,
          description: result.description,
          role: UserRoles?.parse(result?.role),
          is_activated: result?.is_activated
        });
      })
      .catch((err) => {
        onErr(err);
      });
  },

  updateUserActive(
    id: string,
    body: any,
    onResult: (res: string) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    instance
      .put(UserPath + id, body, {
        headers: { ...newParam, ...contentTypeJson }
      })
      .then((res) => {
        onResult('');
      })
      .catch((err) => {
        onErr(err);
      });
  },

  updateUserContent(
    id: string,
    state: any,
    onResult: (res: string) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    let newData = JSON.stringify(state);
    instance
      .put(UserPath + id, newData, {
        headers: { ...newParam, ...contentTypeJson }
      })
      .then((res) => {
        onResult(res.data);
      })
      .catch((err) => {
        console.log(err);
        onErr(err);
      });
  },

  removeUser(
    id: string,
    onResult: (res: any) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    instance
      .delete(UserPath + id, { headers: { ...newParam, ...contentTypeJson } })
      .then((res) => {
        onResult(true);
      })
      .catch((err) => {
        console.log(err);
        onErr(err);
      });
  },

  getMirrorsById(
    id: string,
    onResult: (res: GetProfileWithMirrors) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    const path = `${ProfileMirrors}${id}`;
    instance
      .get(path, { headers: { ...newParam, ...contentTypeJson } })
      .then((res) => {
        onResult(res.data);
      })
      .catch((err) => {
        onErr(err);
      });
  },

  getImage(
    id: string,
    onResult: (res: ImageReturn) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    const path = `/profile/video/photo/${id}`;
    instance
      .get(path, { headers: { ...newParam, ...contentTypeJson } })
      .then((res) => {
        onResult(res.data);
      })
      .catch((err) => {
        onErr(err);
      });
  },

  getProfilesWithMirrors(
    user: UserResponse,
    onResult: (res: GetProfileWithMirrors[]) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    const path = `${ProfilesMirrors}`;
    instance
      .get(path, { headers: { ...newParam, ...contentTypeJson } })
      .then((res) => {
        onResult(res.data);
      })
      .catch((err) => {
        console.log(err);
        onErr(err);
      });
  },

  getMirrors(
    user: UserResponse,
    onResult: (res: Array<GetMirrorsResult>) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    const path = `${MirrorsPath}${
      user.role.includes(UserRoles.toString(UserRoles.Mirrors_owner))
        ? user.id.toString()
        : ''
    }`;
    instance
      .get(path, { headers: { ...newParam, ...contentTypeJson } })
      .then((res) => {
        onResult(
          res.data?.sort((a, b) => {
            return a['uuid'].toLowerCase() > b['uuid'].toLowerCase()
              ? 1
              : a['uuid'].toLowerCase() < b['uuid'].toLowerCase()
              ? -1
              : 0;
          })
        );
      })
      .catch((err) => {
        onErr(err);
      });
  },

  getVoiceOver(
    id: number,
    onResult: (res: VoiceOver) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    const path = `${VoiceOver}/${id}`;
    instance
      .get(path, { headers: { ...newParam, ...contentTypeJson } })
      .then((res) => {
        onResult(res.data);
      })
      .catch((err) => {
        onErr(err);
      });
  },

  postVoiceOver(
    body: FormData,
    onResult: (res: VoiceOver) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    const path = `${VoiceOver}/add`;
    instance
      .post(path, body, { headers: { ...newParam, ...contentTypeJson } })
      .then((res) => {
        onResult(res.data);
      })
      .catch((err) => {
        onErr(err);
      });
  },

  putVoiceOver(
    id: number,
    body: FormData,
    onResult: (res: VoiceOver) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    const path = `${VoiceOver}/${id}`;
    instance
      .put(path, body, { headers: { ...newParam, ...contentTypeJson } })
      .then((res) => {
        onResult(res.data);
      })
      .catch((err) => {
        onErr(err);
      });
  },

  getMirror(
    id: number,
    onResult: (res: GetMirrorsResult) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    const path = `${MirrorPath}${id}`;
    instance
      .get(path, { headers: { ...newParam, ...contentTypeJson } })
      .then((res) => {
        onResult(res.data);
      })
      .catch((err) => {
        onErr(err);
      });
  },

  createMirror(
    mirror: FormData,
    onResult: (res: GetMirrorsResult) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    instance
      .post(MirrorPath + 'add', mirror, {
        headers: { ...newParam, ...contentTypeJson }
      })
      .then((res) => {
        onResult(res.data);
      })
      .catch((err) => {
        onErr(err);
      });
  },

  updateMirror(
    id: string,
    mirror: FormData,
    onResult: (res: GetMirrorsResult) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    instance
      .put(MirrorPath + id, mirror, {
        headers: { ...newParam, ...contentTypeJson }
      })
      .then((res) => {
        onResult(res.data);
      })
      .catch((err) => {
        onErr(err);
      });
  },

  removeMirror(
    id: string,
    onResult: (res: any) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    instance
      .delete(MirrorPath + id, { headers: { ...newParam, ...contentTypeJson } })
      .then((res) => {
        onResult(true);
      })
      .catch((err) => {
        onErr(err);
      });
  },

  getSnaps(
    onResult: (res: GetSnapsResult[]) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    const path = `${SnapsPath}`;
    instance
      .get(path, { headers: { ...newParam, ...contentTypeJson } })
      .then((res) => {
        onResult(res.data);
      })
      .catch((err) => {
        onErr(err);
      });
  },

  getSnap(
    id,
    onResult: (res: GetSnapsResult) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    const path = `${SnapPath}${id}`;
    instance
      .get(path, { headers: { ...newParam, ...contentTypeJson } })
      .then((res) => {
        onResult(res.data);
      })
      .catch((err) => {
        onErr(err);
      });
  },

  createSnap(
    snap: CreateSnap,
    onResult: (res: GetSnapsResult) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    const path = `${SnapPath}add`;
    instance
      .post(path, snap, { headers: { ...newParam, ...contentTypeJson } })
      .then((res) => {
        onResult(res.data);
      })
      .catch((err) => {
        onErr(err);
      });
  },

  validateUUid(id, onResult: (res: any) => void, onErr: (err: any) => void) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    const path = `${Validate}${id}`;
    instance
      .get(path, { headers: { ...newParam, ...contentTypeJson } })
      .then((res) => {
        onResult(res.data);
      })
      .catch((err) => {
        onErr(err);
      });
  },

  changePassword(
    newpass: ChangePasswordType,
    onResult: (res: any) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    const path = `${ChangePass}`;
    instance
      .post(path, newpass, { headers: { ...newParam, ...contentTypeJson } })
      .then((res) => {
        onResult(res.data);
      })
      .catch((err) => {
        onErr(err);
      });
  },

  changePasswordAuth(
    newpass: ChangePasswordAuthType,
    onResult: (res: any) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    const path = `${ChangePassAuth}`;
    instance
      .post(path, newpass, { headers: { ...newParam, ...contentTypeJson } })
      .then((res) => {
        onResult(res.data);
      })
      .catch((err) => {
        onErr(err);
      });
  },

  sendChangeRequest(
    email: string,
    onResult: (res: any) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    const path = `rec-pass`;
    instance
      .post(
        path,
        { email: email },
        { headers: { ...newParam, ...contentTypeJson } }
      )
      .then((res) => {
        onResult(res.data);
      })
      .catch((err) => {
        onErr(err);
      });
  },

  declineUUid(
    uuid: string,
    onResult: (res: any) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    const path = `dec-rec-pass/${uuid}`;
    instance
      .get(path, { headers: { ...newParam, ...contentTypeJson } })
      .then((res) => {
        onResult(res.data);
      })
      .catch((err) => {
        onErr(err);
      });
  },

  postSnap(
    id: string,
    snap: CreateSnap,
    onResult: (res: GetSnapsResult) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    const path = `${SnapPath}${id}`;
    instance
      .put(path, snap, { headers: { ...newParam, ...contentTypeJson } })
      .then((res) => {
        onResult(res.data);
      })
      .catch((err) => {
        console.log(err);
        onErr(err);
      });
  },

  getMirrorSnaps(
    id: string,
    onResult: (res: GetMirrorSnaps[]) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    const path = `${MirrorSnaps}${id}`;
    instance
      .get(path, { headers: { ...newParam, ...contentTypeJson } })
      .then((res) => {
        onResult(res.data);
      })
      .catch((err) => {
        console.log(err);
        onErr(err);
      });
  },

  createMirrorSnaps(
    snaps: PostMirrorSnaps[],
    onResult: (res: GetMirrorSnaps[]) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    instance
      .post(MirrorArrSnaps + 'add', snaps, {
        headers: { ...newParam, ...contentTypeJson }
      })
      .then((res) => {
        onResult(res.data);
      })
      .catch((err) => {
        console.log(err);
        onErr(err);
      });
  },

  updateMirrorSnaps(
    id: string,
    snaps: GetMirrorSnaps[],
    onResult: (res: GetMirrorSnaps[]) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    instance
      .put(MirrorArrSnaps + id, snaps, {
        headers: { ...newParam, ...contentTypeJson }
      })
      .then((res) => {
        onResult(res.data);
      })
      .catch((err) => {
        console.log(err);
        onErr(err);
      });
  },

  removeSnap(
    id: string,
    onResult: (res: any) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    instance
      .delete(SnapPath + id, { headers: { ...newParam, ...contentTypeJson } })
      .then((res) => {
        onResult(res.data);
      })
      .catch((err) => {
        console.log(err);
        onErr(err);
      });
  },

  searchMirror(
    uuid: string,
    onResult: (res: GetProfilesResult) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    const path = `${MirrorPath}search/${uuid}`;
    instance
      .get(path, { headers: { ...newParam, ...contentTypeJson } })
      .then((res) => {
        onResult(res.data);
      })
      .catch((err) => {
        console.log(err);
        onErr(err);
      });
  },

  getProfiles(
    user: UserResponse,
    onResult: (res: Array<GetProfilesResult>) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    const path = `${ProfilesPath}${
      user.role.includes(UserRoles.toString(UserRoles.Mirrors_owner)) ? user.id : ''
    }`;
    instance
      .get(path, { headers: { ...newParam, ...contentTypeJson } })
      .then((res) => {
        onResult(
          res.data?.sort((a, b) => {
            return a['name'].toLowerCase() > b['name'].toLowerCase()
              ? 1
              : a['name'].toLowerCase() < b['name'].toLowerCase()
              ? -1
              : 0;
          })
        );
      })
      .catch((err) => {
        console.log(err);
        onErr(err);
      });
  },

  getProfile(
    id: string,
    onResult: (res: GetProfilesResult) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    const path = `${ProfilePath}${id}`;
    instance
      .get(path, { headers: { ...newParam, ...contentTypeJson } })
      .then((res) => {
        onResult(res.data);
      })
      .catch((err) => {
        console.log(err);
        onErr(err);
      });
  },

  createProfile(
    profile: PostProfileType,
    onResult: (res: GetProfilesResult) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    instance
      .post(ProfilePath + 'add', profile, {
        headers: { ...newParam, ...contentTypeJson }
      })
      .then((res) => {
        onResult(res.data);
      })
      .catch((err) => {
        console.log(err);
        onErr(err);
      });
  },

  updateProfile(
    id: string,
    profile: PostProfileType,
    onResult: (res: GetProfilesResult) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    instance
      .put(ProfilePath + id, profile, {
        headers: { ...newParam, ...contentTypeJson }
      })
      .then((res) => {
        onResult(res.data);
      })
      .catch((err) => {
        console.log(err);
        onErr(err);
      });
  },

  removeProfile(
    id: string,
    onResult: (res: any) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    instance
      .delete(ProfilePath + id, {
        headers: { ...newParam, ...contentTypeJson }
      })
      .then((res) => {
        onResult(true);
      })
      .catch((err) => {
        console.log(err);
        onErr(err);
      });
  },

  getVideos(
    user: UserResponse,
    onResult: (res: GetVideo[]) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    const userIsAdmin =
      !user.role.includes(UserRoles.toString(UserRoles.Mirrors_owner));
    const path = `${userIsAdmin ? ProfilesVideos : Videos}${
      userIsAdmin ? `` : user.id
    }`;
    instance
      .get(path, { headers: { ...newParam, ...contentTypeJson } })
      .then((res) => {
        onResult(res.data);
      })
      .catch((err) => {
        console.log(err);
        onErr(err);
      });
  },

  getMirrorTypes(
    onResult: (res: Array<GetMirrorTypes>) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    instance
      .get(MirrorTypes, { headers: { ...newParam, ...contentTypeJson } })
      .then((res) => {
        onResult(res.data);
      })
      .catch((err) => {
        console.log(err);
        onErr(err);
      });
  },

  getMirrorControls(
    onResult: (res: Array<GetMirrorControls>) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    instance
      .get(MirrorControls, { headers: { ...newParam, ...contentTypeJson } })
      .then((res) => {
        onResult(res.data);
      })
      .catch((err) => {
        console.log(err);
        onErr(err);
      });
  },

  updateVideoActive(
    id: string,
    video: GetVideo,
    onResult: (res: string) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    instance
      .put(Video + id, video, { headers: { ...newParam, ...contentTypeJson } })
      .then((res) => {
        onResult('');
      })
      .catch((err) => {
        console.log(err);
        onErr(err);
      });
  },

  getVideo(
    id: string,
    onResult: (res: any) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    const path = `${Video}${id}`;
    instance
      .get(path, { headers: { ...newParam } })
      .then((res) => {
        onResult(res.data);
      })
      .catch((err) => {
        console.log(err);
        onErr(err);
      });
  },

  createVideo(
    formData: FormData,
    onResult: (res: GetVideo) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    console.log(formData);
    instance
      .post(Video + 'add', formData, {
        headers: { ...newParam, ...contentTypeFormData }
      })
      .then((res) => {
        onResult(res.data);
      })
      .catch((err) => {
        console.log(err);
        onErr(err);
      });
  },

  updateVideo(
    id: string,
    video: FormData,
    onResult: (res: GetVideo) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    instance
      .put(Video + id, video, { headers: { ...newParam, ...contentTypeJson } })
      .then((res) => {
        onResult(res.data);
      })
      .catch((err) => {
        console.log(err);
        onErr(err);
      });
  },

  deleteVideo(
    id: number,
    onResult: (res: any) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    instance
      .delete(Video + id.toString(), {
        headers: { ...newParam, ...contentTypeJson }
      })
      .then((res) => {
        onResult(res.data);
      })
      .catch((err) => {
        console.log(err);
        onErr(err);
      });
  },

  getProfileSettings(
    id: string,
    onResult: (res: PostSettings[]) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    instance
      .get(ProfileSettingsArray + id, {
        headers: { ...newParam, ...contentTypeJson }
      })
      .then((res) => {
        onResult(res.data);
      })
      .catch((err) => {
        console.log(err);
        onErr(err);
      });
  },

  createProfileSettings(
    settings: CreateSettings[],
    onResult: (res: PostSettings[]) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    instance
      .post(ProfileSettingsPost + 'add', settings, {
        headers: { ...newParam, ...contentTypeJson }
      })
      .then((res) => {
        onResult(res.data);
      })
      .catch((err) => {
        console.log(err);
        onErr(err);
      });
  },

  updateProfileSettings(
    settings: PostSettings[],
    onResult: (res: PostSettings[]) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    instance
      .put(ProfileSettingsPost, settings, {
        headers: { ...newParam, ...contentTypeJson }
      })
      .then((res) => {
        onResult(res.data);
      })
      .catch((err) => {
        console.log(err);
        onErr(err);
      });
  },

  removeProfileSettings(
    remove,
    onResult: (res: any) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    instance
      .post(ProfileSettingsDel, remove, {
        headers: { ...newParam, ...contentTypeJson }
      })
      .then((res) => {
        onResult(res.data);
      })
      .catch((err) => {
        console.log(err);
        onErr(err);
      });
  },

  reservationGet(
    mirrorId,
    onResult: (res: any) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    instance
      .get<ReservationData>(Reservation + mirrorId, {
        headers: { ...newParam, ...contentTypeJson }
      })
      .then((res) => {
        onResult(res.data);
      })
      .catch((err) => {
        onErr(err);
      });
  },

  reservationSet(
    item: ReservationData,
    onResult: (res: any) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    instance
      .post<ReservationData>(Reservation + 'add', item, {
        headers: { ...newParam, ...contentTypeJson }
      })
      .then((res) => {
        onResult(res.data);
      })
      .catch((err) => {
        onErr(err);
      });
  },

  reservationAdd(
    item: FormData,
    onResult: (res: any) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    instance
      .post(ReservationImage + 'add', item, {
        headers: { ...newParam, ...contentTypeJson }
      })
      .then((res) => {
        onResult(res.data);
      })
      .catch((err) => {
        onErr(err);
      });
  },

  getARContents(
    user: UserResponse,
    onResult: (res: Array<ARCreator>) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    const path = `${ARCreators}${
      user.role.includes(UserRoles.toString(UserRoles.AR_owner_namecard))
        ? `/${user.id.toString()}`
        : `?id=${user.id}`
    }`;
    instance
      .get(path, { headers: { ...newParam, ...contentTypeJson } })
      .then((res) => {
        onResult(
          res.data?.sort((a, b) => {
            return a['shop_name'].toLowerCase() > b['shop_name'].toLowerCase()
              ? 1
              : a['shop_name'].toLowerCase() < b['shop_name'].toLowerCase()
              ? -1
              : 0;
          })
        );
      })
      .catch((err) => {
        onErr(err);
      });
  },

  getARContent(
    id: number,
    onResult: (res: ARCreator) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    const path = `${ARCreator}/${id}`;
    instance
      .get(path, { headers: { ...newParam, ...contentTypeJson } })
      .then((res) => {
        onResult(res.data);
      })
      .catch((err) => {
        onErr(err);
      });
  },

  downloadQRCode(
    { link, name },
    onResult: (res: any) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}`};
    const path = `${ARCreators}/downloadqr?link=${link}&name=${name}`;
    instance
      .get(path, {
        headers: { ...newParam, ...contentTypeJson },
        responseType: 'blob'
      })
      .then((res) => {
        const blobUrl = URL.createObjectURL(res.data);
        const fileName = res?.headers?.['content-disposition']
          .split('filename=')[1]
          .split(';')[0];
        onResult({
          fileName,
          blobUrl
        });
      })
      .catch((err) => {
        onErr(err);
      });
  },

  addARContent(
    data: FormData,
    onResult: (res: ARCreator) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    const path = `${ARCreator}/add`;
    instance
      .post(path, data, { headers: { ...newParam, ...contentTypeJson } })
      .then((res) => {
        onResult(res.data);
      })
      .catch((err) => {
        onErr(err);
      });
  },

  updateARContent(
    id: string,
    data: FormData,
    onResult: (res: ARCreator) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    const path = `${ARCreator}/${id}`;
    instance
      .put(path, data, { headers: { ...newParam, ...contentTypeJson } })
      .then((res) => {
        onResult(res.data);
      })
      .catch((err) => {
        onErr(err);
      });
  },

  deleteARContent(
    id: string,
    onResult: (res: any) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    const path = `${ARCreator}/${id}`;
    instance
      .delete(path, { headers: { ...newParam, ...contentTypeJson } })
      .then((res) => {
        onResult(res);
      })
      .catch((err) => {
        onErr(err);
      });
  },

  postAudioText(
    data: PostAudio,
    onResult: (res: any) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    const path = `${ARCreatorAudio}`;
    instance
      .post(path, data, { headers: { ...newParam, ...contentTypeJson } })
      .then((res) => {
        onResult(res.data);
      })
      .catch((err) => {
        onErr(err);
      });
  },

  getProfileSettingsContent(
    onResult: (res: Content[]) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    const path = `${ContentProfile}`;
    instance
      .get(path, { headers: { ...newParam, ...contentTypeJson } })
      .then((res) => {
        onResult(res.data);
      })
      .catch((err) => {
        onErr(err);
      });
  },

  getUCTContent(onResult: (res: Content[]) => void, onErr: (err: any) => void) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    const path = `${ContentUCT}`;
    instance
      .get(path, { headers: { ...newParam, ...contentTypeJson } })
      .then((res) => {
        onResult(res.data);
      })
      .catch((err) => {
        onErr(err);
      });
  },

  getArProjectsList(
    onResult: (res: ArProjGet[]) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    const path = `${ArProjects}`;
    instance
      .get(path, { headers: { ...newParam, ...contentTypeJson } })
      .then((res) => {
        onResult(res.data);
      })
      .catch((err) => {
        onErr(err);
      });
  },

  getQAList(
    page: number,
    category: string,
    input: string,
    count: number,
    onResult: (res: GetQAListResult) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    const path = `${QAReturn}${page}/${category}/${
      input === '' ? 'empty' : input
    }/${count}`;
    instance
      .get(path, { headers: { ...newParam, ...contentTypeJson } })
      .then((res) => {
        onResult(res.data);
      })
      .catch((err) => {
        onErr(err);
      });
  },

  getArProjectList(
    id: number,
    onResult: (res: ArProjGet) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    const path = `${ArProject}${id}`;
    instance
      .get(path, { headers: { ...newParam, ...contentTypeJson } })
      .then((res) => {
        onResult(res.data);
      })
      .catch((err) => {
        onErr(err);
      });
  },

  getQA(id: number, onResult: (res: GetQA) => void, onErr: (err: any) => void) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    const path = `${QA}${id}`;
    instance
      .get(path, { headers: { ...newParam, ...contentTypeJson } })
      .then((res) => {
        onResult(res.data);
      })
      .catch((err) => {
        onErr(err);
      });
  },

  addArProjectsList(
    body: ArProjPost,
    onResult: (res: ArProjGet) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    const path = `${ArProject}add`;
    instance
      .post(path, body, { headers: { ...newParam, ...contentTypeJson } })
      .then((res) => {
        onResult(res.data);
      })
      .catch((err) => {
        onErr(err);
      });
  },

  addQA(
    data: PostQA,
    onResult: (res: GetQA) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    const path = `${QA}add`;
    instance
      .post(path, data, { headers: { ...newParam, ...contentTypeJson } })
      .then((res) => {
        onResult(res.data);
      })
      .catch((err) => {
        onErr(err);
      });
  },

  updateArProjectsList(
    id: number,
    body: ArProjPost,
    onResult: (res: ArProjGet) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    const path = `${ArProject}${id}`;
    instance
      .put(path, body, { headers: { ...newParam, ...contentTypeJson } })
      .then((res) => {
        onResult(res.data);
      })
      .catch((err) => {
        onErr(err);
      });
  },

  updateQA(
    id: number,
    data: PostQA,
    onResult: (res: GetQA) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    const path = `${QA}${id}`;
    instance
      .put(path, data, { headers: { ...newParam, ...contentTypeJson } })
      .then((res) => {
        onResult(res.data);
      })
      .catch((err) => {
        onErr(err);
      });
  },

  deleteArProjectsList(
    id: number,
    onResult: (res: any) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    const path = `${ArProject}${id}`;
    instance
      .delete(path, { headers: { ...newParam, ...contentTypeJson } })
      .then((res) => {
        onResult(res);
      })
      .catch((err) => {
        onErr(err);
      });
  },

  deleteQA(
    id: number,
    onResult: (res: any) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    const path = `${QA}${id}`;
    instance
      .delete(path, { headers: { ...newParam, ...contentTypeJson } })
      .then((res) => {
        onResult(res);
      })
      .catch((err) => {
        onErr(err);
      });
  },

  getDiffs(onResult: (res: Diffs[]) => void, onErr: (err: any) => void) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    const path = `${QADifficulty}`;
    instance
      .get(path, { headers: { ...newParam, ...contentTypeJson } })
      .then((res) => {
        onResult(res.data);
      })
      .catch((err) => {
        onErr(err);
      });
  },

  getCategories(
    onResult: (res: Categories[]) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const newParam = { Authorization: `Bearer ${token}` };
    const path = `${QACategory}`;
    instance
      .get(path, { headers: { ...newParam, ...contentTypeJson } })
      .then((res) => {
        onResult(res.data);
      })
      .catch((err) => {
        onErr(err);
      });
  },

  getContentProfiles(onResult: (res: any) => void, onErr: (err: any) => void) {
    const token = localStorage.getItem('token');
    const authHeader = { Authorization: `Bearer ${token}` };
    const path = `${Content}/allContent`;
    instance
      .get<any>(path, { headers: { ...authHeader, ...contentTypeJson } })
      .then((res) => {
        onResult(res.data);
      })
      .catch((err) => {
        onErr(err);
      });
  },

  getContentProfilesEnabled(
    id,
    onResult: (res: any) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const authHeader = { Authorization: `Bearer ${token}` };
    const path = `${Content}/profile/${id}`;
    instance
      .get<any>(path, { headers: { ...authHeader, ...contentTypeJson } })
      .then((res) => {
        onResult(res.data);
      })
      .catch((err) => {
        onErr(err);
      });
  },

  removeContent(id, onResult: (res: any) => void, onErr: (err: any) => void) {
    const token = localStorage.getItem('token');
    const authHeader = { Authorization: `Bearer ${token}` };
    const path = `${Content}/removeContent/${id}`;
    instance
      .delete<any>(path, { headers: { ...authHeader, ...contentTypeJson } })
      .then((res) => {
        onResult(res.data);
      })
      .catch((err) => {
        onErr(err);
      });
  },

  addContent(
    name,
    parents,
    onResult: (res: any) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const authHeader = { Authorization: `Bearer ${token}` };
    const path = `${Content}/addNewContent`;
    instance
      .post<any>(
        path,
        { parents: parents, name: name },
        { headers: { ...authHeader, ...contentTypeJson } }
      )
      .then((res) => {
        onResult(res.data);
      })
      .catch((err) => {
        onErr(err);
      });
  },

  updateContent(
    id,
    name,
    parents,
    onResult: (res: any) => void,
    onErr: (err: any) => void
  ) {
    const token = localStorage.getItem('token');
    const authHeader = { Authorization: `Bearer ${token}` };
    const path = `${Content}/changeContent/${id}`;
    instance
      .put<any>(
        path,
        { parents: parents, name: name },
        { headers: { ...authHeader, ...contentTypeJson } }
      )
      .then((res) => {
        onResult(res.data);
      })
      .catch((err) => {
        onErr(err);
      });
  }
};
