import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store/configureStore';
import { QuizListState } from './slice';

const selectDomain = (state: RootState) => {
  return state.quizlist;
};

export const selectQuizList = createSelector(
  [selectDomain],
  (quizlist: QuizListState) => quizlist.data
);

export const selectPagination = createSelector(
  [selectDomain],
  (quizlist: QuizListState) => ({
    page: quizlist.page,
    pageSize: quizlist.pageSize,
    search: quizlist.search,
    sort: quizlist.sort,
  })
);

export const selectSearch = createSelector(
  [selectDomain],
  (quizlist: QuizListState) => quizlist.search
);

export const selectSort = createSelector(
  [selectDomain],
  (quizlist: QuizListState) => quizlist.sort
);

export const selectPage = createSelector(
  [selectDomain],
  (quizlist: QuizListState) => quizlist.page
);

export const selectPageSize = createSelector(
  [selectDomain],
  (quizlist: QuizListState) => quizlist.pageSize
);

export const selectTotal = createSelector(
  [selectDomain],
  (quizlist: QuizListState) => quizlist.total
)

export const selectLoading = createSelector(
  [selectDomain],
  (quizlist: QuizListState) => quizlist.loading
)

export const selectDeleted = createSelector(
  [selectDomain],
  (quizlist: QuizListState) => quizlist.deleted
)

export const selectUploadCredit = createSelector(
  [selectDomain],
  (quizlist: QuizListState) => quizlist.uploadCredit
)

export const selectError = createSelector(
  [selectDomain],
  (quizlist: QuizListState) => quizlist.error
)

export const selectCount = createSelector(
  [selectDomain],
  (quizlist: QuizListState) => quizlist.count
);

export const selectCategory = createSelector(
  [selectDomain],
  (quizlist: QuizListState) => quizlist.category
);

export const selectQuizListCategory = createSelector(
  [selectDomain],
  (quizlist: QuizListState) => quizlist.quizListCatology
);