import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { UserAuditListPageResult } from 'src/api_v2/user-audit/types';
import IPagination from 'src/models/pagination';

export type UserAuditType = {
  id: number;
  email?: string;
  role?: string[];
  lastLoginDate?: string;
  action?: string;
  modifiedDate?: string;
  createdDate?: string;
  updatedBy?: {
    email?: string,
    id?: number
  };
};
export interface UserAuditListState extends IPagination {
  total: number;
  data: UserAuditType[];
  loading: boolean;
  error: string;
  deleted: boolean;
  uploadCredit?: number;
  exporting: boolean;
}

export const initialState: UserAuditListState = {
  data: [],
  total: 0,
  page: 1,
  pageSize: 50,
  sort: 'modifiedDate|desc',
  search: '',
  loading: false,
  error: '',
  deleted: false,
  uploadCredit : 0,
  exporting: false,
};

const UserAuditListSlice = createSlice({
  name: 'userAuditlist',
  initialState,
  reducers: {
    resetState: () => initialState,
    fetchUserAuditList(state: UserAuditListState) {
      state.loading = true;
    },
    userAuditListFetched(
      state: UserAuditListState,
      action: PayloadAction<UserAuditListPageResult>
    ) {
      state.loading = false;
      state.error = '';
      state.data = action.payload.data;
      state.total = action.payload.total;
    },
    userAuditListFetchedError(state: UserAuditListState) {
      state.loading = false;
      state.error = 'Fail to fetch userAudit list';
    },
    setCurrentPage(state: UserAuditListState, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setSearch(state: UserAuditListState, action: PayloadAction<string>) {
      state.search = action.payload;
    },
    setSort(state: UserAuditListState, action: PayloadAction<string>) {
      state.sort = action.payload;
    },
    deleteUserAudit(state: UserAuditListState, action: PayloadAction<number>) {
      state.loading = true;
      state.error = '';
      state.deleted = false;
    },
    userAuditDeleted(state: UserAuditListState) {
      state.loading = false;
      state.deleted = true;
    },
    userAuditDeletedError(state: UserAuditListState, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    resetDeleted(state: UserAuditListState) {
      state.deleted = false;
    },
    setPageSize(state: UserAuditListState, action: PayloadAction<number>) {
      state.pageSize = action.payload;
    },
    fetchTotalUserAuditList(state: UserAuditListState) {
      state.loading = true;
    },
    fetchRemainingCredit(state: UserAuditListState, action: PayloadAction<number>) {
      state.loading = true;
    },
    fetchedRemainingCredit(
      state: UserAuditListState,
      action: PayloadAction<number>
    ) {
      state.loading = false;
      state.uploadCredit = action.payload || 0;
    },
    fetchedRemainingCreditError(state: UserAuditListState) {
      state.loading = false;
    },
    resetError(state: UserAuditListState) {
      state.error = '';
    },
    exportFile(state: UserAuditListState) {
      state.exporting = true
    },
    unscreenHistoryExported(state: UserAuditListState) {
      state.exporting = false
    },
    unscreenHistoryExportedFail(state: UserAuditListState) {
      state.exporting = false
    }
  }
});

export const { actions, reducer, name: sliceKey } = UserAuditListSlice;
