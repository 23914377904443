import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type QrImageConfigurationType = {
  id?: string
  description?: string;
  thumbnail: string,
  file?: File,
  fileName?: string,
  qrImageName?: string,
  qrImageLocation?: string,
  qrDuration?: string,
  size?: string,
  expiredDate?: string,
  qrDescription?: string,
  qrOneImgFlag?: boolean,
};

export interface QrImageConfigurationState {
  loading?: boolean;
  error?: string;
  saved?: boolean;
  editingQrImage?: QrImageConfigurationType;
}

export const initialState: QrImageConfigurationState = {
  loading: false,
  error: '',
  saved: false,
  editingQrImage: undefined
};

const qrImageConfigurationSlice = createSlice({
  name: 'qrImageConfiguration',
  initialState,
  reducers: {
    reset(state: QrImageConfigurationState) {
      state.saved = false;
      state.editingQrImage = undefined;
    },
    saveQrImage(state: QrImageConfigurationState, action: PayloadAction<QrImageConfigurationType>) {
      state.loading = true;
      state.error = ''
      state.saved = false
    },
    setLoading(state: QrImageConfigurationState, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    qrImageSaved(state: QrImageConfigurationState) {
      state.loading = false;
      state.saved = true
    },
    qrImageSavedError(state: QrImageConfigurationState, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload
    },
    getQrImages(state: QrImageConfigurationState, action: PayloadAction<string>) {
      state.loading = true
    },
    qrImagesGot(state: QrImageConfigurationState, action: PayloadAction<QrImageConfigurationType>) {
      state.loading = false;
      state.editingQrImage = action.payload
    },
    qrImagesGotError(state: QrImageConfigurationState, action: PayloadAction<string>) {
      state.loading = false
      state.error = action.payload
    }
  }
});

export const { actions, reducer, name: sliceKey } = qrImageConfigurationSlice;
