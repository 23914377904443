import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store/configureStore';
import { QrImageState } from './slice';

const selectDomain = (state: RootState) => {
  return state.qrImages;
};

export const selectQrImagesList = createSelector(
  [selectDomain],
  (state: QrImageState) => state.data
);

export const selectLoading = createSelector(
  [selectDomain],
  (state: QrImageState) => state.loading
);

export const selectDeleted = createSelector(
  [selectDomain],
  (state: QrImageState) => state.deleted
);

export const selectSaved = createSelector(
  [selectDomain],
  (state: QrImageState) => state.saved
);

export const selectQuery = createSelector(
  [selectDomain],
  (state: QrImageState) => ({
    search: state.search,
    sort: state.sort,
    page: state.page,
    pageSize: state.pageSize,
    total: state.total,
    expiredDate: state.expiredDate,
    includeProfiles: state.includeProfiles,
    addedQrImageIds: state.addedQrImageIds,
  })
);

export const selectError = createSelector(
  [selectDomain],
  (state: QrImageState) => state.error
);

export const selectSearch = createSelector(
  [selectDomain],
  (state: QrImageState) => state.search
);