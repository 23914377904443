import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store/configureStore';
import { MirrorAuditListState } from './slice';

const selectDomain = (state: RootState) => {
  return state.mirrorAuditList;
};

export const selectMirrorAuditList = createSelector(
  [selectDomain],
  (mirrorAuditList: MirrorAuditListState) => mirrorAuditList.data
);

export const selectPagination = createSelector(
  [selectDomain],
  (mirrorAuditList: MirrorAuditListState) => ({
    page: mirrorAuditList.page,
    pageSize: mirrorAuditList.pageSize,
    search: mirrorAuditList.search,
    sort: mirrorAuditList.sort,
  })
);

export const selectSearch = createSelector(
  [selectDomain],
  (mirrorAuditList: MirrorAuditListState) => mirrorAuditList.search
);

export const selectSort = createSelector(
  [selectDomain],
  (mirrorAuditList: MirrorAuditListState) => mirrorAuditList.sort
);

export const selectPage = createSelector(
  [selectDomain],
  (mirrorAuditList: MirrorAuditListState) => mirrorAuditList.page
);

export const selectPageSize = createSelector(
  [selectDomain],
  (mirrorAuditList: MirrorAuditListState) => mirrorAuditList.pageSize
);

export const selectTotal = createSelector(
  [selectDomain],
  (mirrorAuditList: MirrorAuditListState) => mirrorAuditList.total
)

export const selectLoading = createSelector(
  [selectDomain],
  (mirrorAuditList: MirrorAuditListState) => mirrorAuditList.loading
)

export const selectDeleted = createSelector(
  [selectDomain],
  (mirrorAuditList: MirrorAuditListState) => mirrorAuditList.deleted
)

export const selectUploadCredit = createSelector(
  [selectDomain],
  (mirrorAuditList: MirrorAuditListState) => mirrorAuditList.uploadCredit
)

export const selectError = createSelector(
  [selectDomain],
  (mirrorAuditList: MirrorAuditListState) => mirrorAuditList.error
)

export const selectExporting = createSelector(
  [selectDomain],
  (mirrorAuditList: MirrorAuditListState) => mirrorAuditList.exporting
);