import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import { SurveyApi } from 'src/api/survey-api';

function* fetchAllSurveys() {
  try {
    const surveyApi = new SurveyApi();
    const result = yield call(surveyApi.fetchSurveys);

    yield put(actions.surveyFetched(result));
  } catch (err) {
    yield put(actions.surveyFetchedError());
  }
}

export default function* watchSurveyReport() {
  yield takeLatest(actions.fetchSurveys.type, fetchAllSurveys);
}
