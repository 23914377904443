import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { LED_COLOR } from '../../videos-list/store/slice';

export type VideosConfigurationType = {
  id?: string
  description?: string;
  name?: string;
  thumbnail: string,
  file?: File,
  fileName?: string,
  videoName?: string,
  videoLocation?: string,
  duration?: string,
  size?: string,
  expiredDate?: string
};

export interface VideosConfigurationState {
  loading?: boolean;
  error?: string;
  saved?: boolean;
  editingVideos?: VideosConfigurationType;
}

export const initialState: VideosConfigurationState = {
  loading: false,
  error: '',
  saved: false,
  editingVideos: undefined
};

const videosConfigurationlice = createSlice({
  name: 'videosConfiguration',
  initialState,
  reducers: {
    reset(state: VideosConfigurationState) {
      state.saved = false;
      state.editingVideos = undefined;
    },
    saveVideos(state: VideosConfigurationState, action: PayloadAction<VideosConfigurationType>) {
      state.loading = true;
      state.error = ''
      state.saved = false
    },
    setLoading(state: VideosConfigurationState, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    videosSaved(state: VideosConfigurationState) {
      state.loading = false;
      state.saved = true
    },
    videosSavedError(state: VideosConfigurationState, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload
    },
    getVideos(state: VideosConfigurationState, action: PayloadAction<string>) {
      state.loading = true
    },
    videosGot(state: VideosConfigurationState, action: PayloadAction<VideosConfigurationType>) {
      state.loading = false;
      state.editingVideos = action.payload
    },
    videosGotError(state: VideosConfigurationState, action: PayloadAction<string>) {
      state.loading = false
      state.error = action.payload
    }
  }
});

export const { actions, reducer, name: sliceKey } = videosConfigurationlice;
