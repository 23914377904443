import { PayloadAction, createSlice } from "@reduxjs/toolkit"

export interface MetaverseState {
  mediaUrl: string
}

export const initialState: MetaverseState = {
  mediaUrl: ''
}

const metaverseSlice = createSlice({
  name: 'mirrorlist',
  initialState,
  reducers: {
    setMediaUrl: (state: MetaverseState, action: PayloadAction<string>) => {
      state.mediaUrl = action.payload
    }
  }
})

export default metaverseSlice.reducer
export const metaverseActions = metaverseSlice.actions