import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export interface MirrorState {
  mirrorNames: string[],
  loading: boolean,
  error: boolean
}

const initialState: MirrorState = {
  mirrorNames: [],
  loading: false,
  error: false
}

const mirrorSlice = createSlice({ 
  name: 'mirror',
  initialState,
  reducers: {
    fetchAllMirrors(state: MirrorState) {
      state.mirrorNames = [];
      state.loading = true;
      state.error = false;
    },
    allMirrorsFetched(state: MirrorState, action: PayloadAction<string[]>) {
      state.mirrorNames = action.payload;
      state.loading = false;
    },
    allMirrorsFetchedError(state: MirrorState) {
      state.error = true;
      state.loading = false;
    }
  }
})

export const { actions, reducer, name: sliceKey } = mirrorSlice;
