import { createSelector } from '@reduxjs/toolkit';
import {
  ClickTrackingState
} from 'src/containers_v2/user-click-tracking/store/slice';
import { RootState } from 'src/redux/store/configureStore';
import _ from 'lodash';
import { ContentCountType } from './model';


const selectDomain = (state: RootState) => {
  return state.userClickTrackingV2;
};

export const selectClickTrackingReport = createSelector(
  [selectDomain],
  (state: ClickTrackingState) => state.data
);

export const selectSearch = createSelector(
  [selectDomain],
  (state: ClickTrackingState) => state.search
);

export const selectSort = createSelector(
  [selectDomain],
  (state: ClickTrackingState) => state.sort
);

export const selectPage = createSelector(
  [selectDomain],
  (state: ClickTrackingState) => state.page
);

export const selectPageSize = createSelector(
  [selectDomain],
  (state: ClickTrackingState) => state.pageSize
);

export const selectTotal = createSelector(
  [selectDomain],
  (state: ClickTrackingState) => state.total
);

export const selectLoading = createSelector(
  [selectDomain],
  (state: ClickTrackingState) => state.loading
);

export const selectMirrorTypeFitler = createSelector(
  [selectDomain],
  (state: ClickTrackingState) => state.mirrorTypeFilter
);

export const selectContentFitler = createSelector(
  [selectDomain],
  (state: ClickTrackingState) => state.contentFilter
);

export const selectFrom = createSelector(
  [selectDomain],
  (state: ClickTrackingState) => state.from
);

export const selectTo = createSelector(
  [selectDomain],
  (state: ClickTrackingState) => state.to
);

export const selectContents = createSelector(
  [selectDomain],
  (state: ClickTrackingState) => state.contents
);

export const selectMirrorTypes = createSelector(
  [selectDomain],
  (state: ClickTrackingState) => state.mirrorTypes
);

export const selectFilters = createSelector(
  [selectDomain],
  (state: ClickTrackingState) => ({
    contents: state.contents,
    mirrorTypes: state.mirrorTypes,
    mirrorIds: state.mirrorIds,
    gameNameEngList: state.gameNameEngList,
    gameNameJpnList: state.gameNameJpnList,
    shopNames: state.shopNames,
  })
);

export const selectQuery = createSelector(
  [selectDomain],
  (state: ClickTrackingState) => ({
    search: state.search,
    sort: state.sort,
    page: state.page,
    pageSize: state.pageSize,
    from: state.from,
    to: state.to,
    mirrorTypeFilter: state.mirrorTypeFilter,
    contentFilter: state.contentFilter,
    shopNameFilter: state.shopNameFilter,
    selectedMirrors: state.selectedMirrors
  })
);

export const countContentClickings = createSelector(
  [selectDomain],
  (state: ClickTrackingState) => {
    const dataGroupByContent = _.groupBy(state.data, x => x.content)
    const result: ContentCountType[] = []

    Object.keys(dataGroupByContent).forEach((key: string, index: number) => {
      result.push({
        name: key.replaceAll('_', " ").toLowerCase(),
        value: dataGroupByContent[key].length,
        fileLogoName: dataGroupByContent[key][0].fileLogoName,
        fileLogoLink: dataGroupByContent[key][0].fileLogoLink,
      })
    })

    const sortedData = result.sort(function (a, b) {
      return b.value - a.value;
    });

    return sortedData
  }
)

export const selectExporting = createSelector(
  [selectDomain],
  (state: ClickTrackingState) => state.exporting
);


export const selectLastUpdated = createSelector(
  [selectDomain],
  (state: ClickTrackingState) => state.data[0]?.datetime
);