import { BaseSurveyApi } from './api';
import { AxiosResponse } from 'axios';
import { SurveyReportData } from 'src/containers/survey-report/store/slice';

class SurveyApi extends BaseSurveyApi {
  fetchSurveys = async (): Promise<SurveyReportData[]> => {
    const response: AxiosResponse = await this.axios.get(`/survey`);

    if (response.status !== 200) {
      throw new Error(response.statusText);
    }

    return response.data
      .map(
        (x) =>
          ({
            appVersion: x.appVersion,
            mirrorId: x.mirrorId,
            content: x.content,
            gender: x.gender,
            age: x.age === 0 ? "unknown" : x.age,
            review: x.review,
            questionNo: x.questionNo,
            datetimeUtcMilis: Number(x.datetime)
          } as SurveyReportData)
      )
      .sort(
        (a: SurveyReportData, b: SurveyReportData) =>
          b.datetimeUtcMilis - a.datetimeUtcMilis
      );
  };
}

export { SurveyApi };
