import { AxiosResponse } from 'axios';
import agent from 'src/api_v2/base';
import { MirrorTypeConfigurationType } from 'src/containers_v2/categories/mirror-type/mirror-type-configuration/store/slice';
import { MirrorType } from 'src/containers_v2/categories/mirror-type/mirror-type-list/store/slice';
import { FetchMirrorTypeParams } from './types';

class MirrorTypeApi {
  async fetchMirrorTypes(params: FetchMirrorTypeParams): Promise<MirrorType[]> {
    try {
      const response: AxiosResponse = await agent.get(
        `/mirror-type/search?${params.toString()}`
      );

      if (response.status === 200) {
        return response.data;
      }

      return [];
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async saveMirrorType(payload: MirrorTypeConfigurationType) {
    try {
      const response: AxiosResponse = await agent.put(`/mirror-type`, payload);

      if (response.status === 200) {
        return true;
      }

      return false;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async getMirrorTypeById(
    id: string | number
  ): Promise<MirrorTypeConfigurationType> {
    try {
      const response: AxiosResponse = await agent.get(`/mirror-type/${id}`);

      if (response.status === 200) {
        return response.data as MirrorTypeConfigurationType;
      }

      return undefined;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async deleteMirrorType(id: number) {
    try {
      const response: AxiosResponse = await agent.delete(`/mirror-type/${id}`);

      if (response.status !== 200) {
        return false;
      }

      return true;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
}

const mirrorTypeApi = new MirrorTypeApi();
export default mirrorTypeApi;
