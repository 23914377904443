import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
export interface LoginParams {
  email: string;
  password: string
}

export interface AuthUser {
  id: number
  email: string;
  username: string;
  role: string[];
  groupId: number;
  mirrorIds?: number[]
  isArProjectCreationExpired?: boolean
}

export interface AuthState {
  loading: boolean;
  error: boolean;
  isAuthenticated: boolean;
  authUser: AuthUser
}

export const initialState: AuthState = {
  loading: false,
  error: false,
  authUser: {
    id: 0,
    email: '',
    username: '',
    role: [''],
    groupId: 0,
    mirrorIds: [],
    isArProjectCreationExpired: false
  },
  isAuthenticated: false
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetState: () => initialState,
    login(state: AuthState, action: PayloadAction<LoginParams>) {
      state.loading = true
      state.error = false
    },
    loginSuccess(state: AuthState, action: PayloadAction<AuthUser>) {
      state.loading = false
      state.authUser = action.payload
      state.isAuthenticated = true
    },
    loginError(state: AuthState) {
      state.loading = false
      state.error = true
    },
    checkUserArProjectCreationExpired(state: AuthState) {
      
    },
    userArProjectCreationExpiredChecked(state: AuthState, action: PayloadAction<boolean>) {
      state.authUser.isArProjectCreationExpired = action.payload
    }
  }
})

export const authActions = authSlice.actions
export default authSlice.reducer