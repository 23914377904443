import { ARProjectType } from 'src/containers_v2/ar_creator/ARProject/list/store/slice';
import IPagination from 'src/models/pagination';

export class FetchARPamphletParams implements IPagination {
  page: number;
  pageSize: number;
  search: string;
  sort: string;
  template_id: string;
  user_id: string;
  type: number;

  constructor(
    page: number = 1,
    pageSize: number = 10,
    search: string = '',
    sort: string = 'asc',
    template_id: string = '',
    user_id: string = '',
    type: number = 0
  ) {
    this.page = page;
    this.pageSize = pageSize;
    this.search = search;
    this.sort = sort;
    this.template_id = template_id;
    this.user_id = user_id;
    this.type = type;
  }

  toString() {
    return `page=${this.page}&size=${this.pageSize}&search=${this.search}&sort=${this.sort}&template_id=${this.template_id}&user_id=${this.user_id}&type=${this.type}`;
  }
}

export type ARPamphletListPageResult = {
  data: ARProjectType[];
  totalItems: number;
};
