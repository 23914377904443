import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import quizApi from 'src/api_v2/quiz/quiz-list-api';
import { actions, QuizConfigurationType } from './slice';

function* saveQuiz(action: PayloadAction<QuizConfigurationType>) {
  try {
    const result = yield call(
      action.payload.id ? quizApi.updateQuiz : quizApi.addQuiz,
      action.payload
    );

    if (result.valid) {
      yield put(actions.quizSaved());
    } else {
      yield put(actions.quizSavedError(result?.data?.response?.data?.message?.[0] ? result.data.response.data.message?.[0] : 'Fail to save quiz.'));
    }
  } catch (err) {
    if (err.data.response) {
      const message = err.data.response.data?.message as string[];
      if (message?.includes('Email is duplicated')) {
        yield put(actions.quizSavedError('Email is duplicated'));
      } else {
        yield put(actions.quizSavedError('Fail to save quiz.'));
      }
    }
  }
}

function* getQuiz(action: PayloadAction<string>) {
  try {
    const result = yield call(quizApi.getQuiz, action.payload);

    if (result) {
      yield put(
        actions.quizGot({
          ...result,
        })
      );
    } else {
    }
  } catch (err) {
    yield put(actions.quizGotError('Fail to get quiz.'));
  }
}

function* getQuizDifficulty() {
  try {
    const result = yield call(quizApi.getQuizDifficulty);

    if (result) {
      yield put(actions.quizDifficultySaved(result));
    } else {
      yield put(actions.quizDifficultyError('Fail to get quiz difficulty.'));
    }
  } catch (err) {
    yield put(actions.quizDifficultyError('Fail to get quiz difficulty.'));
  }
}

export default function* watchQuizConfigurationSaga() {
  yield takeLatest(actions.saveQuiz.type, saveQuiz);
  yield takeLatest(actions.getQuiz.type, getQuiz);
  yield takeLatest(actions.getQuizDifficulty.type, getQuizDifficulty);
}
