import { ProfileType } from 'src/containers_v2/mirror-configuration/profile/profile-list/store/slice';
import IPagination from 'src/models/pagination';

export type ProfileVoiceoverResponseType = {
  profileId: number;
  id?: number;
  step1?: number;
  step2?: number;
  step_nobody?: number;
  step_fixed_duration?: number;
  enableNobody?: boolean;
  enableVoiceover?: boolean;
  enableFixedDuration?: boolean;
  voiceover1Name?: string;
  voiceover1Path?: string;
  voiceover2Name?: string;
  voiceover2Path?: string;
  voiceover3Name?: string;
  voiceover3Path?: string;
  voiceover4Name?: string;
  voiceover4Path?: string;
  voiceover5Name?: string;
  voiceover5Path?: string;
  voiceover6Name?: string;
  voiceover6Path?: string;
  gif1Name?: string;
  gif1Path?: string;
  gif2Name?: string;
  gif2Path?: string;
  gif3Name?: string;
  gif3Path?: string;
  gif4Name?: string;
  gif4Path?: string;
  gif5Name?: string;
  gif5Path?: string;
  gif6Name?: string;
  gif6Path?: string;
  voiceoverNobodyName?: string;
  voiceoverNobodyPath?: string;
  gifNobodyName?: string;
  gifNobodyPath?: string;
  voiceoverFixedDurationName?: string;
  voiceoverFixedDurationPath?: string;
  gifFixedDurationName?: string;
  gifFixedDurationPath?: string;
  isOverride?: boolean;
};

export type ProfileListPageResult = {
  data: ProfileType[];
  totalCount: number;
};
export class FetchProfileListParams implements IPagination {
  page: number;
  pageSize: number;
  search: string;
  sort: string;
  mirrorType: string;
  inputType: string;
  owner: string;

  constructor(
    page: number = 1,
    pageSize: number = 10,
    search: string = '',
    sort: string = 'name|asc',
    mirrorType: string,
    inputType: string,
    owner: string
  ) {
    this.page = page;
    this.pageSize = pageSize;
    this.search = search;
    this.sort = sort;
    this.mirrorType = mirrorType;
    this.inputType = inputType;
    this.owner = owner;
  }

  toString() {
    return `page=${this.page}&size=${this.pageSize}&name=${this.search}&sort=${this.sort}&mirrorType=${this.mirrorType}&inputType=${this.inputType}&owner=${this.owner}`;
  }
}
