import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store/configureStore';
import { UserAuditListState } from './slice';

const selectDomain = (state: RootState) => {
  return state.userAuditList;
};

export const selectUserAuditList = createSelector(
  [selectDomain],
  (userAuditList: UserAuditListState) => userAuditList.data
);

export const selectPagination = createSelector(
  [selectDomain],
  (userAuditList: UserAuditListState) => ({
    page: userAuditList.page,
    pageSize: userAuditList.pageSize,
    search: userAuditList.search,
    sort: userAuditList.sort,
  })
);

export const selectSearch = createSelector(
  [selectDomain],
  (userAuditList: UserAuditListState) => userAuditList.search
);

export const selectSort = createSelector(
  [selectDomain],
  (userAuditList: UserAuditListState) => userAuditList.sort
);

export const selectPage = createSelector(
  [selectDomain],
  (userAuditList: UserAuditListState) => userAuditList.page
);

export const selectPageSize = createSelector(
  [selectDomain],
  (userAuditList: UserAuditListState) => userAuditList.pageSize
);

export const selectTotal = createSelector(
  [selectDomain],
  (userAuditList: UserAuditListState) => userAuditList.total
)

export const selectLoading = createSelector(
  [selectDomain],
  (userAuditList: UserAuditListState) => userAuditList.loading
)

export const selectDeleted = createSelector(
  [selectDomain],
  (userAuditList: UserAuditListState) => userAuditList.deleted
)

export const selectUploadCredit = createSelector(
  [selectDomain],
  (userAuditList: UserAuditListState) => userAuditList.uploadCredit
)

export const selectError = createSelector(
  [selectDomain],
  (userAuditList: UserAuditListState) => userAuditList.error
)

export const selectExporting = createSelector(
  [selectDomain],
  (userAuditList: UserAuditListState) => userAuditList.exporting
);