import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { QuizListPageResult } from 'src/api_v2/quiz/types';
import IPagination from 'src/models/pagination';

export type QuizType = {
  id?: number;
  question?: string;
  answer?: string;
  options?: string[];
  explanation?: string;
  category?: number | string;
  diff?: number;
  explanation_image?: null;
  explanation_image_encode?: null;
  c_name?: string;
  d_name?: string;
};

export type QuizCatologyType = {
  id?: number;
  name?: string
};

export interface QuizListState extends IPagination {
  total: number;
  data: QuizType[];
  loading: boolean;
  error: string;
  deleted: boolean;
  uploadCredit?: number;
  count?: number;
  category?: number | string;
  quizListCatology?: QuizCatologyType[]
}

export const initialState: QuizListState = {
  data: [],
  total: 0,
  page: 1,
  count: 50,
  pageSize: 10,
  sort: '',
  search: '',
  loading: false,
  error: '',
  deleted: false,
  uploadCredit: 0,
  category: 'All',
  quizListCatology: []
};

const QuizListSlice = createSlice({
  name: 'quizlist',
  initialState,
  reducers: {
    resetState: () => initialState,
    fetchQuizList(state: QuizListState) {
      state.loading = true;
    },
    quizListFetched(
      state: QuizListState,
      action: PayloadAction<QuizListPageResult>
    ) {
      state.loading = false;
      state.error = '';
      state.data = action.payload.data;
      state.total = action.payload.totalCount;
    },
    quizListFetchedError(state: QuizListState) {
      state.loading = false;
      state.error = 'Fail to fetch quiz list';
    },
    fetchQuizCategory(state: QuizListState) {
      state.loading = true;
    },
    fetchQuizCategoryFetched(
      state: QuizListState,
      action: PayloadAction<QuizCatologyType[]>
    ) {
      state.loading = false;
      state.error = '';
      state.quizListCatology = action.payload;
    },
    fetchQuizCategoryError(state: QuizListState) {
      state.loading = false;
    },
    setCurrentPage(state: QuizListState, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setSearch(state: QuizListState, action: PayloadAction<string>) {
      state.search = action.payload;
    },
    setSort(state: QuizListState, action: PayloadAction<string>) {
      state.sort = action.payload;
    },
    deleteQuiz(state: QuizListState, action: PayloadAction<number>) {
      state.loading = true;
      state.error = '';
      state.deleted = false;
    },
    quizDeleted(state: QuizListState) {
      state.loading = false;
      state.deleted = true;
    },
    quizDeletedError(state: QuizListState, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    resetDeleted(state: QuizListState) {
      state.deleted = false;
    },
    setCount(state: QuizListState, action: PayloadAction<number>) {
      state.count = action.payload;
    },
    fetchTotalQuizList(state: QuizListState) {
      state.loading = true;
    },
    fetchRemainingCredit(state: QuizListState, action: PayloadAction<number>) {
      state.loading = true;
    },
    fetchedRemainingCredit(
      state: QuizListState,
      action: PayloadAction<number>
    ) {
      state.loading = false;
      state.uploadCredit = action.payload || 0;
    },
    fetchedRemainingCreditError(state: QuizListState) {
      state.loading = false;
    },
    setCatelogy(state: QuizListState, action: PayloadAction<string | number>) {
      state.category = action.payload;
    },
    resetError(state: QuizListState) {
      state.error = '';
    }
  }
});

export const { actions, reducer, name: sliceKey } = QuizListSlice;
