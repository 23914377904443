import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export type QuizConfigurationType = {
  id?: string
  description?: string;
  question?: string;
  explanation?: string;
  answer?: number,
  options?: string[] | [],
  category?: string,
  diff?: string,
  explanation_image?: string,
  explanation_image_name?: string
  // uploadCredit?: number
};

export interface QuizConfigurationState {
  loading?: boolean;
  error?: string;
  saved?: boolean;
  editingQuiz?: QuizConfigurationType;
  listQuizDifficulty?: []
}

export const initialState: QuizConfigurationState = {
  loading: false,
  error: '',
  saved: false,
  editingQuiz: undefined
};

const quizConfigurationlice = createSlice({
  name: 'quizConfiguration',
  initialState,
  reducers: {
    reset(state: QuizConfigurationState) {
      state.saved = false;
      state.editingQuiz = undefined;
    },
    saveQuiz(state: QuizConfigurationState, action: PayloadAction<QuizConfigurationType>) {
      state.loading = true;
      state.error = ''
      state.saved = false
    },
    quizSaved(state: QuizConfigurationState) {
      state.loading = false;
      state.saved = true
    },
    quizSavedError(state: QuizConfigurationState, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload
    },
    getQuiz(state: QuizConfigurationState, action: PayloadAction<string>) {
      state.loading = true
    },
    quizGot(state: QuizConfigurationState, action: PayloadAction<QuizConfigurationType>) {
      state.loading = false;
      state.editingQuiz = action.payload
    },
    quizGotError(state: QuizConfigurationState, action: PayloadAction<string>) {
      state.loading = false
      state.error = action.payload
    },
    getQuizDifficulty(state: QuizConfigurationState, action: PayloadAction<string>) {
      state.loading = true
    },
    quizDifficultySaved(state: QuizConfigurationState, action: PayloadAction<[]>) {
      state.loading = false;
      state.listQuizDifficulty = action.payload
    },
    quizDifficultyError(state: QuizConfigurationState, action: PayloadAction<string>) {
      state.loading = false
    },
    setError(state: QuizConfigurationState, action: PayloadAction<string>) {
      state.error = action.payload
    },
    resetError(state: QuizConfigurationState, action: PayloadAction<string>) {
      state.error = ''
    }
  }
});

export const { actions, reducer, name: sliceKey } = quizConfigurationlice;
