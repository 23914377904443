import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { ClickTrackingQueryResponse } from 'src/api/user-click-tracking-api';

export interface ClickTrackingReportState {
  data: ClickTrackingQueryResponse;
  loading: boolean;
  error: boolean;
}

export const initialState: any = {
  data: { tracks: [] },
  loading: false,
  error: false
};

const userClickTrackingReportSlice = createSlice({
  name: 'userClickTrackingReport',
  initialState,
  reducers: {
    fetchUserClickTracking(state: ClickTrackingReportState) {
      state.loading = true;
    },
    userClickTrackingFetched(
      state: ClickTrackingReportState,
      action: PayloadAction<ClickTrackingQueryResponse>
    ) {
      state.loading = false;
      state.error = false;
      state.data = action.payload;
    },
    userClickTrackingFetchedError(state: ClickTrackingReportState) {
      state.loading = false;
      state.error = false;
    }
  }
});

export const {
  actions,
  reducer,
  name: sliceKey
} = userClickTrackingReportSlice;