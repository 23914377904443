import { AxiosResponse } from 'axios';
import agent from 'src/api_v2/base';
import { FetchMirrorAuditListParams, MirrorAuditListPageResult } from './types';
import moment from 'moment';
class MirrorAuditApi {
  async fetchMirrorAuditList(params: FetchMirrorAuditListParams) {
    try {
      const response: AxiosResponse = await agent.get(
        `/mirror-audit?${params.toString()}`
      );

      if (response.status === 200) {
        return response.data as MirrorAuditListPageResult;
      }

      return []
    } catch (err) {
      throw new Error(err);
    }
  }

  async exportFile(params: FetchMirrorAuditListParams): Promise<any> {
    try {
      const response: AxiosResponse = await agent.post(
        `/mirror-audit/export/csv?${params.toString()}`,
        {
          responseType: 'blob'
        }
      );

      if (response.status === 201) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.setAttribute(
          'download',
          `mirror-${moment().format('YYYY-MM-DD, HH-mm')}.csv`
        );
        link.href = url;
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);

        return true;
      }
      return false;
    } catch (err) {
      throw err;
    }
  }

}

const mirrorAuditListApi = new MirrorAuditApi();
export default mirrorAuditListApi;
