import { AxiosResponse } from 'axios';
import agent from 'src/api_v2/base';

class VersionApi {
  async fetchVersion() {
    try {
      const response: AxiosResponse = await agent.get(
        `/versioning`
      );

      if (response?.status === 200) {
        return response?.data;
      }

      return []
    } catch (err) {
    }
  }
}

const versioningApi = new VersionApi();
export default versioningApi;
