import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  FetchQuizListParams,
  QuizListPageResult
} from 'src/api_v2/quiz/types';
import quizApi from 'src/api_v2/quiz/quiz-list-api';
import {
  selectCategory,
  selectCount,
  selectPage,
  selectPageSize,
  selectSearch,
  selectSort
} from './selector';
import { actions, QuizCatologyType } from './slice';

function* fetchQuizList() {
  try {
    const page = yield select(selectPage);
    const count = yield select(selectCount);
    const search = yield select(selectSearch);
    const searchCustom = search === '' ? 'empty' : search
    const category = yield select(selectCategory);
    
    const queryParams = new FetchQuizListParams(page, category, searchCustom, count);

    const result: QuizListPageResult = yield call(
      quizApi.fetchQuizList,
      queryParams
    );

    yield put(actions.quizListFetched(result));
  } catch (err) {
    console.log(err);
    yield put(actions.quizListFetchedError());
  }
}

function* fetchQuizCategory() {
  try {
    const result: QuizCatologyType[] = yield call(
      quizApi.fetchQuizCategory
    );

    yield put(actions.fetchQuizCategoryFetched(result));
  } catch (err) {
    console.log(err);
    yield put(actions.fetchQuizCategoryError());
  }
}

function* deleteQuiz(action: PayloadAction<number>) {
  try {
    const userId = action.payload;
    if (userId) {
      yield call(quizApi.deleteQuiz, userId);

      yield put(actions.quizDeleted());
      yield call(fetchQuizList);
    }
  } catch (err) {
    if (err.response) {
      const message = err.response.data?.message as string[];
      console.log(message);

      if (message[0]) {
        yield put(actions.quizDeletedError(message[0]));
      }
    } else {
      yield put(actions.quizDeletedError(''));
    }
  }
}


export default function* watchQuizListSaga() {
  yield takeLatest(actions.fetchQuizList.type, fetchQuizList);
  yield takeLatest(actions.fetchQuizCategory.type, fetchQuizCategory);
  yield takeLatest(actions.deleteQuiz.type, deleteQuiz);
}
