import {
  configureStore,
  getDefaultMiddleware,
  EnhancedStore,
} from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";

import createReducer from "./reducers";

function configureAppStore() {
  const reduxSagaMonitorOptions = {};
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);
  // Create the store with saga middleware
  const store = configureStore({
    reducer: createReducer(),
    middleware: [...getDefaultMiddleware(), sagaMiddleware],
    devTools:
      /* istanbul ignore next line */
      process.env.NODE_ENV !== "production",
  });

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  // if (process.env.NODE_ENV !== "production" && module.hot) {
  //   module.hot.accept("./reducers", () => {
  //     store.replaceReducer(createReducer(store));
  //   });
  // }

  return { store, sagaMiddleware };
}

export const { store, sagaMiddleware } = configureAppStore();
export type RootState = ReturnType<typeof store.getState>
