import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store/configureStore';
import { ProfileConfigurationState } from './slice';

const selectDomain = (state: RootState) => {
  return state.profileConfig;
};

export const selectFilters = createSelector(
  [selectDomain],
  (state: ProfileConfigurationState) => state.filters
)

export const selectMirrorTabData = createSelector(
  [selectDomain],
  (state: ProfileConfigurationState) => state.mirrorTab
);

export const selectSnapTabData = createSelector(
  [selectDomain],
  (state: ProfileConfigurationState) => state.snapTab
);

export const selectContentTabData = createSelector(
  [selectDomain],
  (state: ProfileConfigurationState) => state.contentTab
);

export const selectGeneralProfile = createSelector(
  [selectDomain],
  (state: ProfileConfigurationState) => state.profile
)

export const selectLoading = createSelector(
  [selectDomain],
  (state: ProfileConfigurationState) => state.loading
)

export const selectVideoTabData = createSelector(
  [selectDomain],
  (state: ProfileConfigurationState) => state.videoTab
);

export const selectVoiceoverTabData = createSelector(
  [selectDomain],
  (state: ProfileConfigurationState) => state.voiceoverTab
);

export const selectPhotoTabData = createSelector(
  [selectDomain],
  (state: ProfileConfigurationState) => state.photoTab
);

export const selectError = createSelector(
  [selectDomain],
  (state: ProfileConfigurationState) => state.error
);

export const selectSaved = createSelector(
  [selectDomain],
  (state: ProfileConfigurationState) => state.saved
);

export const selectQrImageTabData = createSelector(
  [selectDomain],
  (state: ProfileConfigurationState) => state.qrImageTab
);