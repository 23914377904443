import { AxiosResponse } from 'axios';
import agent from 'src/api_v2/base';
import {
  FetchClickTrackingReportParams,
  ClickTrackingReportPageResult,
  FiltersResult
} from './types';
import moment from 'moment';
import { helpers } from 'src/utils/helpers';
class ClickTrackingReportApi {
  async fetchClickTrackingReport(params: FetchClickTrackingReportParams) {
    try {
      const response: AxiosResponse = await agent.get(
        `/user-click-tracking?${params.toString()}`
      );

      if (response.status === 200) {
        return response.data as ClickTrackingReportPageResult;
      }

      return [];
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async fetchFilters() {
    try {
      const response: AxiosResponse = await agent.get(
        `/user-click-tracking/filters`
      );

      if (response.status === 200) {
        return response.data as FiltersResult;
      }

      return undefined;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async exportCSV(params: FetchClickTrackingReportParams) {
    try {
      const response = await agent.get(
        `/user-click-tracking/export-csv?${params.toString()}`,
        {
          responseType: 'blob'
        }
      );

      if (response.status === 200) {
        helpers.exportCSV(
          response.data,
          `user-click-tracking-${moment().format('YYYY-MM-DD, HH-mm')}.csv`
        );

        return true;
      }
      return false;
    } catch (err) {
      throw err;
    }
  }
}

const clickTrackingApi = new ClickTrackingReportApi();
export default clickTrackingApi;
