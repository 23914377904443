import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export type ARTemplateConfigurationType = {
  id?: string
  description?: string;
  name?: string;
  link?: string;
  preview_link?: string;
  enable?: boolean;
  type?: number;
};

export interface ARTemplateConfigurationState {
  loading?: boolean;
  error?: string;
  saved?: boolean;
  editingARTemplate?: ARTemplateConfigurationType;
}

export const initialState: ARTemplateConfigurationState = {
  loading: false,
  error: '',
  saved: false,
  editingARTemplate: undefined,
};

const arTemplateConfigurationlice = createSlice({
  name: 'arTemplateConfiguration',
  initialState,
  reducers: {
    reset(state: ARTemplateConfigurationState) {
      state.saved = false;
      state.editingARTemplate = undefined;
    },
    saveARTemplate(state: ARTemplateConfigurationState, action: PayloadAction<ARTemplateConfigurationType>) {
      state.loading = true;
      state.error = ''
      state.saved = false
    },
    arTemplateSaved(state: ARTemplateConfigurationState) {
      state.loading = false;
      state.saved = true
    },
    arTemplateSavedError(state: ARTemplateConfigurationState, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload
    },
    getARTemplate(state: ARTemplateConfigurationState, action: PayloadAction<string>) {
      state.loading = true
    },
    arTemplateGot(state: ARTemplateConfigurationState, action: PayloadAction<ARTemplateConfigurationType>) {
      state.loading = false;
      state.editingARTemplate = action.payload
    },
    arTemplateGotError(state: ARTemplateConfigurationState, action: PayloadAction<string>) {
      state.loading = false
      state.error = action.payload
    }
  }
});

export const { actions, reducer, name: sliceKey } = arTemplateConfigurationlice;
