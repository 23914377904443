import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { UserType } from 'src/containers_v2/user-management/user-list/store/slice';

export type DataTrackingItem = {
  count: number;
  name: string;
};

export type ARProjectTrackingType = {
  os?: DataTrackingItem[];
  country?: DataTrackingItem[];
  city?: DataTrackingItem[];
  date?: DataTrackingItem[];
};
export type ConfigurationType = {
  id?: string;
  description?: string;
  name?: string;
  shopName?: string;
  shopAddress: string;
  shopPhone: string;
  shopUrl: string;
  voiceover: any;
  video: any;
  tts: string;
  user_id: number | null;
  arproj_id: number | null;
  bgRemove: number | null;
  videoName?: string | null;
  voiceoverName?: string | null;
  status?: string;
  videoLink?: string;
  expiredDate?: string | null;
  user?: UserType
};

export interface ConfigurationState {
  loading?: boolean;
  error?: string;
  saved?: boolean;
  editing?: ConfigurationType;
  dataTracking?: ARProjectTrackingType;
  from?: number;
  to?: number;
}

export const initialState: ConfigurationState = {
  loading: false,
  error: '',
  saved: false,
  editing: undefined,
  dataTracking: { country: [], city: [], os: [], date: [] },
  from: moment().subtract(2, 'weeks').startOf('day').valueOf(),
  to: moment().endOf('day').valueOf()
};

const handleConfigurationSlice = createSlice({
  name: 'arProjectConfiguration',
  initialState,
  reducers: {
    reset(state: ConfigurationState) {
      state.saved = false;
      state.editing = undefined;
      state.dataTracking = { country: [], city: [], os: [], date: [] };
      state.from = moment().subtract(2, 'weeks').startOf('day').valueOf();
      state.to = moment().endOf('day').valueOf();
    },
    save(state: ConfigurationState, action: PayloadAction<FormData>) {
      state.loading = true;
      state.error = '';
      state.saved = false;
    },
    saved(state: ConfigurationState) {
      state.loading = false;
      state.saved = true;
    },
    savedError(state: ConfigurationState, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    get(state: ConfigurationState, action: PayloadAction<string>) {
      state.loading = true;
    },
    got(state: ConfigurationState, action: PayloadAction<ConfigurationType>) {
      state.loading = false;
      state.editing = action.payload;
    },
    gotError(state: ConfigurationState, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    deleteItem(
      state: ConfigurationState,
      action: PayloadAction<number | string>
    ) {},
    getARProjectTracking(
      state: ConfigurationState,
      action: PayloadAction<string>
    ) {
      state.loading = true;
    },
    gotARProjectTracking(
      state: ConfigurationState,
      action: PayloadAction<ARProjectTrackingType>
    ) {
      state.loading = false;
      state.dataTracking = action.payload;
    },
    setFrom(state: ConfigurationState, action: PayloadAction<number>) {
      state.from = action.payload;
      if (state.to < action.payload) {
        state.to = moment().endOf('day').valueOf();
      }
    },
    setTo(state: ConfigurationState, action: PayloadAction<number>) {
      state.to = action.payload;
    }
  }
});

export const { actions, reducer, name: sliceKey } = handleConfigurationSlice;
