import { Route, Routes } from 'react-router-dom';
import { isArray } from 'util';

const configureChild = (childRote, role: string[] = []) => {
  const tempChildRouute = childRote;

  if (tempChildRouute?.children) {
    tempChildRouute.children = tempChildRouute.children
      .map((f) => {
        configureChild(f, role);
        if (f?.roles?.length > 0) {
          if (f?.roles?.some(item => role.some(item1 => f?.roles?.includes(item1)))) return f;
          else return null;
        } else return f;
      })
      .filter((x) => x); // filter undefined / null items
  }
};

const configure = (route, role: string[]) => {
  route.forEach((r) => {
    configureChild(r, role);
  });
};

interface RouteCux {
  path: string;
  element: any;
  children?: any;
}

const configureChildRoute = (route: RouteCux) => {
  if (Array.isArray(route)) {
    return route.map(({ children, element, path }) => {
      return (
        <Route key={path} element={element} path={path}>
          {children && configureChildRoute(children)}
        </Route>
      );
    });
  } else
    return (
      <Route key={route.path} element={route.element} path={route.path}>
        {route?.children && configureChildRoute(route.children)}
      </Route>
    );
};

const configureRoute = (routes) => {
  return <Routes>{routes.map((r) => configureChildRoute(r))}</Routes>;
};

export default function useRouteRoles(routes, currentRole: string[]) {
  let newRoutes = routes;
  
  if (currentRole?.length > 0) configure(newRoutes, currentRole);

  const configuredRoutes = configureRoute(newRoutes);
  return configuredRoutes;
}
