import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import arProjectApi from 'src/api_v2/ar-project/ar-project-list-api';
import { actions } from './slice';
import { selectQuery } from './selector';
import moment from 'moment';

function* save(action: PayloadAction<FormData>) {
  try {
    const result = yield call(arProjectApi.updateAndInsert, action.payload);

    if (result) {
      yield put(actions.saved());
    } else {
      yield put(actions.savedError('Fail to save ar pamphlet.'));
    }
  } catch (err) {
    if (err.response) {
      const message = err.response.data?.message as string[];
      if (message?.includes('Email is duplicated')) {
        yield put(actions.savedError('Email is duplicated'));
      } else {
        yield put(actions.savedError('Fail to save ar pamphlet.'));
      }
    }
  }
}

function* get(action: PayloadAction<string>) {
  try {
    const result = yield call(arProjectApi.get, action.payload);

    if (result) {
      yield put(
        actions.got({
          ...result,
          confirmPassword: result.password
        })
      );
    } else {
    }
  } catch (err) {
    yield put(actions.gotError('Fail to get ar pamphlet.'));
  }
}

function* getARProjectTracking(action: PayloadAction<string>) {
  const { from, to } = yield select(selectQuery);
  try {
    const result = yield call(
      arProjectApi.getARProjectTracking,
      action.payload,
      from,
      to
    );

    if (result) {
      yield put(
        actions.gotARProjectTracking({
          ...result,
          date: result.date.map((item) => {
            return {
              ...item,
              name: moment(item.name).format('YYYY-MM-DD'),
              count: Number(item.count)
            };
          })
        })
      );
    } else {
    }
  } catch (err) {
    yield put(actions.gotError('Fail to get ar project.'));
  }
}

function* deleteItem(action: PayloadAction<number|string>) {
  try {
    const userId = action.payload;
    if (userId) {
      yield call(arProjectApi.deleteItem, Number(userId));
    }
  } catch (err) {
    console.log(err);
  }
}

export default function* watchArPamphletConfigurationSaga() {
  yield takeLatest(actions.save.type, save);
  yield takeLatest(actions.get.type, get);
  yield takeLatest(actions.getARProjectTracking.type, getARProjectTracking);
  yield takeLatest(actions.deleteItem.type, deleteItem);
}
