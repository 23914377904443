import React from 'react';
import LanguageTwoToneIcon from '@mui/icons-material/LanguageTwoTone';
import { styled } from '@mui/material/styles';
import { Box, InputBase, MenuItem, Select } from '@mui/material';
import i18n from 'i18next';
import { useDispatch } from 'react-redux';
import { setLanguage } from 'src/redux/store/language/slice';
import { useTranslation } from 'react-i18next';
import { messages } from 'src/locales/messages';

const Wrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '5px',
  borderRadius: '5px',
  transition: '.2s',
  color: '#7F63F4',
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: '#f2f5f9',
  },
  '&.active': {
    backgroundColor: '#f2f5f9',
    cursor: 'pointer'
  }
}));

const CustomInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    padding: 5,
    color: '#6e759f'
  }
}));

const LanguageSelector = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const handleChange = (event) => {
    i18n.changeLanguage(event.target.value);
    localStorage.setItem('language', event.target.value);
    dispatch(setLanguage(event.target.value === 'en' ? 'en' : 'jp'));
  };

  return (
    <Wrapper>
      <LanguageTwoToneIcon sx={{ fontSize: 26, marginRight: '5px' }} />
      <Select
        labelId="demo-customized-select-label"
        id="demo-customized-select"
        value={i18n.language}
        onChange={handleChange}
        style={{ fontSize: '16px' }}
        input={<CustomInput />}
      >
        <MenuItem value={'en'}>ENG</MenuItem>
        <MenuItem value={'ja'}>{t(messages.main.jpn())}</MenuItem>
      </Select>
    </Wrapper>
  );
};

export default LanguageSelector;
