import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import contentApi from 'src/api_v2/content';
import { FetchContentsParams } from 'src/api_v2/content/types';
import { selectQuery } from './selector';
import { contentActions } from './slice';

function* fetchContents() {
  try {
    const { search, page, pageSize, sort, type } = yield select(selectQuery);

    const queryParams = new FetchContentsParams(page, pageSize, search, sort, type);
    const result = yield call(contentApi.fetchContents, queryParams);

    if (result && result.data) {
      yield put(contentActions.contentFetched(result));
    } else {
      yield put(contentActions.contentFetchedError());
    }
  } catch (err) {
    yield put(contentActions.contentFetchedError());
  }
}

function* fetchAllContents() {
  try {
    const queryParams = new FetchContentsParams(
      1,
      Number.MAX_SAFE_INTEGER,
      '',
      'name|asc'
    );
    const result = yield call(contentApi.fetchContents, queryParams);

    if (result && result.data) {
      yield put(contentActions.contentFetched(result));
    } else {
      yield put(contentActions.contentFetchedError());
    }
  } catch (err) {
    yield put(contentActions.contentFetchedError());
  }
}

function* deleteContent(action: PayloadAction<number>) {
  try {
    const result = yield call(contentApi.deleteContent, action.payload);

    if (result) {
      yield put(contentActions.contentDeleted());
      yield call(fetchContents);
    } else {
      yield put(contentActions.contentDeletedError());
    }
  } catch (err) {
    yield put(contentActions.contentDeletedError());
  }
}

function* saveContent(action: PayloadAction<string>) {
  try {
    const result = yield call(contentApi.saveContent, {
      name: action.payload,
      id: 0
    });

    if (result) {
      yield put(contentActions.contentSaved());
      yield call(fetchContents);
    } else {
      yield put(contentActions.contentSavedError(''));
    }
  } catch (err) {
    if (err.response) {
      const message = err.response.data?.message as string[];
      console.log(message);

      yield put(contentActions.contentSavedError(message[0]));
    } else {
      yield put(contentActions.contentSavedError(''));
    }
  }
}

function* fetchContentFilters() {
  try {
    const result = yield call(contentApi.fetchContentFilters);

    if (result) {
      yield put(contentActions.filtersFetched(result));
    }
  } catch (err) {
    console.log(err);
  }
}

export default function* watchContentListSaga() {
  yield takeLatest(contentActions.fetchContents.type, fetchContents);
  yield takeLatest(contentActions.fetchAllContents.type, fetchAllContents);
  yield takeLatest(contentActions.deleteContent.type, deleteContent);
  yield takeLatest(contentActions.saveContent.type, saveContent);
  yield takeLatest(contentActions.fetchContentFilters.type, fetchContentFilters);
}
