import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import qrImagesApi from 'src/api_v2/qr-image';
import { FetchQrImagesParams } from 'src/api_v2/qr-image/types';
import { selectQuery } from './selector';
import { qrImageActions } from './slice';
import { log } from 'console';

function* fetchQrImages() {
  try {
    const { search, page, pageSize, sort, expiredDate, includeProfiles, addedVideoIds } = yield select(selectQuery);

    console.log("search:", search)
    const queryParams = new FetchQrImagesParams(page, pageSize, search, sort, expiredDate, includeProfiles, addedVideoIds);
    const result = yield call(qrImagesApi.fetchQrImages, queryParams);

    if (result && result.data) {
      yield put(qrImageActions.qrImagesFetched(result));
    } else {
      yield put(qrImageActions.qrImagesFetchedError());
    }
  } catch (err) {
    yield put(qrImageActions.qrImagesFetchedError());
  }
}

function* fetchAllQrImages() {
  try {
    const queryParams = new FetchQrImagesParams(
      1,
      Number.MAX_SAFE_INTEGER,
      '',
      'qrImageName|asc'
    );
    const result = yield call(qrImagesApi.fetchQrImages, queryParams);

    if (result && result.data) {
      yield put(qrImageActions.qrImagesFetched(result));
    } else {
      yield put(qrImageActions.qrImagesFetchedError());
    }
  } catch (err) {
    yield put(qrImageActions.qrImagesFetchedError());
  }
}

function* deleteQrImages(action: PayloadAction<number>) {
  try {
    const result = yield call(qrImagesApi.deleteQrImage, action.payload);

    if (result) {
      yield put(qrImageActions.qrImagesDeleted());
      yield call(fetchQrImages);
    } else {
      yield put(qrImageActions.qrImagesDeletedError());
    }
  } catch (err) {
    yield put(qrImageActions.qrImagesDeletedError());
  }
}

function* saveQrImages(action: PayloadAction<string>) {
  try {
    const result = yield call(qrImagesApi.saveQrImage, {
      name: action.payload,
      id: 0
    });

    if (result) {
      yield put(qrImageActions.qrImagesSaved());
      yield call(fetchQrImages);
    } else {
      yield put(qrImageActions.qrImagesSavedError(''));
    }
  } catch (err) {
    if (err.response) {
      const message = err.response.data?.message as string[];

      yield put(qrImageActions.qrImagesSavedError(message[0]));
    } else {
      yield put(qrImageActions.qrImagesSavedError(''));
    }
  }
}

export default function* watchQrImagesListSaga() {
  yield takeLatest(qrImageActions.fetchQrImages.type, fetchQrImages);
  yield takeLatest(qrImageActions.fetchAllQrImages.type, fetchAllQrImages);
  yield takeLatest(qrImageActions.deleteQrImages.type, deleteQrImages);
  yield takeLatest(qrImageActions.saveQrImages.type, saveQrImages);
}
