import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { MirrorTypePageResult } from 'src/api_v2/mirror-type/types';
import { ProfileType } from 'src/containers_v2/mirror-configuration/profile/profile-list/store/slice';
import IPagination from 'src/models/pagination';

export type MirrorType = {
  id: number;
  type: string;
  profiles: ProfileType[],
  contentsCount?: number
};

export interface MirrorTypeListState extends IPagination{
  data: MirrorType[];
  loading: boolean;
  error: boolean;
  deleted: boolean;
  total: number;
}

export const initialState: MirrorTypeListState = {
  data: [],
  loading: false,
  error: false,
  deleted: false,
  total: 0,
  page: 1,
  pageSize: 50,
  sort: 'type|asc',
  search: ''
};

const mirrorTypeListSlice = createSlice({
  name: 'mirrorTypeList',
  initialState,
  reducers: {
    resetState: () => initialState,
    fetchMirrorTypes(state: MirrorTypeListState) {
      state.loading = true;
      state.error = false;
    },
    mirrorTypesFetched(
      state: MirrorTypeListState,
      action: PayloadAction<MirrorTypePageResult>
    ) {
      state.data = action.payload.data;
      state.total = action.payload.totalItems;
      state.loading = false;
    },
    mirrorTypesFetchedError(state: MirrorTypeListState) {
      state.loading = false;
      state.error = true;
    },
    deleteMirrorType(
      state: MirrorTypeListState,
      action: PayloadAction<number>
    ) {
      state.loading = true;
      state.error = false;
      state.deleted = false;
    },
    mirrorTypeDeleted(state: MirrorTypeListState) {
      state.deleted = true;
    },
    mirrorTypeDeletedError(state: MirrorTypeListState) {
      state.loading = false;
      state.error = true;
    },
    setSearch(state: MirrorTypeListState, action: PayloadAction<string>) {
      state.search = action.payload;
    },
    setPageSize(state: MirrorTypeListState, action: PayloadAction<number>) {
      state.pageSize = action.payload;
    },
    setCurrentPage(state: MirrorTypeListState, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setSort(state: MirrorTypeListState, action: PayloadAction<string>) {
      state.sort = action.payload;
    }
  }
});

export default mirrorTypeListSlice.reducer;
export const mirrorTypeActions = mirrorTypeListSlice.actions;
