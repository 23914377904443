import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store/configureStore';
import { SnapState } from './slice';

const selectDomain = (state: RootState) => {
  return state.snap;
};

export const selectSnapList = createSelector(
  [selectDomain],
  (state: SnapState) => state.data
);

export const selectLoading = createSelector(
  [selectDomain],
  (state: SnapState) => state.loading
);

export const selectDeleted = createSelector(
  [selectDomain],
  (state: SnapState) => state.deleted
);

export const selectSaved = createSelector(
  [selectDomain],
  (state: SnapState) => state.saved
);

export const selectQuery = createSelector(
  [selectDomain],
  (state: SnapState) => ({
    search: state.search,
    sort: state.sort,
    page: state.page,
    pageSize: state.pageSize,
    total: state.total
  })
);

export const selectError = createSelector(
  [selectDomain],
  (state: SnapState) => state.error
);

export const selectSearch = createSelector(
  [selectDomain],
  (state: SnapState) => state.search
);