import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  FetchARProjectAuditListParams,
  ARProjectAuditListPageResult
} from 'src/api_v2/ar-project-audit/types';
import arProjectAuditApi from 'src/api_v2/ar-project-audit/ar-project-audit-list-api';
import {
  selectPage,
  selectPageSize,
  selectSearch,
  selectSort
} from './selector';
import { actions } from './slice';

function* fetchARProjectAuditList() {
  try {
    const page = yield select(selectPage);
    const pageSize = yield select(selectPageSize);
    const search = yield select(selectSearch);
    const sort = yield select(selectSort);
    const queryParams = new FetchARProjectAuditListParams(page, pageSize, search, sort);
    const result: ARProjectAuditListPageResult = yield call(
      arProjectAuditApi.fetchARProjectAuditList,
      queryParams
    );

    yield put(actions.arProjectAuditListFetched(result));
  } catch (err) {
    console.log(err);
    yield put(actions.arProjectAuditListFetchedError());
  }
}

function* fetchTotalARProjectAuditList() {
  try {
    const search = yield select(selectSearch);
    const sort = yield select(selectSort);

    const queryParams = new FetchARProjectAuditListParams(
      1,
      Number.MAX_SAFE_INTEGER,
      search,
      sort
    );
    const result: ARProjectAuditListPageResult = yield call(
      arProjectAuditApi.fetchARProjectAuditList,
      queryParams
    );

    yield put(actions.arProjectAuditListFetched(result));
  } catch (err) {
    console.log(err);
    yield put(actions.arProjectAuditListFetchedError());
  }
}

function* exportFile() {
  const page = yield select(selectPage);
  const pageSize = yield select(selectPageSize);
  const search = yield select(selectSearch);
  const sort = yield select(selectSort);
  const queryParams = new FetchARProjectAuditListParams(page, pageSize, search, sort);

  try {
    yield call(arProjectAuditApi.exportFile,queryParams)

    yield put(actions.unscreenHistoryExported())
  } catch (err) {
    console.log(err);
    yield put(actions.unscreenHistoryExportedFail());
  }
}

export default function* watchARProjectAuditListSaga() {
  yield takeLatest(actions.fetchARProjectAuditList.type, fetchARProjectAuditList);
  yield takeEvery(actions.fetchTotalARProjectAuditList.type, fetchTotalARProjectAuditList);
  yield takeEvery(actions.exportFile.type, exportFile);
}
