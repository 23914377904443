import React from 'react';
import {
  AlertColor,
  CircularProgress,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectPhotoUploader,
  selectVideoUploader,
  selectVoiceoverUploader
} from './store/seletor';
import Toast from 'src/components/Toast';
import { uploaderActions } from './store/slice';
import { VOICEOVER_STEP } from 'src/redux/store/models';
import { useTranslation } from 'react-i18next';
import { messages } from 'src/locales/messages';

type Props = {
  text: string;
  severity?: AlertColor;
  anchor?: {
    vertical: 'bottom' | 'top';
    horizontal: 'right' | 'center' | 'left';
  };
};

const Uploader = () => {
  const [openUploading, setOpenUploading] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation()
  const voiceover = useSelector(selectVoiceoverUploader);
  const video = useSelector(selectVideoUploader);
  const photo = useSelector(selectPhotoUploader);
  const { step1, step2, step_nobody, step_fixed_duration } = voiceover;

  useEffect(() => {
    setOpenUploading(
      step1?.uploading ||
        step2?.uploading ||
        step_nobody?.uploading ||
        step_fixed_duration?.uploading ||
        video?.uploading ||
        photo?.uploading
    );
  }, [
    step1?.uploading,
    step2?.uploading,
    step_nobody?.uploading,
    video?.uploading,
    photo?.uploading,
    step_fixed_duration?.uploading
  ]);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') return;
    setOpenUploading(false);
  };

  return (
    <>
      {openUploading && (
        <CircularProgress
          size={100}
          style={{
            left: '50%',
            position: 'absolute',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 20
          }}
        />
      )}
      <Toast
        isOpen={step1.success}
        text={t(messages.main.uploadVoiceoverSuccessfully())}
        onClose={() =>
          dispatch(uploaderActions.resetVoicever(VOICEOVER_STEP.STEP1))
        }
      />
      <Toast
        isOpen={step2.success}
        text={t(messages.main.uploadVoiceoverSuccessfully())}
        onClose={() =>
          dispatch(uploaderActions.resetVoicever(VOICEOVER_STEP.STEP2))
        }
      />
      <Toast
        isOpen={step_nobody.success}
        text={t(messages.main.uploadVoiceoverSuccessfully())}
        onClose={() =>
          dispatch(uploaderActions.resetVoicever(VOICEOVER_STEP.NOBODY))
        }
      />
      <Toast
        isOpen={step_fixed_duration.success}
        text={t(messages.main.uploadVoiceoverSuccessfully())}
        onClose={() =>
          dispatch(uploaderActions.resetVoicever(VOICEOVER_STEP.FIXED_DURATION))
        }
      />
      <Toast
        isOpen={video.success}
        text="Upload video successfully!"
        onClose={() => dispatch(uploaderActions.resetVideo())}
      />
      <Toast
        isOpen={photo.success}
        text="Upload photos successfully!"
        onClose={() => dispatch(uploaderActions.resetPhoto())}
      />
    </>
  );
};

export default Uploader;
