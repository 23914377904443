import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export interface PeopleCounterRawData {
  mirror_id: string;
  entry_x1: number;
  entry_y1: number;
  entry_x2: number;
  entry_y2: number;
  exit_x1: number;
  exit_y1: number;
  exit_x2: number;
  exit_y2: number;
  entry_time: number;
  exit_time: number;
  age: number;
  gender: string;
  emotion: string;
}

export interface PeopleNeutralVideoTrackingRawData {
  mirror_id: string;
  datetime_utc_milis: number;
  person_id: number;
  person_in_roi_duration: number;
  video_name: string
}
export interface PeopleCounterData {
  name: string,
  persons_count: number,
  date: string,
  ageList: any,
  emotionList: any,
  genderList: any
}

export enum DATE_RANGE {
  TODAY,
  SEVEN_DAYS_AGO,
  THIRDTY_DAYS_AGO,
  SEVEN_DAYS_AGO_TIME,
  THIRDTY_DAYS_AGO_TIME,
  CUSTOM
}

export enum COUNTER_TYPE {
  PEOPLE,
  VOICEOVER
}

export interface PeopleCounterReportState {
  counterRawData: PeopleCounterRawData[]
  videoTrackingRawData: PeopleNeutralVideoTrackingRawData[]
  mirrorId: string,
  dateRange: DATE_RANGE,
  customDate: string,
  loading: boolean;
  error: boolean;
  counterType: COUNTER_TYPE
}

export const initialState: any = {
  counterRawData: [],
  videoTrackingRawData: [],
  mirrorId: '',
  dateRange: DATE_RANGE.TODAY,
  loading: false,
  error: false,
  customDate: null,
  counterType: COUNTER_TYPE.PEOPLE,
}

const peopleCounterReportSlice = createSlice({
  name: "peopleCounterReport",
  initialState,
  reducers: {
    setDateRange(state: PeopleCounterReportState, action: PayloadAction<DATE_RANGE>) {
      state.dateRange = action.payload
    },
    setCustomDate(state: PeopleCounterReportState, action: PayloadAction<string>) {
      state.dateRange = DATE_RANGE.CUSTOM;
      state.customDate = action.payload
    },
    setMirrorId(state: PeopleCounterReportState, action: PayloadAction<string>) {
      state.mirrorId = action.payload
    },
    fetchPeopleCounter(state: PeopleCounterReportState) {
      state.error = false;
      state.loading = true;
      state.counterRawData = [];
    },
    peopleCounterRawDataFetched(state: PeopleCounterReportState, action: PayloadAction<PeopleCounterRawData[]>) {
      state.loading = false;
      state.counterRawData = action.payload;
    },
    peopleCounterFetchedError(state: PeopleCounterReportState) {
      state.loading = false;
      state.error = true;
    },
    setCounterType(state: PeopleCounterReportState, action: PayloadAction<COUNTER_TYPE>) {
      state.counterType = action.payload
    },
    fetchPeopleNeutralVideoTracking(state: PeopleCounterReportState) {
      state.videoTrackingRawData = [];
      state.loading = true;
    },
    peopleNeutralVideoFetched(state: PeopleCounterReportState, action: PayloadAction<PeopleNeutralVideoTrackingRawData[]>) {
      state.videoTrackingRawData = action.payload;
      state.loading = false
    },
    peopleNeutralVideoFetchedError(state: PeopleCounterReportState) {
      state.error = true;
      state.loading = false
    }
  }
})

export const { actions, reducer, name: sliceKey } = peopleCounterReportSlice;