import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store/configureStore';
import { UploaderState } from './slice';

const selectDomain = (state: RootState) => {
  return state.uploader;
};

export const selectVideoUploader = createSelector(
  [selectDomain],
  (state: UploaderState) => state.video
);

export const selectVoiceoverUploader = createSelector(
  [selectDomain],
  (state: UploaderState) => state.voiceover
);

export const selectPhotoUploader = createSelector(
  [selectDomain],
  (state: UploaderState) => state.photos
);