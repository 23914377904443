import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { VideosPageResult } from 'src/api_v2/videos/types';
import { ProfileType } from 'src/containers_v2/mirror-configuration/profile/profile-list/store/slice';
import IPagination from 'src/models/pagination';

export type LED_COLOR = 'green' | 'yellow' | 'red' | ""

export type VideosType = {
  id: number;
  name?: string;
  videoName?: string;
  fileLocation?: string;
  duration?: string;
  size?: string;
  expiredDate?: string;
  profiles?: ProfileType[];
  createdAt?: string
};

export interface VideosState extends IPagination {
  total: number;
  data: VideosType[];
  error: string;
  loading: boolean;
  deleted: boolean;
  saved: boolean;
  expiredDate?: string;
  includeProfiles?: boolean,
  addedVideoIds?: string
}

export const initialState: VideosState = {
  data: [],
  error: '',
  loading: false,
  deleted: false,
  saved: false,
  total: 0,
  page: 1,
  pageSize: 50,
  sort: 'videoName|asc',
  search: '',
  expiredDate: '',
  includeProfiles: false,
  addedVideoIds: ''
};

const videosSlice = createSlice({
  name: 'videos',
  initialState,
  reducers: {
    resetState: () => initialState,
    fetchVideos(state: VideosState) {
      state.loading = true;
      state.error = '';
    },
    fetchAllVideos(state: VideosState) {
      state.loading = true;
      state.error = '';
    },
    videosFetched(
      state: VideosState,
      action: PayloadAction<VideosPageResult>
    ) {
      state.data = action.payload.data;
      state.total = action.payload.totalCount;
      state.loading = false;
    },
    videosFetchedError(state: VideosState) {
      state.loading = false;
      state.error = 'Fail to fetch videos!';
    },
    deleteVideos(state: VideosState, action: PayloadAction<number>) {
      state.loading = true;
      state.deleted = false;
      state.error = '';
    },
    videosDeleted(state: VideosState) {
      state.loading = false;
      state.deleted = true;
    },
    videosDeletedError(state: VideosState) {
      state.loading = false;
      state.error = 'Fail to delete videos!';
    },
    saveVideos(state: VideosState, action: PayloadAction<string>) {
      state.loading = true;
      state.error = '';
    },
    videosSaved(state: VideosState) {
      state.loading = false;
      state.saved = true;
    },
    resetSaved(state: VideosState) {
      state.saved = false
    },
    videosSavedError(state: VideosState, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    setCurrentPage(state: VideosState, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setSearch(state: VideosState, action: PayloadAction<string>) {
      state.search = action.payload;
    },
    setSort(state: VideosState, action: PayloadAction<string>) {
      state.sort = action.payload;
    },
    setPageSize(state: VideosState, action: PayloadAction<number>) {
      state.pageSize = action.payload;
    },
    setExpiredDate(state: VideosState, action: PayloadAction<string>) {
      state.expiredDate = action.payload;
    },
    setIncludeProfiles(state: VideosState, action: PayloadAction<boolean>) {
      state.includeProfiles = action.payload;
    },
    setAddedVideoIds(state: VideosState, action: PayloadAction<string>) {
      state.addedVideoIds = action.payload;
    },
  }
});

export default videosSlice.reducer;
export const videosActions = videosSlice.actions;
