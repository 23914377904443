import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { GroupListPageResult } from 'src/api_v2/group/types';
import IPagination from 'src/models/pagination';

export enum GROUP_ROLE {
  ADMIN = 'admin',
  MIRROR_OWNER = 'mirrors-owner',
  AR_OWNER = 'ar_owner',
  AR_ADMIN = 'ar_admin'
}
export type GroupType = {
  id: number;
  description?: string;
  name?: string;
  uploadCredit?: number;
  numberOfUser?: number;
};
export interface GroupListState extends IPagination {
  total: number;
  data: GroupType[];
  loading: boolean;
  error: string;
  deleted: boolean;
  uploadCredit?: number;
}

export const initialState: GroupListState = {
  data: [],
  total: 0,
  page: 1,
  pageSize: 50,
  sort: 'asc',
  search: '',
  loading: false,
  error: '',
  deleted: false,
  uploadCredit: 0
};

const GroupListSlice = createSlice({
  name: 'grouplist',
  initialState,
  reducers: {
    resetState: () => initialState,
    fetchGroupList(state: GroupListState) {
      state.loading = true;
    },
    groupListFetched(
      state: GroupListState,
      action: PayloadAction<GroupListPageResult>
    ) {
      state.loading = false;
      state.error = '';
      state.data = action.payload.data
      state.total = action.payload.totalCount;
    },
    groupListFetchedError(state: GroupListState) {
      state.loading = false;
      state.error = 'Fail to fetch group list';
    },
    setCurrentPage(state: GroupListState, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setSearch(state: GroupListState, action: PayloadAction<string>) {
      state.search = action.payload;
    },
    setSort(state: GroupListState, action: PayloadAction<string>) {
      state.sort = action.payload;
    },
    deleteGroup(state: GroupListState, action: PayloadAction<number>) {
      state.loading = true;
      state.error = '';
      state.deleted = false;
    },
    groupDeleted(state: GroupListState) {
      state.loading = false;
      state.deleted = true;
    },
    groupDeletedError(state: GroupListState, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    resetDeleted(state: GroupListState) {
      state.deleted = false;
    },
    setPageSize(state: GroupListState, action: PayloadAction<number>) {
      state.pageSize = action.payload;
    },
    fetchTotalGroupList(state: GroupListState) {
      state.loading = true;
    },
    fetchRemainingCredit(state: GroupListState, action: PayloadAction<number>) {
      state.loading = true;
    },
    fetchedRemainingCredit(
      state: GroupListState,
      action: PayloadAction<number>
    ) {
      state.loading = false;
      state.uploadCredit = action.payload || 0;
    },
    fetchedRemainingCreditError(state: GroupListState) {
      state.loading = false;
    },
    resetError(state: GroupListState) {
      state.error = '';
    }
  }
});

export const { actions, reducer, name: sliceKey } = GroupListSlice;
