import { AxiosResponse } from 'axios';
import agent from 'src/api_v2/base';
import { SnapConfigurationType } from 'src/containers_v2/categories/snap/snap-configuration/store/slice';
import { SnapType } from 'src/containers_v2/categories/snap/snap-list/store/slice';
import { FetchSnapsParams } from './types';

class SnapApi {
  async fetchSnaps(
    params: FetchSnapsParams
  ): Promise<{ data: SnapType[]; totalItems: number }> {
    try {
      const response: AxiosResponse = await agent.get(
        `/snaps?${params.toString()}`
      );

      if (response.status === 200) {
        return response.data;
      }

      return undefined;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async deleteSnap(id: number) {
    try {
      const response: AxiosResponse = await agent.delete(`/snaps/delete/${id}`);

      if (response.status !== 200) {
        return false;
      }

      return true;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async saveSnap(payload: SnapType) {
    try {
      const response: AxiosResponse = await agent.post(
        `/snaps/${payload.id > 0 ? 'edit' : 'create'}`,
        payload
      );

      if (response.status !== 200 && response.status !== 201) {
        return false;
      }

      return true;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

   async addSnap(snap: SnapConfigurationType) {
    try {
      const response: AxiosResponse = await agent.post(
        `/snaps/create`,
        snap
      );

      if(response.status !== 200 && response.status !== 201) {
        return {
          valid : false,
          data: response
        }
      }

      return {
        valid : true,
        data: response
      }
    } catch (err) {
      return {
        valid : false,
        data: err
      };
    }
  }

  async updateSnap(snap: SnapConfigurationType) {
    try {
      const response: AxiosResponse = await agent.put(
        `/snaps/edit`,
        snap
      );

      if(response.status !== 200 && response.status !== 204) {
        return {
          valid : false,
          data: response
        }
      }

      return {
        valid : true,
        data: response
      }
    } catch (err) {
      return {
        valid : false,
        data: err
      };
    }
  }

  async getSnap(id: string): Promise<any> {
    try {
      const response: AxiosResponse = await agent.get(
        `/snaps/${id}`
      );

      if (response.status === 200) {
        return response.data;
      }

      return undefined
    } catch (err) {
      throw err;
    }
  }
}

const snapApi = new SnapApi();
export default snapApi;
