import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { MirrorListPageResult } from 'src/api_v2/mirror/types';
import IPagination from 'src/models/pagination';
import { MirrorFiltersType } from 'src/redux/store/models';

export type MirrorType = {
  id: number;
  mirrorId: number;
  name: string;
  teamviewerId: string;
  profile: string;
  description: string;
  profileId: number;
  isVideoRecord: boolean;
  isAdOverlay: boolean;
  status: string;
  lastonlineTime: string;
  isOverridePhoto: boolean;
  isOverrideVoiceOver: boolean;
  shopName?: string;
};

export interface MirrorListState extends IPagination {
  total: number;
  data: MirrorType[];
  loading: boolean;
  error: string;
  deleted: boolean;
  mirrorIdFilter: string,
  shopNameFilter: string,
  profileFilter: string,
  statusFilter: string;
  filters: MirrorFiltersType;
  mirrorNames: string[]
}

export const initialState: MirrorListState = {
  data: [],
  total: 0,
  page: 1,
  pageSize: 50,
  sort: 'mirrorId|asc',
  search: '',
  loading: false,
  error: '',
  deleted: false,
  mirrorIdFilter: '',
  shopNameFilter: '',
  profileFilter: '',
  statusFilter: 'online',
  filters: {
    profiles: [],
    mirrorIds: [],
    shopNames: [],
  },
  mirrorNames: []
};

const mirrorListSlice = createSlice({
  name: 'metaverse',
  initialState,
  reducers: {
    resetAllState: () => initialState,
    resetState: (state: MirrorListState, action: PayloadAction<{[key: string]: boolean}>) => {
      const keys = Object.keys(action.payload)
      const currentState = {}
      keys.forEach((x: string) => currentState[x] = state[x])
      return {
        ...initialState,
        ...currentState
      }
    },

    setCurrentPage(state: MirrorListState, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setSearch(state: MirrorListState, action: PayloadAction<string>) {
      state.search = action.payload
    },
    setSort(state: MirrorListState, action: PayloadAction<string>) {
      state.sort = action.payload
    },
    setPageSize(state: MirrorListState, action: PayloadAction<number>) {
      state.pageSize = action.payload
    },
    setMirrorIdFilter(state: MirrorListState, action: PayloadAction<string>) {
      state.mirrorIdFilter = action.payload
    },
    setShopNameFilter(state: MirrorListState, action: PayloadAction<string>) {
      state.shopNameFilter = action.payload
    },
    setProfileFilter(state: MirrorListState, action: PayloadAction<string>) {
      state.profileFilter = action.payload
    },
    setStatusFilter(state: MirrorListState, action: PayloadAction<string>) {
      console.log("setStatusFilter")
      state.statusFilter = action.payload
    },
    fetchMirrorList(state: MirrorListState) {
      state.loading = true;
    },
    mirrorListFetched(state: MirrorListState, action: PayloadAction<MirrorListPageResult>) {
      state.loading = false;
      state.error = '';
      state.data = action.payload.data;
      state.mirrorNames = action.payload.data.map((x) => x.name);;
      state.total = action.payload.totalCount;
    },
    mirrorListFetchedError(state: MirrorListState) {
      state.loading = false;
      state.error = 'Fail to fetch profile list';
    },
    setMirrorListData(state: MirrorListState, action: PayloadAction<MirrorType[]>) {
      state.data = action.payload;
    },
    fetchMirrorFilters(state: MirrorListState) {
      state.loading = true;
    },
    filtersFetched(state: MirrorListState, action: PayloadAction<MirrorFiltersType>) {
      state.filters = action.payload
      state.loading = false;
    },
    deleteMirror(state: MirrorListState, action: PayloadAction<string| number>) {
      state.loading = true
    },
    mirrorDeleted(state: MirrorListState) {
      state.deleted = true
      state.loading = false
    },
    mirrorDeletedError(state: MirrorListState, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload
    },
    setDeleted(state: MirrorListState, action: PayloadAction<boolean>) {
      state.deleted = action.payload
    },
    resetError(state: MirrorListState) {
      state.error = ''
    },
    fetchAllMirrors(state: MirrorListState) {
      state.loading = true;
    },
  }
});

export default mirrorListSlice.reducer
export const mirrorActions = mirrorListSlice.actions
