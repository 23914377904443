import { TYPE_VOICEOVER_ENUM } from "src/containers_v2/mirror-configuration/mirror/mirror-configuration/store/slice";
import { messages } from "src/locales/messages";

export type ProfileOwnerType = {
  id: number;
  name: string;
};

export type ProfileInputType = {
  id: number;
  input: string;
};

export type ProfileMirrorType = {
  id: number;
  type: string;
};

export type ProfileFiltersType = {
  owners: ProfileOwnerType[];
  inputType: ProfileInputType[];
  mirrorTypes: ProfileMirrorType[];
};

export type ProfileConfigurationType = {
  id?: number;
  profileName: string;
  ownerId: number;
  inputType: number;
  mirrorTypeId: number;
  language: string;
  description: string;
  ownerEmail?: string;
};

export type ProfileSnapType = {
  id: number;
  name: string;
  enable: boolean;
  branch: string;
};

export type SnapBranchConfigType = {
  id: number;
  branch: string;
};

export type ProfileSnapConfigType = {
  profileId: number;
  snaps: SnapBranchConfigType[];
};

export type ProfileContentType = {
  id: number;
  value: string;
  enabled: boolean;
  childNodes?: ProfileContentType[];
};

export type NeutralVideoType = {
  id: number;
  videoLocation: string;
  enable: boolean;
  videoName: string;
  videoSequence?: number;
  duration?: number;
  size?: number;
  expiredDate?: string;
  videoId?: number
};

export type AddVideosType = {
  videoId: number,
  enable: boolean,
  videoSequence: number
};

export type AddQrImagesType = {
  qrImageId: number,
  enable: boolean,
  qrImageSequence: number
};

export type UpdateNeutralVideoType = {
  videos: NeutralVideoType[];
};

export type UpdateQrImageType = {
  qrImages: QrImageType[];
};

export type VoiceoverFileType = { voiceName?: string; voicePath?: string, gifName?: string, gifPath?: string }

export type StepVoiceoverFileType = {
  step: VOICEOVER_STEP;
  files: VoiceoverFileType[];
  enable?: boolean
};

export interface FileVoiceOver {
  id?: number,
  order?: number,
  videoName?: string,
  videoPath?: string,
  gifName?: string,
  gifPath?: string
}


export type VoiceoverType = {
  type?: TYPE_VOICEOVER_ENUM;
  id?: number,
  mirrorId?: number;
  profileId?: number;
  duration?: number;
  voiceovers?: FileVoiceOver[];
  enable?: boolean;
  validation?: {
    isError?: boolean,
    message?: string
  }
};

export type ProfileVoiceoverType = {
  id?: number;
  profileId?: number
  enableVoiceover: boolean;
  enableStep1?: boolean;
  enableStep2?: boolean;
  enableNobody: boolean;
  enableFixedDuration: boolean;
  step1: number;
  step2: number;
  step_nobody: number;
  step_fixed_duration: number;
};

export enum VOICEOVER_STEP {
  STEP1 = 'step1',
  STEP2 = 'step2',
  NOBODY = 'step_nobody',
  FIXED_DURATION = 'step_fixed_duration'
}

export const VOICEOVER_NAME_NUM = {
  0 : 'step1',
  1 : 'step2',
  2 : 'step_nobody',
  3 : 'step_fixed_duration'
}

export const VOICEOVER_NAME = {
  0: 'Step 1',
  1: 'Step 2',
  2: 'Nobody',
  3: 'Fixed duration'
};

export const VOICEOVER_NAME_LOCALE = {
  0:  messages.main.step1.title,
  1:  messages.main.step2.title,
  2:  messages.main.step_nobody.title,
  3:  messages.main.step_fixed_duration.title
};

export type ProfilePhotoType = {
  bottomName?: string,
  bottomPath?: string,
  headerName?: string,
  headerPath?: string,
  fullScreenName?: string,
  fullScreenPath?: string,
}

export type DeleteVoiceoverFiles = {
  [key: string]: boolean
}

export type ProfileType = {
  id: number;
  name: string
}

export type MirrorFiltersType = {
  profiles?: ProfileType[]
  mirrorIds?: string[];
  shopNames?: string[];
}

export type MirrorConfigurationType = {
  id?: number;
  uuid: string;
  name: string;
  advertiseMode: string;
  profileId: number;
  teamviewer: string;
  description: string;
  isAdOverlay?: boolean;
  isVideoRecord?: boolean;
  date?: string
  isModalEnabled?: boolean;
  videoRecord?: boolean
  reservation?: ReservationType;
  shopName?: string
};

export type MirrorVoiceoverType = ProfileVoiceoverType & {
  theFirstIsOverride?: boolean;
  isOverride: boolean;
  mirrorId?: number;
};

export type AdOverlayAndVideoRecordType = {
  mirrorId: number
  activateAdOverlay: boolean
  activateVideoRecord: boolean
  date?: string
}

export type MirrorPhotoType = ProfilePhotoType & {
  isOverride: boolean;
}

export enum TYPES {HOURLY = "Hourly", TIMES = "Times"}

export type TimeValueType = {
  time: number
  value: number
}

export type ReservationDateType = {
  date: string;
  link: string;
  name: string;
  timeValuesN: TimeValueType[] // for morning, noon, night
  timeValuesV: TimeValueType[] // for hourly
}

export type ReservationType = {
  dates: ReservationDateType[];
  type: TYPES
}

export type MirrorNeutralVideos = {
  isOverride: boolean;
  mirrorVideos: NeutralVideoType[]
  profileVideos: NeutralVideoType[]
}

export type OverrideMirrorNeutralVideos = {
  videos: AddVideosType[]
}

export type UpdateMirrorNeutralVideo = UpdateNeutralVideoType & {
  isOverride: boolean;
}

export type UpdateMirrorQrImage = UpdateQrImageType & {
  isOverride: boolean;
}

export type OverrideMirrorQrImages = {
  qrImages: AddQrImagesType[]
}

export type QrImageType = {
  id: number;
  qrImageLocation: string;
  enable: boolean;
  qrImageName: string;
  qrImageSequence?: number;
  qrDuration?: number;
  expiredDate?: string;
  qrImageId?: number;
  qrImageDescription: string;
  qrImageDescriptionColor: string;
};

export type MirrorQrImages = {
  isOverride: boolean;
  mirrorQrImages: QrImageType[]
  profileQrImages: QrImageType[]
}
