import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { ProfileAuditListPageResult } from 'src/api_v2/profile-audit/types';
import IPagination from 'src/models/pagination';

export type ProfileAuditType = {
  id: number;
  description?: string;
  name?: string;
  action?: string;
  modifiedDate?: string;
  createdDate?: string;
  contentJson?: string;
  updatedBy?: {
    email?: string,
    id?: number
  };
};
export interface ProfileAuditListState extends IPagination {
  total: number;
  data: ProfileAuditType[];
  loading: boolean;
  error: string;
  deleted: boolean;
  uploadCredit?: number;
  exporting: boolean;
}

export const initialState: ProfileAuditListState = {
  data: [],
  total: 0,
  page: 1,
  pageSize: 50,
  sort: 'modifiedDate|desc',
  search: '',
  loading: false,
  error: '',
  deleted: false,
  uploadCredit : 0,
  exporting: false
};

const ProfileAuditListSlice = createSlice({
  name: 'profileAuditlist',
  initialState,
  reducers: {
    resetState: () => initialState,
    fetchProfileAuditList(state: ProfileAuditListState) {
      state.loading = true;
    },
    profileAuditListFetched(
      state: ProfileAuditListState,
      action: PayloadAction<ProfileAuditListPageResult>
    ) {
      state.loading = false;
      state.error = '';
      state.data = action.payload.data;
      state.total = action.payload.total;
    },
    profileAuditListFetchedError(state: ProfileAuditListState) {
      state.loading = false;
      state.error = 'Fail to fetch profileAudit list';
    },
    setCurrentPage(state: ProfileAuditListState, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setSearch(state: ProfileAuditListState, action: PayloadAction<string>) {
      state.search = action.payload;
    },
    setSort(state: ProfileAuditListState, action: PayloadAction<string>) {
      state.sort = action.payload;
    },
    deleteProfileAudit(state: ProfileAuditListState, action: PayloadAction<number>) {
      state.loading = true;
      state.error = '';
      state.deleted = false;
    },
    profileAuditDeleted(state: ProfileAuditListState) {
      state.loading = false;
      state.deleted = true;
    },
    profileAuditDeletedError(state: ProfileAuditListState, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    resetDeleted(state: ProfileAuditListState) {
      state.deleted = false;
    },
    setPageSize(state: ProfileAuditListState, action: PayloadAction<number>) {
      state.pageSize = action.payload;
    },
    fetchTotalProfileAuditList(state: ProfileAuditListState) {
      state.loading = true;
    },
    fetchRemainingCredit(state: ProfileAuditListState, action: PayloadAction<number>) {
      state.loading = true;
    },
    fetchedRemainingCredit(
      state: ProfileAuditListState,
      action: PayloadAction<number>
    ) {
      state.loading = false;
      state.uploadCredit = action.payload || 0;
    },
    fetchedRemainingCreditError(state: ProfileAuditListState) {
      state.loading = false;
    },
    resetError(state: ProfileAuditListState) {
      state.error = '';
    },
    exportFile(state: ProfileAuditListState) {
      state.exporting = true
    },
    unscreenHistoryExported(state: ProfileAuditListState) {
      state.exporting = false
    },
    unscreenHistoryExportedFail(state: ProfileAuditListState) {
      state.exporting = false
    }
  }
});

export const { actions, reducer, name: sliceKey } = ProfileAuditListSlice;
