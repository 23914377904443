import { call, put, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import { UserClickTrackingApi } from 'src/api/user-click-tracking-api';

function* fetchAllUserClickTrackingReport() {
  try {
    const userClickTrackingApi = new UserClickTrackingApi();
    const result = yield call(userClickTrackingApi.fetchUserClickTracking);

    yield put(actions.userClickTrackingFetched(result));
  } catch (err) {
    yield put(actions.userClickTrackingFetchedError());
  }
}

export default function* watchUserClickTrackingReport() {
  yield takeLatest(
    actions.fetchUserClickTracking.type,
    fetchAllUserClickTrackingReport
  );
}
