import { AxiosResponse } from 'axios';
import agent from 'src/api_v2/base';
import { FetchUserAuditListParams, UserAuditListPageResult } from './types';
import moment from 'moment';
class UserAuditApi {
  async fetchUserAuditList(params: FetchUserAuditListParams) {
    try {
      const response: AxiosResponse = await agent.get(
        `/user-audit?${params.toString()}`
      );

      if (response.status === 200) {
        return response.data as UserAuditListPageResult;
      }

      return [];
    } catch (err) {
      throw new Error(err);
    }
  }

  async exportFile(params: FetchUserAuditListParams): Promise<any> {
    try {
      const response: AxiosResponse = await agent.post(
        `/user-audit/export/csv?${params.toString()}`,
        {
          responseType: 'blob'
        }
      );

      if (response.status === 201) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.setAttribute(
          'download',
          `user-${moment().format('YYYY-MM-DD, HH-mm')}.csv`
        );
        link.href = url;
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);

        return true;
      }
      return false;
    } catch (err) {
      throw err;
    }
  }

  async updateAction(data: { action: string }) {
    try {
      const response: AxiosResponse = await agent.put(`/user-audit/action`, {
        action: data.action
      });

      if (response.status !== 200 && response.status !== 204) {
        return {
          data: {},
          status: false
        };
      }

      return {
        data: response,
        status: true
      };
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
}

const userAuditListApi = new UserAuditApi();
export default userAuditListApi;
