import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store/configureStore';
import { GroupListState } from './slice';

const selectDomain = (state: RootState) => {
  return state.grouplist;
};

export const selectGroupList = createSelector(
  [selectDomain],
  (grouplist: GroupListState) => grouplist.data
);

export const selectPagination = createSelector(
  [selectDomain],
  (grouplist: GroupListState) => ({
    page: grouplist.page,
    pageSize: grouplist.pageSize,
    search: grouplist.search,
    sort: grouplist.sort,
  })
);

export const selectSearch = createSelector(
  [selectDomain],
  (grouplist: GroupListState) => grouplist.search
);

export const selectSort = createSelector(
  [selectDomain],
  (grouplist: GroupListState) => grouplist.sort
);

export const selectPage = createSelector(
  [selectDomain],
  (grouplist: GroupListState) => grouplist.page
);

export const selectPageSize = createSelector(
  [selectDomain],
  (grouplist: GroupListState) => grouplist.pageSize
);

export const selectTotal = createSelector(
  [selectDomain],
  (grouplist: GroupListState) => grouplist.total
)

export const selectLoading = createSelector(
  [selectDomain],
  (grouplist: GroupListState) => grouplist.loading
)

export const selectDeleted = createSelector(
  [selectDomain],
  (grouplist: GroupListState) => grouplist.deleted
)

export const selectUploadCredit = createSelector(
  [selectDomain],
  (grouplist: GroupListState) => grouplist.uploadCredit
)

export const selectError = createSelector(
  [selectDomain],
  (grouplist: GroupListState) => grouplist.error
)