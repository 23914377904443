class MirrorConsoleConfiguration {
    get DefaultApi() {
        return process.env.REACT_APP_DEFAULT_API;
    }

    get MirrorApi() {
        return process.env.REACT_APP_MIRROR_API;
    }

    get ConsoleApi() {
        return process.env.REACT_APP_CONSOLE_API;
    }

    get ThwallUrl() {
        return process.env.REACT_APP_THWALL_URL;
    }

    get SocketServer() {
        return process.env.REACT_APP_SOCKET_SERVER;
    }

    get PremiereApi() {
        return process.env.REACT_APP_PREMIERE_API;
    }

    get PremiereSocket() {
        return process.env.REACT_APP_PREMIERE_SOCKET;
    }

    get SketchfabClientId() {
        return process.env.REACT_APP_SKETCHFAB_CLIENT_ID;
    }

    get SketchfabClientSecret() {
        return process.env.REACT_APP_SKETCHFAB_CLIENT_SECRET;
    }

    get IsProduction() {
        return process.env.REACT_APP_ENV === 'production';
    }

    get IsStaging() {
        return process.env.REACT_APP_ENV === 'staging';
    }

    get IsQA() {
        return process.env.REACT_APP_ENV === 'qa';
    }

    get IsLocal() {
        return process.env.REACT_APP_ENV === 'develop';
    }

    get helpdeskWebsocketUrl() {
        return process.env.REACT_APP_HELPDESK_WEBSOCKET_URL
    }
}

const config = new MirrorConsoleConfiguration();
export default config;