import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store/configureStore';
import { UserListState } from './slice';

const selectDomain = (state: RootState) => {
  return state.userlist;
};

export const selectUserList = createSelector(
  [selectDomain],
  (userlist: UserListState) => userlist.data
);

export const selectPagination = createSelector(
  [selectDomain],
  (userlist: UserListState) => ({
    page: userlist.page,
    pageSize: userlist.pageSize,
    search: userlist.search,
    sort: userlist.sort,
    active: userlist.activated,
    role: userlist.role
  })
);

export const selectSearch = createSelector(
  [selectDomain],
  (userlist: UserListState) => userlist.search
);

export const selectRole = createSelector(
  [selectDomain],
  (userlist: UserListState) => userlist.role
);

export const selectActivated = createSelector(
  [selectDomain],
  (userlist: UserListState) => userlist.activated
);

export const selectSort = createSelector(
  [selectDomain],
  (userlist: UserListState) => userlist.sort
);

export const selectPage = createSelector(
  [selectDomain],
  (userlist: UserListState) => userlist.page
);

export const selectPageSize = createSelector(
  [selectDomain],
  (userlist: UserListState) => userlist.pageSize
);

export const selectTotal = createSelector(
  [selectDomain],
  (userlist: UserListState) => userlist.total
)

export const selectLoading = createSelector(
  [selectDomain],
  (userlist: UserListState) => userlist.loading
)

export const selectDeleted = createSelector(
  [selectDomain],
  (userlist: UserListState) => userlist.deleted
)

export const selectGroupId = createSelector(
  [selectDomain],
  (userlist: UserListState) => userlist.groupId
)