import { ContentType } from 'src/containers_v2/categories/content/content-list/store/slice';
import IPagination from 'src/models/pagination';

export class FetchContentsParams implements IPagination {
  page: number;
  pageSize: number;
  search: string; // content
  sort: string;
  type: string;

  constructor(
    page: number = 1,
    pageSize: number = 20,
    search: string = '',
    sort: string = 'name|asc',
    type: string = ''
  ) {
    this.page = page;
    this.pageSize = pageSize;
    this.search = search;
    this.sort = sort;
    this.type = type;
  }

  toString() {
    return `page=${this.page}&size=${this.pageSize}&search=${this.search}&sort=${this.sort}&type=${this.type}`;
  }
}

export type ContentsPageResult = {
  data: ContentType[];
  totalItems: number;
};

export type ContentsResult = {
  mirrorTypes: string[];
};
