import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export type GroupConfigurationType = {
  id?: string
  description?: string;
  name: string;
  uploadCredit: number
  viewExpirationDate?: string;
  createExpirationDate?: string;
};

export interface GroupConfigurationState {
  loading?: boolean;
  error?: string;
  saved?: boolean;
  editingGroup?: GroupConfigurationType;
}

export const initialState: GroupConfigurationState = {
  loading: false,
  error: '',
  saved: false,
  editingGroup: undefined
};

const groupConfigurationlice = createSlice({
  name: 'groupConfiguration',
  initialState,
  reducers: {
    reset(state: GroupConfigurationState) {
      state.saved = false;
      state.editingGroup = undefined;
    },
    saveGroup(state: GroupConfigurationState, action: PayloadAction<GroupConfigurationType>) {
      state.loading = true;
      state.error = ''
      state.saved = false
    },
    groupSaved(state: GroupConfigurationState) {
      state.loading = false;
      state.saved = true
    },
    groupSavedError(state: GroupConfigurationState, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload
    },
    getGroup(state: GroupConfigurationState, action: PayloadAction<string>) {
      state.loading = true
    },
    groupGot(state: GroupConfigurationState, action: PayloadAction<GroupConfigurationType>) {
      state.loading = false;
      state.editingGroup = action.payload
    },
    groupGotError(state: GroupConfigurationState, action: PayloadAction<string>) {
      state.loading = false
      state.error = action.payload
    }
  }
});

export const { actions, reducer, name: sliceKey } = groupConfigurationlice;
