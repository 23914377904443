import { Alert, AlertColor, Snackbar, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

type Props = {
  text: string;
  isOpen: boolean;
  onClose?: () => void;
  severity?: AlertColor;
  anchor?: {
    vertical: 'bottom' | 'top';
    horizontal: 'right' | 'center' | 'left';
  };
  autoHideDuration?: number;
};

const Toast = (props: Props) => {
  const {
    text,
    isOpen,
    severity = 'success',
    anchor = {
      vertical: 'top',
      horizontal: 'right'
    },
    onClose,
    autoHideDuration = 5000
  } = props;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    setOpen(false);
    onClose && onClose();
  };
  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      anchorOrigin={anchor}
    >
      <Alert
        onClose={handleClose}
        severity={severity}
        sx={() => ({
          '&.MuiAlert-root': {
            padding: '20px 30px'
          }
        })}
      >
        <Typography sx={{ mr: 2, fontSize: 16, whiteSpace: 'pre-wrap' }}>
          {text}
        </Typography>
      </Alert>
    </Snackbar>
  );
};

export default Toast;
