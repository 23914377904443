import React, { useContext, useEffect } from 'react';
import {
  Box,
  Hidden,
  InputBase,
  Typography,
  ClickAwayListener,
  IconButton,
  Tooltip,
  useMediaQuery
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { SidebarContext } from 'src/contexts/SidebarContext';
import Logo from 'src/components/Logo';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useDispatch, useSelector } from 'react-redux';
import { setLanguage } from 'src/redux/store/language/slice';
import { UserResponse } from '../../../api/mirrors-console-api';
import {
  setAuth,
  setUser,
  setUserSketchfab
} from '../../../redux/store/user/slice';
import { useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUPIcon from '@mui/icons-material/ArrowDropUp';
import { useNavigate } from 'react-router';
import { selectAuthUser } from 'src/containers_v2/auth/store/selector';
import { authActions } from 'src/containers_v2/auth/store/slice';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { messages } from 'src/locales/messages';
import LanguageSelector from 'src/components/LanguageSelector';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import { actions as actionsGroup } from '../../../containers_v2/ar_creator/group/group-list/store/slice';
import {
  selectGroupList,
  selectUploadCredit
} from 'src/containers_v2/ar_creator/group/group-list/store/selector';
import LockIcon from '@mui/icons-material/Lock';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { UserRoles } from 'src/utils/constants';
import { isArray } from 'lodash';
import userAuditListApi from 'src/api_v2/user-audit/user-audit-list-api';

const HeaderWrapper = styled(Box)(
  ({ theme, width }) => `
        min-height: 64px;
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        z-index: 5;
        background-color: ${theme.header.background};
        box-shadow: ${theme.header.boxShadow};
        justify-content: space-between;
        width: 100%;
`
);

function Header() {
  const matches = useMediaQuery('(max-width:1280px)');
  const matches480 = useMediaQuery('(max-width:480px)');
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const {
    handleDrawerClose,
    handleDrawerOpen,
    open: openCollapse
  } = useContext(SidebarContext);
  const authUser = useSelector(selectAuthUser);
  const uploadCredit = useSelector(selectUploadCredit);
  const dataGroupList = useSelector(selectGroupList);
  const [lng, setLng] = React.useState('en');
  const navigate = useNavigate();
  const [userOpened, setUserOpened] = useState(false);
  const { t } = useTranslation();
  const isMobile = window.innerWidth < 790;

  const admin = UserRoles.toString(UserRoles.Admin);
  const arAdmin = UserRoles.toString(UserRoles.AR_admin);
  const arOwner = UserRoles.toString(UserRoles.AR_owner_namecard);
  const userAdmin = UserRoles.toString(UserRoles.User_admin);

  const acceptRoles = (roles: string[]) => {
    const permissioned = isArray(roles)
      ? roles.some((item) =>
          authUser.role.some((item1) => roles.includes(item1))
        )
      : false;

    if (permissioned) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    if (authUser.id) {
      const permissioned = acceptRoles([admin, arAdmin, arOwner, userAdmin]);
      if (permissioned) {
        dispatch(actionsGroup.fetchRemainingCredit(authUser.id));
      }
    }
  }, [authUser?.id, dataGroupList]);

  const logOut = async () => {
    await userAuditListApi.updateAction({ action: 'Logoff' });
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    dispatch(setUser({role: []} as UserResponse));
    dispatch(setAuth({ isAuth: false }));
    dispatch(setUserSketchfab(null));
    dispatch(authActions.resetState());
    navigate('/login');
    window.location.reload();
  };

  const settings = () => {
    navigate('login/recover');
  };

  const Selector = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5px',
    borderRadius: '5px',
    transition: '.2s',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#f2f5f9',
      color: '#7F63F4'
    },
    '&.active': {
      backgroundColor: '#f2f5f9',
      color: '#7F63F4',
      cursor: 'pointer'
    }
  };

  const ItemsUser = () => {
    const styleForElems = {
      padding: '5px 10px',
      borderRadius: '5px',
      fontSize: '16px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '&:hover': {
        backgroundColor: '#f2f5f9',
        color: '#7F63F4',
        cursor: 'pointer',
        transition: '.4s'
      }
    };

    return (
      <Box
        sx={{
          position: 'absolute',
          borderRadius: '0px 0px 5px 5px',
          padding: '5px 10px',
          background: '#ffffff',
          right: 0,
          minWidth: 'max-content'
        }}
      >
        <Typography onClick={logOut} textAlign="right" sx={styleForElems}>
          {t(messages.header.logout())}
          <LogoutIcon sx={{ marginLeft: '5px', fontSize: 26 }} />
        </Typography>
        <Typography
          onClick={(e) => navigate('change-password')}
          textAlign="right"
          sx={styleForElems}
        >
          {t(messages.main.changePassword.changePassword())}
          <LockIcon sx={{ marginLeft: '5px', fontSize: 26 }} />
        </Typography>
      </Box>
    );
  };

  const handleChange = (event) => {
    setLng(event.target.value);
    i18n.changeLanguage(event.target.value);
    localStorage.setItem('language', event.target.value);
    dispatch(setLanguage(event.target.value === 'en' ? 'en' : 'jp'));
  };

  const toggleOpened = (e) => {
    setUserOpened(!userOpened);
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const renderUploadCredit = () => {
    if (authUser.groupId && authUser.groupId != null) {
      if (isMobile) {
        return (
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <Tooltip
              PopperProps={{
                disablePortal: true
              }}
              onClose={handleTooltipClose}
              open={open}
              disableFocusListener
              disableTouchListener
              title={
                <Typography>
                  {`${t(messages.main.uploadCredit())} : ${uploadCredit} ${t(
                    messages.main.times()
                  )}`}
                </Typography>
              }
            >
              <IconButton onClick={handleTooltipOpen}>
                <AttachMoneyIcon />
              </IconButton>
            </Tooltip>
          </ClickAwayListener>
        );
      }
      return `${t(messages.main.uploadCredit())} : ${uploadCredit} ${t(
        messages.main.times()
      )}`;
    }
  };

  const usernameRender = () => {
    const username = localStorage.getItem('username') ?? '';

    if (matches480) {
      // mobile screen
      return '';
    } else if (matches && !matches480) {
      // ipad screen
      return username;
    }

    return username;
  };

  return (
    <HeaderWrapper display="flex" alignItems="center">
      <Box>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={() => {
            setTimeout(() => {
              handleDrawerOpen();
            }, 50);
          }}
          sx={{
            ...(openCollapse && { display: 'none' }),
            padding: 0
          }}
        >
          <MenuOutlinedIcon id="collapse-icon" />
        </IconButton>
      </Box>

      <Box
        sx={{
          ...(openCollapse && matches && { display: 'none' })
        }}
        display="flex"
        justifyContent="end"
        alignItems="center"
        width="100%"
      >
        <Box
          fontSize="18px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          marginRight="10px"
        >
          <Box margin="0px 20px 0px 10px">{renderUploadCredit()}</Box>
          <LanguageSelector />
          <Box>
            <ClickAwayListener onClickAway={() => setUserOpened(false)}>
              <Box
                color="primary"
                margin="0px 0px 0px 10px"
                position="relative"
              >
                <Box
                  display="flex"
                  style={{ userSelect: 'none' }}
                  sx={Selector}
                  onClick={toggleOpened}
                  className={userOpened && 'active'}
                >
                  <AccountCircleIcon
                    sx={{ fontSize: 26, marginRight: '5px' }}
                  />
                  {usernameRender()}
                  {userOpened ? <ArrowDropUPIcon /> : <ArrowDropDownIcon />}
                </Box>
                {userOpened && <ItemsUser />}
              </Box>
            </ClickAwayListener>
          </Box>
        </Box>
      </Box>
    </HeaderWrapper>
  );
}

export default Header;
