import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { USER_ROLE } from 'src/utils/constants';

export type UserConfigurationType = {
  id?: string
  email: string;
  uuid: string;
  password: string;
  confirmPassword: string;
  role: string[];
  zipCode: string;
  isActivated: boolean;
  groupId?: number | "empty";
  checkboxes?: [],
  mirrorIds?: number[]
};

export type UserMirrorsType = {
  id?: string;
  mirrorId?: string;
};

export interface UserConfigurationState {
  loading: boolean;
  error: string;
  saved: boolean;
  editingUser: UserConfigurationType;
  userMirrors?: UserMirrorsType[]
}

export const initialState: UserConfigurationState = {
  loading: false,
  error: '',
  saved: false,
  editingUser: undefined,
  userMirrors: []
};

const userConfigurationlice = createSlice({
  name: 'userConfiguration',
  initialState,
  reducers: {
    reset(state: UserConfigurationState) {
      state.saved = false;
      state.editingUser = undefined;
      state.userMirrors = [];
    },
    saveUser(state: UserConfigurationState, action: PayloadAction<UserConfigurationType>) {
      state.loading = true;
      state.error = ''
      state.saved = false
    },
    userSaved(state: UserConfigurationState) {
      state.loading = false;
      state.saved = true
    },
    userSavedError(state: UserConfigurationState, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload
    },
    getUser(state: UserConfigurationState, action: PayloadAction<string>) {
      state.loading = true
    },
    userGot(state: UserConfigurationState, action: PayloadAction<UserConfigurationType>) {
      state.loading = false;
      state.editingUser = action.payload
    },
    userGotError(state: UserConfigurationState, action: PayloadAction<string>) {
      state.loading = false
      state.error = action.payload
    },
    getMirrorsAssigned(state: UserConfigurationState, action: PayloadAction<string>) {
      state.loading = true
    },
    getMirrorsAssignedSuccess(state: UserConfigurationState, action: PayloadAction<UserMirrorsType[]>) {
      state.loading = false;
      state.userMirrors = action.payload
    },
    getMirrorsAssignedError(state: UserConfigurationState, action: PayloadAction<string>) {
      state.loading = false
      state.error = action.payload
    },
    saveMirrorsAssigned(state: UserConfigurationState, action: PayloadAction<{ id: string, userMirrors: UserMirrorsType[] }>) {
      state.loading = true;
      state.error = ''
      state.saved = false
    },
    savedMirrorsAssignedSuccess(state: UserConfigurationState) {
      state.loading = false;
      state.saved = true
    },
    savedMirrorsAssignedError(state: UserConfigurationState, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload
    },
  }
});

export const { actions, reducer, name: sliceKey } = userConfigurationlice;
