import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store/configureStore';
import { UserState } from './slice';

export const selectDomain = (state: RootState) => {
    return state.user;
};

export const SelectUser = createSelector(
    [selectDomain],
    (user: UserState) => user,
);

export const SelectUserSketchfabData = createSelector(
    [selectDomain],
    (user: UserState) => user.sketchfab,
);
