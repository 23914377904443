import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store/configureStore';
import { ProfileListState } from './slice';

const selectDomain = (state: RootState) => {
  return state.profileList;
};

export const selectQuery = createSelector(
  [selectDomain],
  (state: ProfileListState) => ({
    search: state.search,
    sort: state.sort,
    page: state.page,
    pageSize: state.pageSize,
    mirrorTypeFilter: state.mirrorTypeFilter,
    inputTypeFilter: state.inputTypeFilter,
    ownerFilter: state.ownerFilter
  })
);

export const selectProfileList = createSelector(
  [selectDomain],
  (state: ProfileListState) => state.data
);

export const selectFilters = createSelector(
  [selectDomain],
  (state: ProfileListState) => state.filters
)

export const selectTotal = createSelector(
  [selectDomain],
  (state: ProfileListState) => state.total
)

export const selectDeleted = createSelector(
  [selectDomain],
  (state: ProfileListState) => state.deleted
)

export const selectLoading = createSelector(
  [selectDomain],
  (state: ProfileListState) => state.loading
)

export const selectError = createSelector(
  [selectDomain],
  (state: ProfileListState) => state.error
)

