import React, {
  FC,
  ReactNode,
  useState,
  useContext,
  useEffect,
  memo
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import clsx from 'clsx';
import { SidebarContext } from 'src/contexts/SidebarContext';

import PropTypes from 'prop-types';
import {
  Collapse,
  ListItem,
  Popover,
  Typography,
  Tooltip,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box
} from '@mui/material';

import ExpandLessTwoToneIcon from '@mui/icons-material/ExpandLessTwoTone';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import { PureLightTheme } from 'src/theme/schemes/PureLightTheme';

interface SidebarMenuItemProps {
  children?: ReactNode;
  link?: string;
  icon?: any;
  badge?: string;
  open?: boolean;
  active?: boolean;
  name: string;
  roles: string[];
  collapseHeader?: boolean;
  count?: number
}
const listHref = window.location.href.split('/');

let endpoint = listHref[listHref?.length - 1];

switch (endpoint) {
  case 'summary':
    endpoint = `people-counter/${endpoint}`;
    break;
  case 'detailed':
    endpoint = `people-counter/${endpoint}`;
    break;
  default:
    break;
}

let activeRef: any = React.createRef<any>();
let activeTitle = endpoint;

const SidebarMenuItem: FC<SidebarMenuItemProps> = ({
  children,
  link,
  icon: Icon,
  badge,
  open: openParent,
  active,
  name,
  roles,
  collapseHeader,
  count,
  ...rest
}) => {
  const [menuToggle, setMenuToggle] = useState<boolean>(openParent);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const { open } = useContext(SidebarContext);
  const navigate = useNavigate();

  useEffect(() => {
    // set value of title into state
    if (link === activeRef.current) {
      activeTitle = link;
    }
  }, [link]);

  useEffect(() => {
    // the dropdown will keep collapse when title clicked
    const isActivated =
      children &&
      children['props'].children.some(
        (x) => x?.['props']?.link === activeRef.current
      );
    if (isActivated) {
      setMenuToggle(true);
    }
  }, [children]);

  useEffect(() => {
    // the first render
    const isActivated =
      children &&
      children['props'].children.some((x) => x?.['props']?.link === endpoint);
    if (isActivated) {
      setMenuToggle(true);
    }
  }, []);

  const toggleMenu = (e): void => {
    if (!open) {
      handleClick(e);
    }
    setMenuToggle((Open) => !Open);
  };

  const sxForChildsButton = {
    '& div div div:hover': {
      backgroundColor: '#f2f5f9',
      color: '#7F63F4',
      borderRadius: '6px'
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (children) {
    const isActivated =
      children &&
      children['props'].children.some(
        (x) => x?.['props']?.link === activeTitle
      );

    return (
      <ListItem key={name} disablePadding sx={{ display: 'block' }}>
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: open ? 'space-between' : 'center',
            px: 1,
            display: 'flex'
          }}
          onClick={toggleMenu}
        >
          <Tooltip title={name}>
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 1 : 'auto',
                justifyContent: 'center'
              }}
            >
              <Icon
                sx={{ ...(isActivated ? { color: 'rgb(101, 79, 195)' } : {}) }}
              />
            </ListItemIcon>
          </Tooltip>
          <ListItemText
            primary={name}
            sx={{
              opacity: open ? 1 : 0,
              ...(isActivated ? { color: 'rgb(101, 79, 195)' } : {}),
              '.MuiTypography-root': {
                fontFamily: `${PureLightTheme.typography.fontFamily} !important`,
                fontWeight: `${PureLightTheme.typography.h1.fontWeight} !important`
              }
            }}
          />
          <Box
            sx={{
              minWidth: 0,
              justifyContent: 'center',
              display: open ? 'block' : 'none'
            }}
          >
            {menuToggle ? <ExpandLessTwoToneIcon /> : <ExpandMoreTwoToneIcon />}
          </Box>
        </ListItemButton>

        {!open ? (
          <Popover
            id={Boolean(anchorEl) ? 'simple-popover' : undefined}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            onClick={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
          >
            <Typography sx={{ p: 2 }}>{children}</Typography>
          </Popover>
        ) : (
          <Box sx={{ marginLeft: 2 }}>
            <Collapse sx={sxForChildsButton} in={menuToggle}>
              {children}
            </Collapse>
          </Box>
        )}
      </ListItem>
    );
  }

  return (
    <ListItem key={name} disablePadding sx={{ display: 'block' }}>
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: open ? 'initial' : 'center',
          px: 1,
          '&:hover': {
            color: 'rgb(101, 79, 195)',
            '.MuiListItemIcon-root': {
              color: 'rgb(101, 79, 195)'
            }
          }
        }}
        onClick={() => {
          navigate(link);
          activeTitle = link;
          activeRef.current = link;
        }}
      >
        <Tooltip title={name}>
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 1 : 'auto',
              justifyContent: 'center'
            }}
          >
            <Icon
              className={clsx({ 'Mui-active': link === activeTitle })}
              sx={link === activeTitle ? { color: 'rgb(101, 79, 195)' } : {}}
            />
          </ListItemIcon>
        </Tooltip>
        <ListItemText
          primary={name}
          className={clsx({ 'Mui-active': link === activeTitle })}
          sx={{
            opacity: open || (!open && count >= 2) ? 1 : 0,
            ...(link === activeTitle ? { color: 'rgb(101, 79, 195)' } : {}),
            '.MuiTypography-root': {
              fontFamily: `${PureLightTheme.typography.fontFamily} !important`,
              fontWeight: `${PureLightTheme.typography.h1.fontWeight} !important`
            }
          }}
        />
      </ListItemButton>
    </ListItem>
  );
};

SidebarMenuItem.propTypes = {
  children: PropTypes.node,
  active: PropTypes.bool,
  link: PropTypes.string,
  icon: PropTypes.elementType,
  badge: PropTypes.string,
  open: PropTypes.bool,
  name: PropTypes.string.isRequired
};

SidebarMenuItem.defaultProps = {
  open: false,
  active: false
};

export default memo(SidebarMenuItem);
