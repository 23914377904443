import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import arTemplateApi from 'src/api_v2/ar-template/ar-template-list-api';
import { actions, ARTemplateConfigurationType } from './slice';

function* saveARTemplate(action: PayloadAction<ARTemplateConfigurationType>) {
  try {
    const result = yield call(
      action.payload.id ? arTemplateApi.updateARTemplate : arTemplateApi.addARTemplate,
      action.payload
    );

    if (result) {
      yield put(actions.arTemplateSaved());
    } else {
      yield put(actions.arTemplateSavedError('Fail to save arTemplate.'));
    }
  } catch (err) {
    if (err.response) {
      const message = err.response.data?.message as string[];
      if (message?.includes('Email is duplicated')) {
        yield put(actions.arTemplateSavedError('Email is duplicated'));
      } else {
        yield put(actions.arTemplateSavedError('Fail to save arTemplate.'));
      }
    }
  }
}

function* getARTemplate(action: PayloadAction<string>) {
  try {
    const result = yield call(arTemplateApi.getARTemplate, action.payload);

    if (result) {
      yield put(
        actions.arTemplateGot({
          ...result,
          confirmPassword: result.password
        })
      );
    } else {
    }
  } catch (err) {
    yield put(actions.arTemplateGotError('Fail to get arTemplate.'));
  }
}

export default function* watchARTemplateConfigurationSaga() {
  yield takeLatest(actions.saveARTemplate.type, saveARTemplate);
  yield takeLatest(actions.getARTemplate.type, getARTemplate);
}
