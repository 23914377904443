import { createSlice } from "@reduxjs/toolkit";

export interface LanguageState {
    language: string
}

const INITIAL_STATE = {
    language: "jp"
};

const langSlice = createSlice({
    name: "lang",
    initialState: INITIAL_STATE,
    reducers: {
        setLanguage: (state, action) => {
            state.language = action.payload
        },
    }
})

export default langSlice.reducer;
export const { setLanguage } = langSlice.actions;
