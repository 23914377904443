import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store/configureStore';
import { VideosState } from './slice';

const selectDomain = (state: RootState) => {
  return state.videos;
};

export const selectVideosList = createSelector(
  [selectDomain],
  (state: VideosState) => state.data
);

export const selectLoading = createSelector(
  [selectDomain],
  (state: VideosState) => state.loading
);

export const selectDeleted = createSelector(
  [selectDomain],
  (state: VideosState) => state.deleted
);

export const selectSaved = createSelector(
  [selectDomain],
  (state: VideosState) => state.saved
);

export const selectQuery = createSelector(
  [selectDomain],
  (state: VideosState) => ({
    search: state.search,
    sort: state.sort,
    page: state.page,
    pageSize: state.pageSize,
    total: state.total,
    expiredDate: state.expiredDate,
    includeProfiles: state.includeProfiles,
    addedVideoIds: state.addedVideoIds,
  })
);

export const selectError = createSelector(
  [selectDomain],
  (state: VideosState) => state.error
);

export const selectSearch = createSelector(
  [selectDomain],
  (state: VideosState) => state.search
);