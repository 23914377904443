import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { ARPamphletListPageResult } from 'src/api_v2/ar-pamphlet/types';
import { ARProjectListPageResult } from 'src/api_v2/ar-project/types';
import { ARProjectType } from 'src/containers_v2/ar_creator/ARProject/list/store/slice';
import IPagination from 'src/models/pagination';

export interface ARPamphletState extends IPagination {
  total: number;
  data: ARProjectType[];
  loading: boolean;
  error: boolean;
  deleted: boolean;
  template_id: string;
  user_id: string
  exporting: boolean;
}

export const initialState: ARPamphletState = {
  data: [],
  total: 0,
  page: 1,
  pageSize: 50,
  sort: 'shopName|asc',
  search: '',
  loading: false,
  error: false,
  deleted: false,
  template_id: '',
  user_id: '',
  exporting: false
};

const ListSlice = createSlice({
  name: 'arPamphlet',
  initialState,
  reducers: {
    resetState: () => initialState,
    fetchList(state: ARPamphletState) {
      state.loading = true;
    },
    listFetched(
      state: ARPamphletState,
      action: PayloadAction<ARPamphletListPageResult>
    ) {
      state.loading = false;
      state.error = false;
      state.data = action.payload.data;
      state.total = action.payload.totalItems;
    },
    listFetchedError(state: ARPamphletState) {
      state.loading = false;
      state.error = true;
    },
    setCurrentPage(state: ARPamphletState, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setSearch(state: ARPamphletState, action: PayloadAction<string>) {
      state.search = action.payload;
    },
    setSort(state: ARPamphletState, action: PayloadAction<string>) {
      state.sort = action.payload;
    },
    setTemplateId(state: ARPamphletState, action: PayloadAction<string>) {
      state.template_id = action.payload;
    },
    setUserId(state: ARPamphletState, action: PayloadAction<string>) {
      state.user_id = action.payload;
    },
    deleteItem(state: ARPamphletState, action: PayloadAction<number>) {
      state.loading = true;
      state.error = false;
      state.deleted = false;
    },
    deletedItem(state: ARPamphletState) {
      state.deleted = true;
    },
    useDeletedError(state: ARPamphletState) {
      state.loading = false;
      state.error = true;
    },
    resetDeleted(state: ARPamphletState) {
      state.deleted = false;
    },
    setPageSize(state: ARPamphletState, action: PayloadAction<number>) {
      state.pageSize = action.payload;
    },
    fetchTotalList(state: ARPamphletState) {
      state.loading = true;
    },
    exportUnscreenHistory(state: ARPamphletState) {
      state.exporting = true
    },
    unscreenHistoryExported(state: ARPamphletState) {
      state.exporting = false
    },
    unscreenHistoryExportedFail(state: ARPamphletState) {
      state.exporting = false
    },
    exportCSV(state: ARPamphletState) {
      state.exporting = true;
    },
    csvExported(state: ARPamphletState) {
      state.exporting = false;
    },
    setDataList(state: ARPamphletState, action: PayloadAction<ARProjectType[]>) {
      state.data = action.payload
    },
    updateStatus(state: ARPamphletState, action: PayloadAction<{id: number, status: boolean}>) {
      
    }
  }
});

export const { actions, reducer, name: sliceKey } = ListSlice;
