import { SnapType } from 'src/containers_v2/categories/snap/snap-list/store/slice';
import { SurveyType } from 'src/containers_v2/survey/store/model';
import IPagination from 'src/models/pagination';

export class FetchSnapsParams implements IPagination {
  page: number;
  pageSize: number;
  search: string; // snap name
  sort: string;

  constructor(
    page: number = 1,
    pageSize: number = 20,
    search: string = '',
    sort: string = 'name|asc',
    
  ) {
    this.page = page;
    this.pageSize = pageSize;
    this.search = search;
    this.sort = sort;
  }

  toString() {
    return `page=${this.page}&size=${this.pageSize}&name=${this.search}&sort=${this.sort}`;
  }
}

export type SnapsPageResult = {
  data: SnapType[];
  totalCount: number;
};
