import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store/configureStore';
import { ARProjectAuditListState } from './slice';

const selectDomain = (state: RootState) => {
  return state.arProjectAuditList;
};

export const selectARProjectAuditList = createSelector(
  [selectDomain],
  (arProjectAuditList: ARProjectAuditListState) => arProjectAuditList.data
);

export const selectPagination = createSelector(
  [selectDomain],
  (arProjectAuditList: ARProjectAuditListState) => ({
    page: arProjectAuditList.page,
    pageSize: arProjectAuditList.pageSize,
    search: arProjectAuditList.search,
    sort: arProjectAuditList.sort,
  })
);

export const selectSearch = createSelector(
  [selectDomain],
  (arProjectAuditList: ARProjectAuditListState) => arProjectAuditList.search
);

export const selectSort = createSelector(
  [selectDomain],
  (arProjectAuditList: ARProjectAuditListState) => arProjectAuditList.sort
);

export const selectPage = createSelector(
  [selectDomain],
  (arProjectAuditList: ARProjectAuditListState) => arProjectAuditList.page
);

export const selectPageSize = createSelector(
  [selectDomain],
  (arProjectAuditList: ARProjectAuditListState) => arProjectAuditList.pageSize
);

export const selectTotal = createSelector(
  [selectDomain],
  (arProjectAuditList: ARProjectAuditListState) => arProjectAuditList.total
)

export const selectLoading = createSelector(
  [selectDomain],
  (arProjectAuditList: ARProjectAuditListState) => arProjectAuditList.loading
)

export const selectDeleted = createSelector(
  [selectDomain],
  (arProjectAuditList: ARProjectAuditListState) => arProjectAuditList.deleted
)

export const selectUploadCredit = createSelector(
  [selectDomain],
  (arProjectAuditList: ARProjectAuditListState) => arProjectAuditList.uploadCredit
)

export const selectError = createSelector(
  [selectDomain],
  (arProjectAuditList: ARProjectAuditListState) => arProjectAuditList.error
)

export const selectExporting = createSelector(
  [selectDomain],
  (arProjectAuditList: ARProjectAuditListState) => arProjectAuditList.exporting
);