import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import snapApi from 'src/api_v2/snap';
import { FetchSnapsParams } from 'src/api_v2/snap/types';
import { selectQuery } from './selector';
import { snapActions } from './slice';

function* fetchSnaps() {
  try {
    const { search, page, pageSize, sort } = yield select(selectQuery);

    const queryParams = new FetchSnapsParams(page, pageSize, search, sort);
    const result = yield call(snapApi.fetchSnaps, queryParams);

    if (result && result.data) {
      yield put(snapActions.snapFetched(result));
    } else {
      yield put(snapActions.snapFetchedError());
    }
  } catch (err) {
    yield put(snapActions.snapFetchedError());
  }
}

function* fetchAllSnaps() {
  try {
    const queryParams = new FetchSnapsParams(
      1,
      Number.MAX_SAFE_INTEGER,
      '',
      'name|asc'
    );
    const result = yield call(snapApi.fetchSnaps, queryParams);

    if (result && result.data) {
      yield put(snapActions.snapFetched(result));
    } else {
      yield put(snapActions.snapFetchedError());
    }
  } catch (err) {
    yield put(snapActions.snapFetchedError());
  }
}

function* deleteSnap(action: PayloadAction<number>) {
  try {
    const result = yield call(snapApi.deleteSnap, action.payload);

    if (result) {
      yield put(snapActions.snapDeleted());
      yield call(fetchSnaps);
    } else {
      yield put(snapActions.snapDeletedError());
    }
  } catch (err) {
    yield put(snapActions.snapDeletedError());
  }
}

function* saveSnap(action: PayloadAction<string>) {
  try {
    const result = yield call(snapApi.saveSnap, {
      name: action.payload,
      id: 0
    });

    if (result) {
      yield put(snapActions.snapSaved());
      yield call(fetchSnaps);
    } else {
      yield put(snapActions.snapSavedError(''));
    }
  } catch (err) {
    if (err.response) {
      const message = err.response.data?.message as string[];
      console.log(message);

      yield put(snapActions.snapSavedError(message[0]));
    } else {
      yield put(snapActions.snapSavedError(''));
    }
  }
}

export default function* watchSnapListSaga() {
  yield takeLatest(snapActions.fetchSnaps.type, fetchSnaps);
  yield takeLatest(snapActions.fetchAllSnaps.type, fetchAllSnaps);
  yield takeLatest(snapActions.deleteSnap.type, deleteSnap);
  yield takeLatest(snapActions.saveSnap.type, saveSnap);
}
