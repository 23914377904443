import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { UserListPageResult } from 'src/api_v2/user-management/types';
import { GroupType } from 'src/containers_v2/ar_creator/group/group-list/store/slice';
import IPagination from 'src/models/pagination';

export enum USER_ROLE {
  ADMIN = 'admin',
  MIRROR_OWNER = 'mirrors-owner',
  AR_OWNER_NAMECARD = 'ar_owner_namecard',
  AR_ADMIN = 'ar_admin',
  USER_ADMIN = 'user-admin',
  MIRROR_VIEWER = 'mirror-viewer',
  AR_OWNER_PAMPHLET = 'ar_owner_pamphlet',
}
export type UserType = {
  id: number;
  email: string;
  uuid: string;
  password?: string;
  isActivated: boolean;
  role: string[];
  zipCode?: string;
  last_login_date?: string | null;
  group?: GroupType | null
};
export interface UserListState extends IPagination {
  total: number;
  data: UserType[];
  loading: boolean;
  error: boolean;
  role: string;
  activated: string;
  deleted: boolean;
  groupId: number | "all" | "empty"
}

export const initialState: UserListState = {
  data: [],
  total: 0,
  page: 1,
  pageSize: 50,
  sort: 'email|asc',
  search: '',
  role: 'all',
  activated: '',
  loading: false,
  error: false,
  deleted: false,
  groupId: "all"
};

const userListSlice = createSlice({
  name: 'userlist',
  initialState,
  reducers: {
    resetState: () => initialState,
    fetchUserList(state: UserListState) {
      state.loading = true;
    },
    fetchUserListCustom(state: UserListState) {
      state.loading = true;
    },
    userListFetched(
      state: UserListState,
      action: PayloadAction<UserListPageResult>
    ) {
      state.loading = false;
      state.error = false;
      state.data = action.payload.data;
      state.total = action.payload.totalItems;
    },
    userListFetchedError(state: UserListState) {
      state.loading = false;
      state.error = true;
    },
    setCurrentPage(state: UserListState, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setRole(state: UserListState, action: PayloadAction<string>) {
      state.role = action.payload;
    },
    setActivated(state: UserListState, action: PayloadAction<string>) {
      state.activated = action.payload;
    },
    setSearch(state: UserListState, action: PayloadAction<string>) {
      state.search = action.payload;
    },
    setSort(state: UserListState, action: PayloadAction<string>) {
      state.sort = action.payload;
    },
    deleteUser(state: UserListState, action: PayloadAction<number>) {
      state.loading = true;
      state.error = false;
      state.deleted = false;
    },
    userDeleted(state: UserListState) {
      state.deleted = true;
    },
    useDeletedError(state: UserListState) {
      state.loading = false;
      state.error = true;
    },
    resetDeleted(state: UserListState) {
      state.deleted = false;
    },
    setPageSize(state: UserListState, action: PayloadAction<number>) {
      state.pageSize = action.payload;
    },
    fetchTotalUserList(state: UserListState) {
      state.loading = true;
    },
    setGroupId(state: UserListState, action: PayloadAction<UserListState["groupId"]>) {
      state.groupId = action.payload;
    },
  }
});

export const { actions, reducer, name: sliceKey } = userListSlice;
