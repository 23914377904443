const exportCSV = (data, fileName: string) => {
  const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
  const url = window.URL.createObjectURL(
    new Blob([bom, data], { type: 'text/csv;charset=utf-8;' })
  );
  const link = document.createElement('a');
  link.setAttribute(
    'download',
    fileName
  );
  link.href = url;
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
  window.URL.revokeObjectURL(url);
};

export const helpers = {
  exportCSV
}
