import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store/configureStore';
import { ContentState } from './slice';

const selectDomain = (state: RootState) => {
  return state.content;
};

export const selectContentList = createSelector(
  [selectDomain],
  (state: ContentState) => state.data
);

export const selectLoading = createSelector(
  [selectDomain],
  (state: ContentState) => state.loading
);

export const selectDeleted = createSelector(
  [selectDomain],
  (state: ContentState) => state.deleted
);

export const selectSaved = createSelector(
  [selectDomain],
  (state: ContentState) => state.saved
);

export const selectQuery = createSelector(
  [selectDomain],
  (state: ContentState) => ({
    search: state.search,
    sort: state.sort,
    page: state.page,
    pageSize: state.pageSize,
    total: state.total,
    type: state.type
  })
);

export const selectError = createSelector(
  [selectDomain],
  (state: ContentState) => state.error
);

export const selectSearch = createSelector(
  [selectDomain],
  (state: ContentState) => state.search
);

export const selectFilters = createSelector(
  [selectDomain],
  (state: ContentState) => state.filters
);