import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { ARProjectAuditListPageResult } from 'src/api_v2/ar-project-audit/types';
import IPagination from 'src/models/pagination';

export enum arProjectAudit_ROLE {
  ADMIN = 'admin',
  MIRROR_OWNER = 'mirrors-owner',
  AR_OWNER = 'ar_owner',
  AR_ADMIN = 'ar_admin'
}
export type ARProjectAuditType = {
  id: number;
  description?: string;
  name?: string;
  action?: string;
  email?: string;
  templateName?: string;
  role?: string[];
  modifiedDate?: string;
  createdDate?: string;
  updatedBy?: {
    email?: string,
    id?: number
  };
};
export interface ARProjectAuditListState extends IPagination {
  total: number;
  data: ARProjectAuditType[];
  loading: boolean;
  error: string;
  deleted: boolean;
  uploadCredit?: number;
  exporting: boolean;
}

export const initialState: ARProjectAuditListState = {
  data: [],
  total: 0,
  page: 1,
  pageSize: 50,
  sort: 'modifiedDate|desc',
  search: '',
  loading: false,
  error: '',
  deleted: false,
  uploadCredit : 0,
  exporting: false
};

const ARProjectAuditListSlice = createSlice({
  name: 'arProjectAuditlist',
  initialState,
  reducers: {
    resetState: () => initialState,
    fetchARProjectAuditList(state: ARProjectAuditListState) {
      state.loading = true;
    },
    arProjectAuditListFetched(
      state: ARProjectAuditListState,
      action: PayloadAction<ARProjectAuditListPageResult>
    ) {
      state.loading = false;
      state.error = '';
      state.data = action.payload.data;
      state.total = action.payload.total;
    },
    arProjectAuditListFetchedError(state: ARProjectAuditListState) {
      state.loading = false;
      state.error = 'Fail to fetch arProjectAudit list';
    },
    setCurrentPage(state: ARProjectAuditListState, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setSearch(state: ARProjectAuditListState, action: PayloadAction<string>) {
      state.search = action.payload;
    },
    setSort(state: ARProjectAuditListState, action: PayloadAction<string>) {
      state.sort = action.payload;
    },
    deleteARProjectAudit(state: ARProjectAuditListState, action: PayloadAction<number>) {
      state.loading = true;
      state.error = '';
      state.deleted = false;
    },
    arProjectAuditDeleted(state: ARProjectAuditListState) {
      state.loading = false;
      state.deleted = true;
    },
    arProjectAuditDeletedError(state: ARProjectAuditListState, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    resetDeleted(state: ARProjectAuditListState) {
      state.deleted = false;
    },
    setPageSize(state: ARProjectAuditListState, action: PayloadAction<number>) {
      state.pageSize = action.payload;
    },
    fetchTotalARProjectAuditList(state: ARProjectAuditListState) {
      state.loading = true;
    },
    fetchRemainingCredit(state: ARProjectAuditListState, action: PayloadAction<number>) {
      state.loading = true;
    },
    fetchedRemainingCredit(
      state: ARProjectAuditListState,
      action: PayloadAction<number>
    ) {
      state.loading = false;
      state.uploadCredit = action.payload || 0;
    },
    fetchedRemainingCreditError(state: ARProjectAuditListState) {
      state.loading = false;
    },
    resetError(state: ARProjectAuditListState) {
      state.error = '';
    },
    exportFile(state: ARProjectAuditListState) {
      state.exporting = true
    },
    unscreenHistoryExported(state: ARProjectAuditListState) {
      state.exporting = false
    },
    unscreenHistoryExportedFail(state: ARProjectAuditListState) {
      state.exporting = false
    }
  }
});

export const { actions, reducer, name: sliceKey } = ARProjectAuditListSlice;
