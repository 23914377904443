import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { ProfileListPageResult } from 'src/api_v2/profile/types';
import IPagination from 'src/models/pagination';

export type ProfileType = {
  id: number;
  name: string;
  inputType: string;
  owner: string;
  mirrorType: string;
  assigned?: {
    mirrors: number
    voiceovers: number
    videos: number
  };
};

export type ProfileFiltersType = {
  mirrorTypes: {id: number, type: string}[],
  inputType: {id: number, input: string}[],
  owners: {id: number, name: string}[],
  profileNames: string[]
}

export interface ProfileListState extends IPagination {
  total: number;
  data: ProfileType[];
  loading: boolean;
  error: string;
  deleted: boolean;
  inputTypeFilter: string,
  mirrorTypeFilter: string,
  ownerFilter: string,
  filters: ProfileFiltersType
}

export const initialState: ProfileListState = {
  data: [],
  total: 0,
  page: 1,
  pageSize: 50,
  sort: 'name|asc',
  search: '',
  loading: false,
  error: '',
  deleted: false,
  inputTypeFilter: ' ',
  mirrorTypeFilter: ' ',
  ownerFilter: '',
  filters: {
    mirrorTypes: [],
    inputType: [],
    owners: [],
    profileNames: []
  }
};

const profileListSlice = createSlice({
  name: 'profilelist',
  initialState,
  reducers: {
    resetState: () => initialState,
    setCurrentPage(state: ProfileListState, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setSearch(state: ProfileListState, action: PayloadAction<string>) {
      state.search = action.payload
    },
    setSort(state: ProfileListState, action: PayloadAction<string>) {
      state.sort = action.payload
    },
    setPageSize(state: ProfileListState, action: PayloadAction<number>) {
      state.pageSize = action.payload
    },
    setMirrorTypeFilter(state: ProfileListState, action: PayloadAction<string>) {
      state.mirrorTypeFilter = action.payload
    },
    setInputTypeFilter(state: ProfileListState, action: PayloadAction<string>) {
      state.inputTypeFilter = action.payload
    },
    setOwnerFilter(state: ProfileListState, action: PayloadAction<string>) {
      state.ownerFilter = action.payload
    },
    fetchProfileList(state: ProfileListState) {
      state.loading = true;
    },
    profileListFetched(state: ProfileListState, action: PayloadAction<ProfileListPageResult>) {
      state.loading = false;
      state.error = '';
      state.data = action.payload.data;
      state.total = action.payload.totalCount;
    },
    profileListFetchedError(state: ProfileListState) {
      state.loading = false;
      state.error = 'Fail to fetch profile list';
    },
    fetchProfileFilters(state: ProfileListState) {
      state.loading = true;
    },
    filtersFetched(state: ProfileListState, action: PayloadAction<ProfileFiltersType>) {
      state.filters = action.payload
      state.loading = false;
    },
    deleteProfile(state: ProfileListState, action: PayloadAction<string| number>) {
      state.loading = true
    },
    profileDeleted(state: ProfileListState) {
      state.deleted = true
      state.loading = false
    },
    profileDeletedError(state: ProfileListState, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload
    },
    setDeleted(state: ProfileListState, action: PayloadAction<boolean>) {
      state.deleted = action.payload
    },
    resetError(state: ProfileListState) {
      state.error = ''
    }
  }
});

export default profileListSlice.reducer
export const profileActions = profileListSlice.actions
