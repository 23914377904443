import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import appConfig from '../config';

export class BaseSurveyApi {
  axios: AxiosInstance;

  constructor(config?: AxiosRequestConfig) {
    const baseURL = appConfig.DefaultApi;
    this.axios = axios.create({
      ...config,
      baseURL
    });
  }
}

export class BasePeopleCounterApi {
  axios: AxiosInstance;

  constructor(config?: AxiosRequestConfig) {
    const baseURL = appConfig.MirrorApi;
    this.axios = axios.create({
      ...config,
      baseURL
    });
  }
}
