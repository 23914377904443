import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        height: '100%',
        width: '100%'
      },
      '*, *::before, *::after': {
        boxSizing: 'inherit'
      },
      body: {
        height: '100%',
        width: '100%'
      },
      '#root': {
        height: '100%',
        width: '100%'
      },
      /* Chrome, Safari, Edge, Opera */
      'input::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
      },
      'input::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
      },

      /* Firefox */
      'input[type=number]': {
        '-moz-appearance': 'textfield'
      }
    }
  })
);

const GlobalStyles = () => {
  useStyles();

  return null;
};

export default GlobalStyles;
