import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store/configureStore';
import { MirrorTypeListState } from './slice';

const selectDomain = (state: RootState) => {
  return state.mirrorTypeList;
};

export const selectMirrorTypeList = createSelector(
  [selectDomain],
  (state: MirrorTypeListState) => state.data
);

export const selectLoading = createSelector(
  [selectDomain],
  (state: MirrorTypeListState) => state.loading
);

export const selectDeleted = createSelector(
  [selectDomain],
  (state: MirrorTypeListState) => state.deleted
);

export const selectSearch = createSelector(
  [selectDomain],
  (state: MirrorTypeListState) => state.search
);

export const selectQuery = createSelector(
  [selectDomain],
  (state: MirrorTypeListState) => ({
    search: state.search,
    sort: state.sort,
    page: state.page,
    pageSize: state.pageSize,
    total: state.total
  })
);