import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "src/redux/store/configureStore";
import { MetaverseState } from "./slice";

const selectDomain = (state: RootState) => {
  return state.metaverse;
};

export const selectMetaverseImage = createSelector(
  [selectDomain],
  (state: MetaverseState) => state.mediaUrl
);