
export type ARScanStatictis = {
  id: number
  arProjectId: number
  hashedUUID: string
  aflacInternalId?: string
  country: string;
  city: string;
  os: string;
  date: Date
  device: {
    type: string;
    model: string;
    vendor: string
  }
  shopName: string
}

export enum ARProjectType {
  NAMECARD = 0,
  PAMPHLET = 1,
}