import { Box, CircularProgress } from '@mui/material';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import apiArproject from 'src/api_v2/ar-project/ar-project-list-api';
import { selectAuthUser } from 'src/containers_v2/auth/store/selector';

function QRcodeDynamic() {
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get('projectId');
  const authUser = useSelector(selectAuthUser);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        const link = await apiArproject.getQRcodeDynamic(projectId);
        if (link) {
          window.location.href = link;
        }
      } catch (err) {
        if (
          err &&
          err?.response &&
          err?.response?.data?.message?.[0]?.message?.includes('not found')
        ) {
          navigate('/8thwall/404');
          return;
        }

        if (err?.response?.data?.message?.[0]?.message) {
          window.location.href = window.location.origin;
          return;
        }

        if (
          err &&
          err?.response &&
          err?.response?.data?.message?.[0]?.includes('Expired')
        ) {
          // redirect to expired qr code
          if (authUser?.email) {
            navigate('/reports/expired-qrcode-login');
            return;
          }
          navigate('/expired-qrcode');
        }
      }
    })();
  }, [projectId]);
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          width: '100vw',
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <CircularProgress size={'15rem'} />
      </Box>
    </>
  );
}

export default QRcodeDynamic;
