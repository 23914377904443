import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import {
  FiltersResult,
  SurveyReportPageResult
} from 'src/api_v2/survey-report/types';
import IPagination from 'src/models/pagination';
import moment from 'moment';
import { SurveyType } from './model';

export const questions = [
  'ミラーの感想を一言で！',
  'アフラックのイメージは向上しましたか？',
  'がんの学び動画を観て、がんの備えに関心を持ちましたか？'
];

export interface SurveyReportState extends IPagination {
  total: number;
  data: SurveyType[];
  mirrorTypeFilter: string;
  contentFilter: string;
  shopNameFilter: string;
  from: number;
  to: number;
  loading: boolean;
  error: boolean;
  contents: { [key: string]: string };
  mirrorTypes: string[];
  genderFilter: string;
  exporting: boolean;
  mirrorIds: string[];
  shopNames: string[];
  selectedMirrors: string[]
}

export const initialState: SurveyReportState = {
  data: [],
  total: 0,
  page: 1,
  pageSize: 20,
  sort: 'datetime|desc',
  search: '',
  loading: false,
  error: false,
  mirrorTypeFilter: '',
  contentFilter: '',
  shopNameFilter: '',
  from: moment().subtract(30, 'days').startOf('day').utc().valueOf(),
  to: moment().endOf('day').utc().valueOf(),
  contents: {},
  mirrorTypes: [],
  genderFilter: '',
  exporting: false,
  mirrorIds: [],
  shopNames: [],
  selectedMirrors: []
};

const surveyReportSlice = createSlice({
  name: 'surveyReportV2',
  initialState,
  reducers: {
    resetState: (state: SurveyReportState) => ({
      ...initialState,
      mirrorTypes: state.mirrorTypes,
      contents: state.contents
    }),
    fetchSurveyReport(state: SurveyReportState) {
      state.loading = true;
    },
    fetchTotalSurveyReport(state: SurveyReportState) {
      state.loading = true;
    },
    surveyFetched(
      state: SurveyReportState,
      action: PayloadAction<SurveyReportPageResult>
    ) {
      state.loading = false;
      state.error = false;
      state.data = action.payload.data;
      state.total = action.payload.totalCount;
    },
    surveyFetchedError(state: SurveyReportState) {
      state.loading = false;
      state.error = true;
    },
    fetchFilters(state: SurveyReportState) {},
    filtersFetched(
      state: SurveyReportState,
      action: PayloadAction<FiltersResult>
    ) {
      state.contents = action.payload.mirrorContent;
      state.mirrorTypes = action.payload.mirrorTypes;
      state.mirrorIds = action.payload.mirrorIds;
      state.shopNames = action.payload.shopNames;
    },
    filtersFetchedError(state: SurveyReportState) {
      state.error = true;
    },
    setSort(state: SurveyReportState, action: PayloadAction<string>) {
      state.sort = action.payload;
    },
    setSearch(state: SurveyReportState, action: PayloadAction<string>) {
      state.search = action.payload;
    },
    setPageSize(state: SurveyReportState, action: PayloadAction<number>) {
      state.pageSize = action.payload;
    },
    setCurrentPage(state: SurveyReportState, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setMirrorTypeFilter(
      state: SurveyReportState,
      action: PayloadAction<string>
    ) {
      state.mirrorTypeFilter = action.payload;
    },
    setContentFilter(state: SurveyReportState, action: PayloadAction<string>) {
      state.contentFilter = action.payload;
    },
    setGenderFilter(state: SurveyReportState, action: PayloadAction<string>) {
      state.genderFilter = action.payload;
    },
    setFrom(state: SurveyReportState, action: PayloadAction<number>) {
      state.from = action.payload;
      if (state.to < action.payload) {
        state.to = moment(action.payload)
          .add(1, 'days')
          .endOf('days')
          .utc()
          .valueOf();
      }
    },
    setTo(state: SurveyReportState, action: PayloadAction<number>) {
      state.to = action.payload;
    },
    setFullPageSize(state: SurveyReportState) {
      state.page = 1;
      state.pageSize = state.total;
    },
    exportCSV(state: SurveyReportState) {
      state.exporting = true;
    },
    csvExported(state: SurveyReportState) {
      state.exporting = false;
    },
    setShopNameFilter(state: SurveyReportState, action: PayloadAction<string>) {
      state.shopNameFilter = action.payload
    },
    setSelectedMirrors(state: SurveyReportState, action: PayloadAction<string[]>) {
      state.selectedMirrors = action.payload
    }
  }
});

export default surveyReportSlice.reducer;
export const surveyActions = surveyReportSlice.actions;
