import { createSlice } from "@reduxjs/toolkit";
import { AuthResponse, UserResponse } from "../../../api/mirrors-console-api";

export interface UserState {
    isAuth: boolean,
    account: UserResponse,
    sketchfab: any
}

const INITIAL_STATE = {
    account: {} as UserResponse,
    isAuth: false,
    sketchfab: null
};

const userSlice = createSlice({
    name: "user",
    initialState: INITIAL_STATE,
    reducers: {
        setAuth: (state, action) => {
            state.isAuth = action.payload.isAuth;
        },
        setUser: (state, action) => {
            state.account = action.payload;
        },
        setUserSketchfab: (state, action) => {
            state.sketchfab = action.payload;
        },
    }
})

export default userSlice.reducer;
export const { setUser, setAuth, setUserSketchfab } = userSlice.actions;
