import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store/configureStore';
import { ARPamphletState } from './slice';

const selectDomain = (state: RootState) => {
  return state.arPamphlet;
};

export const selectList = createSelector(
  [selectDomain],
  (arPamphlet: ARPamphletState) => arPamphlet.data
);

export const selectPagination = createSelector(
  [selectDomain],
  (arPamphlet: ARPamphletState) => ({
    page: arPamphlet.page,
    pageSize: arPamphlet.pageSize,
    search: arPamphlet.search,
    sort: arPamphlet.sort
  })
);

export const selectQuery = createSelector(
  [selectDomain],
  (arPamphlet: ARPamphletState) => ({
    page: arPamphlet.page,
    pageSize: arPamphlet.pageSize,
    search: arPamphlet.search,
    sort: arPamphlet.sort,
    template_id: arPamphlet.template_id,
    user_id: arPamphlet.user_id
  })
);

export const selectTotal = createSelector(
  [selectDomain],
  (arPamphlet: ARPamphletState) => arPamphlet.total
);

export const selectLoading = createSelector(
  [selectDomain],
  (arPamphlet: ARPamphletState) => arPamphlet.loading
);

export const selectDeleted = createSelector(
  [selectDomain],
  (arPamphlet: ARPamphletState) => arPamphlet.deleted
);

export const selectTemplateId = createSelector(
  [selectDomain],
  (arPamphlet: ARPamphletState) => arPamphlet.template_id
);

export const selectUserId = createSelector(
  [selectDomain],
  (arPamphlet: ARPamphletState) => arPamphlet.user_id
);

export const selectExporting = createSelector(
  [selectDomain],
  (arPamphlet: ARPamphletState) => arPamphlet.exporting
);
