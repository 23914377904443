import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import profileApi from 'src/api_v2/profile';
import {
  FetchProfileListParams,
  ProfileListPageResult
} from 'src/api_v2/profile/types';
import { selectQuery } from './selector';
import { profileActions } from './slice';

function* fetchProfileList() {
  try {
    const {
      search,
      mirrorTypeFilter,
      inputTypeFilter,
      ownerFilter,
      page,
      pageSize,
      sort
    } = yield select(selectQuery);

    const queryParams = new FetchProfileListParams(
      page,
      pageSize,
      search,
      sort,
      mirrorTypeFilter,
      inputTypeFilter,
      ownerFilter
    );
    const result: ProfileListPageResult = yield call(
      profileApi.fetchProfiles,
      queryParams
    );

    yield put(profileActions.profileListFetched(result));
  } catch (err) {
    console.log(err);
    yield put(profileActions.profileListFetchedError());
  }
}

function* fetchProfileFilters() {
  try {
    const result = yield call(profileApi.fetchProfileFilters);

    if (result) {
      yield put(profileActions.filtersFetched(result));
    }
  } catch (err) {
    console.log(err);
  }
}

function* deleteProfile(action: PayloadAction<string | number>) {
  try {
    const result = yield call(profileApi.deleteProfile, action.payload);

    if (result) {
      yield put(profileActions.profileDeleted());
      yield call(fetchProfileList);
    } else {
      yield put(profileActions.profileDeletedError('Fail to delete profile'));
    }
  } catch (err) {
    if (err.response) {
      const message = err.response.data?.message as string[];
      yield put(profileActions.profileDeletedError(message[0]));
    }
  }
}

export default function* watchProfileListSaga() {
  yield takeLatest(profileActions.fetchProfileList.type, fetchProfileList);
  yield takeLatest(
    profileActions.fetchProfileFilters.type,
    fetchProfileFilters
  );
  yield takeLatest(profileActions.deleteProfile.type, deleteProfile);
}
