import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { ContentsPageResult } from 'src/api_v2/content/types';
import IPagination from 'src/models/pagination';

export type LED_COLOR = 'green' | 'yellow' | 'red' | '';
export type CONTENT_TYPE = 'spa' | 'electron' | 'unity' | '';

export type ContentsFiltersType = {
  types?: string[];
};

export type ContentType = {
  id: number;
  name: string;
  descriptionEng?: string;
  descriptionJpn?: string;
  descriptionVi?: string;
  gameNameEng?: string;
  gameNameJpn?: string;
  gameNameVi?: string;
  fileLogoName?: string;
  fileLogoLink?: string;
  ledColor?: LED_COLOR;
  type?: string[];
  edge?: string;
  beta?: string;
  candidate?: string;
  stable?: string;
};

export interface ContentState extends IPagination {
  total: number;
  data: ContentType[];
  error: string;
  loading: boolean;
  deleted: boolean;
  saved: boolean;
  type: string;
  filters?: ContentsFiltersType;
}

export const initialState: ContentState = {
  data: [],
  error: '',
  loading: false,
  deleted: false,
  saved: false,
  total: 0,
  page: 1,
  pageSize: 50,
  sort: 'name|asc',
  search: '',
  filters: {
    types: []
  },
  type: ''
};

const contentSlice = createSlice({
  name: 'content',
  initialState,
  reducers: {
    resetState: () => initialState,
    fetchContents(state: ContentState) {
      state.loading = true;
      state.error = '';
    },
    fetchAllContents(state: ContentState) {
      state.loading = true;
      state.error = '';
    },
    contentFetched(
      state: ContentState,
      action: PayloadAction<ContentsPageResult>
    ) {
      state.data = action.payload.data;
      state.total = action.payload.totalItems;
      state.loading = false;
    },
    contentFetchedError(state: ContentState) {
      state.loading = false;
      state.error = 'Fail to fetch content!';
    },
    deleteContent(state: ContentState, action: PayloadAction<number>) {
      state.loading = true;
      state.deleted = false;
      state.error = '';
    },
    contentDeleted(state: ContentState) {
      state.loading = false;
      state.deleted = true;
    },
    contentDeletedError(state: ContentState) {
      state.loading = false;
      state.error = 'Fail to delete content!';
    },
    saveContent(state: ContentState, action: PayloadAction<string>) {
      state.loading = true;
      state.error = '';
    },
    contentSaved(state: ContentState) {
      state.loading = false;
      state.saved = true;
    },
    resetSaved(state: ContentState) {
      state.saved = false;
    },
    contentSavedError(state: ContentState, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    setCurrentPage(state: ContentState, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setSearch(state: ContentState, action: PayloadAction<string>) {
      state.search = action.payload;
    },
    setSort(state: ContentState, action: PayloadAction<string>) {
      state.sort = action.payload;
    },
    setPageSize(state: ContentState, action: PayloadAction<number>) {
      state.pageSize = action.payload;
    },
    setType(state: ContentState, action: PayloadAction<string>) {
      state.type = action.payload;
    },
    fetchContentFilters(state: ContentState) {
      state.loading = true;
    },
    filtersFetched(
      state: ContentState,
      action: PayloadAction<ContentsFiltersType>
    ) {
      state.filters = action.payload;
      state.loading = false;
    }
  }
});

export default contentSlice.reducer;
export const contentActions = contentSlice.actions;
