import { AxiosResponse } from 'axios';
import agent from 'src/api_v2/base';
import {
  FetchSurveyReportParams,
  FiltersResult,
  SurveyReportPageResult
} from './types';
import momemt from 'moment'
import { helpers } from 'src/utils/helpers';

class SurveyReportApi {
  async fetchSurveyReport(params: FetchSurveyReportParams) {
    try {
      const response: AxiosResponse = await agent.get(
        `/surveys?${params.toString()}`
      );

      if (response.status === 200) {
        return response.data as SurveyReportPageResult;
      }

      return [];
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async fetchFilters() {
    try {
      const response: AxiosResponse = await agent.get(`/surveys/filters`);

      if (response.status === 200) {
        return response.data as FiltersResult;
      }

      return undefined;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async exportCSV(params: FetchSurveyReportParams) {
    try {
      const response = await agent.get(
        `/surveys/export-csv?${params.toString()}`,
        {
          responseType: 'blob'
        }
      );

      if (response.status === 200) {
        helpers.exportCSV(
          response.data,
          `survey-${momemt().format("YYYY-MM-DD, HH-mm")}.csv`
        );

        return true;
      }
      return false;
    } catch (err) {
      throw err;
    }
  }
}

const surveyApi = new SurveyReportApi();
export default surveyApi;
