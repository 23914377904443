import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  FetchUserListParams,
  UserListPageResult
} from 'src/api_v2/user-management/types';
import userApi from 'src/api_v2/user-management/user-management-api';
import {
  selectActivated,
  selectGroupId,
  selectPage,
  selectPageSize,
  selectRole,
  selectSearch,
  selectSort
} from './selector';
import { actions } from './slice';

function* fetchUserList() {
  try {
    const page = yield select(selectPage);
    const pageSize = yield select(selectPageSize);
    const search = yield select(selectSearch);
    const sort = yield select(selectSort);
    const activated = yield select(selectActivated);
    const role = yield select(selectRole);
    const groupId = yield select(selectGroupId);
    const queryParams = new FetchUserListParams(
      page,
      pageSize,
      search,
      sort,
      role === "all" ? '' : role,
      activated,
      groupId
    );
    const result: UserListPageResult = yield call(
      userApi.fetchUserList,
      queryParams
    );

    yield put(actions.userListFetched(result));
  } catch (err) {
    yield put(actions.userListFetchedError());
  }
}

function* fetchUserListCustom() {
  try {
    const page = yield select(selectPage);
    const pageSize = yield select(selectPageSize);
    const search = yield select(selectSearch);
    const sort = yield select(selectSort);
    const activated = yield select(selectActivated);
    const role = yield select(selectRole);
    const groupId = yield select(selectGroupId);
    const queryParams = new FetchUserListParams(
      page,
      pageSize,
      search,
      sort,
      role === "all" ? '' : role,
      activated,
      groupId
    );
    const result: UserListPageResult = yield call(
      userApi.fetchUserListCustom,
      queryParams
    );

    yield put(actions.userListFetched(result));
  } catch (err) {
    yield put(actions.userListFetchedError());
  }
}

function* deleteUser(action: PayloadAction<number>) {
  try {
    const userId = action.payload;
    if (userId) {
      yield call(userApi.deleteUser, userId);

      yield put(actions.userDeleted());
      yield call(fetchUserList)
    }
  } catch (err) {
    yield put(actions.useDeletedError());
  }
}



function* fetchTotalUserList() {
  try {
    const search = yield select(selectSearch);
    const sort = yield select(selectSort);

    const queryParams = new FetchUserListParams(
      1,
      Number.MAX_SAFE_INTEGER,
      search,
      sort,
    );
    const result: UserListPageResult = yield call(
      userApi.fetchUserList,
      queryParams
    );

    yield put(actions.userListFetched(result));
  } catch (err) {
    yield put(actions.userListFetchedError());
  }
}

export default function* watchUserListSaga() {
  yield takeLatest(actions.fetchUserList.type, fetchUserList);
  yield takeLatest(actions.fetchUserListCustom.type, fetchUserListCustom);
  yield takeLatest(actions.deleteUser.type, deleteUser);
  yield takeLatest(actions.fetchTotalUserList.type, fetchTotalUserList);
}
