import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import videosApi from 'src/api_v2/videos';
import { actions, VideosConfigurationType } from './slice';

function* saveVideos(action: PayloadAction<VideosConfigurationType>) {
  
}

function* getVideos(action: PayloadAction<string>) {
  try {
    const result = yield call(videosApi.getVideos, action.payload);

    if (result) {
      yield put(
        actions.videosGot({
          ...result,
          confirmPassword: result.password
        })
      );
    } else {
    }
  } catch (err) {
    yield put(actions.videosGotError('Fail to get videos.'));
  }
}

export default function* watchVideosConfigurationSaga() {
  yield takeLatest(actions.saveVideos.type, saveVideos);
  yield takeLatest(actions.getVideos.type, getVideos);
}
