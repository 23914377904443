import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store/configureStore';
import { ProfileAuditListState } from './slice';

const selectDomain = (state: RootState) => {
  return state.profileAuditList;
};

export const selectProfileAuditList = createSelector(
  [selectDomain],
  (profileAuditList: ProfileAuditListState) => profileAuditList.data
);

export const selectPagination = createSelector(
  [selectDomain],
  (profileAuditList: ProfileAuditListState) => ({
    page: profileAuditList.page,
    pageSize: profileAuditList.pageSize,
    search: profileAuditList.search,
    sort: profileAuditList.sort,
  })
);

export const selectSearch = createSelector(
  [selectDomain],
  (profileAuditList: ProfileAuditListState) => profileAuditList.search
);

export const selectSort = createSelector(
  [selectDomain],
  (profileAuditList: ProfileAuditListState) => profileAuditList.sort
);

export const selectPage = createSelector(
  [selectDomain],
  (profileAuditList: ProfileAuditListState) => profileAuditList.page
);

export const selectPageSize = createSelector(
  [selectDomain],
  (profileAuditList: ProfileAuditListState) => profileAuditList.pageSize
);

export const selectTotal = createSelector(
  [selectDomain],
  (profileAuditList: ProfileAuditListState) => profileAuditList.total
)

export const selectLoading = createSelector(
  [selectDomain],
  (profileAuditList: ProfileAuditListState) => profileAuditList.loading
)

export const selectDeleted = createSelector(
  [selectDomain],
  (profileAuditList: ProfileAuditListState) => profileAuditList.deleted
)

export const selectUploadCredit = createSelector(
  [selectDomain],
  (profileAuditList: ProfileAuditListState) => profileAuditList.uploadCredit
)

export const selectError = createSelector(
  [selectDomain],
  (profileAuditList: ProfileAuditListState) => profileAuditList.error
)

export const selectExporting = createSelector(
  [selectDomain],
  (profileAuditList: ProfileAuditListState) => profileAuditList.exporting
);