import { UserType } from "src/containers_v2/user-management/user-list/store/slice";
import IPagination from "src/models/pagination";

export class FetchUserListParams implements IPagination {
  page: number;
  pageSize: number;
  search: string;
  sort: string;
  role: string[];
  activated: string;
  groupId: string;

  constructor(
    page: number = 1,
    pageSize: number = 10,
    search: string = '',
    sort: string = 'email|asc',
    role: string[] = [],
    activated: string = '',
    groupId: string = '',
  ) {
    this.page = page;
    this.pageSize = pageSize;
    this.search = search;
    this.sort = sort;
    this.role = role;
    this.activated = activated;
    this.groupId = groupId;
  }

  toString() {
    return `page=${this.page}&size=${this.pageSize}&search=${this.search}&sort=${this.sort}&role=${this.role}&status=${this.activated}&groupId=${this.groupId}`;
  }
}

export type UserListPageResult = {
  data: UserType[],
  totalItems: number
}
