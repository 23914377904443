import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import IPagination from 'src/models/pagination';
import moment from 'moment';
import { SummaryType } from './model';
import { SummaryReportPageResult, FiltersResult, FiltersPeopleCounterResult } from 'src/api_v2/people-counter/types';

export interface SummaryState extends IPagination {
  total: number;
  data: SummaryType[];
  shopNames: string[];
  mirrorTypeFilter: string;
  contentFilter: string;
  from: number;
  to: number;
  loading: boolean;
  error: boolean;
  contents: { [key: string]: string };
  mirrorTypes: string[];
  mirrorIds: string[];
  exporting: boolean;
  shopNameFilter: string
}

export const initialState: SummaryState = {
  data: [],
  total: 0,
  shopNames: [],
  page: 1,
  pageSize: 50,
  sort: 'datetime|desc',
  search: '',
  loading: false,
  error: false,
  mirrorTypeFilter: '',
  contentFilter: '',
  from: moment().subtract(1, 'days').startOf('day').utc().valueOf(),
  to: moment().endOf('day').utc().valueOf(),
  contents: {},
  mirrorTypes: [],
  mirrorIds: [],
  exporting: false,
  shopNameFilter: ''
};

const summarySlice = createSlice({
  name: 'summaryV2',
  initialState,
  reducers: {
    resetState: (state: SummaryState) => ({
      ...initialState,
      mirrorTypes: state.mirrorTypes,
      contents: state.contents
    }),
    fetchSummary(state: SummaryState) {
      state.loading = true;
    },
    fetchTotalSummary(state: SummaryState) {
      state.loading = true;
    },
    summaryFetched(
      state: SummaryState,
      action: PayloadAction<SummaryReportPageResult>
    ) {
      state.loading = false;
      state.error = false;
      state.data = action.payload.data;
      state.total = action.payload.totalCount;
    },
    summaryFetchedError(state: SummaryState) {
      state.loading = false;
      state.error = true;
    },
    fetchFilters(state: SummaryState) { },
    filtersFetched(
      state: SummaryState,
      action: PayloadAction<FiltersResult>
    ) {
      state.contents = action.payload.mirrorContent;
      state.mirrorTypes = action.payload.mirrorTypes;
      state.mirrorIds = action.payload.mirrorIds;
      state.shopNames = action.payload.shopNames;
    },
    fetchFiltersError(state: SummaryState) {
      state.error = true;
    },
    setSort(state: SummaryState, action: PayloadAction<string>) {
      state.sort = action.payload;
    },
    setSearch(state: SummaryState, action: PayloadAction<string>) {
      state.search = action.payload;
    },
    setPageSize(state: SummaryState, action: PayloadAction<number>) {
      state.pageSize = action.payload;
    },
    setCurrentPage(state: SummaryState, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setMirrorTypeFilter(
      state: SummaryState,
      action: PayloadAction<string>
    ) {
      state.mirrorTypeFilter = action.payload;
    },
    setContentFilter(state: SummaryState, action: PayloadAction<string>) {
      state.contentFilter = action.payload;
    },
    setFrom(state: SummaryState, action: PayloadAction<number>) {
      state.from = action.payload;
      if (state.to < action.payload) {
        state.to = moment(action.payload)
          .add(1, 'days')
          .endOf('days')
          .utc()
          .valueOf();
      }
    },
    setTo(state: SummaryState, action: PayloadAction<number>) {
      state.to = action.payload;
    },
    setFullPageSize(state: SummaryState) {
      state.page = 1;
      state.pageSize = state.total;
    },
    exportCSV(state: SummaryState) {
      state.exporting = true;
    },
    csvExported(state: SummaryState) {
      state.exporting = false;
    },
    setShopNameFilter(state: SummaryState, action: PayloadAction<string>) {
      state.shopNameFilter = action.payload
    },
  }
});

export default summarySlice.reducer;
export const summaryActions = summarySlice.actions;
