import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { FiltersPeopleCounterResult } from 'src/api_v2/people-counter/types';

export interface PeopleCounterRawData {
  entry_date?: number;
  total?: string;
  age_1?: string;
  age_2?: string;
  age_3?: string;
  age_4?: string;
  age_5?: string;
  age_na?: string;
  gender_male?: string;
  gender_famale?: string;
  gender_na?: string;
  emotion_surprise?: string;
  emotion_angry?: string;
  emotion_happy?: string;
  emotion_disgusty?: string;
  emotion_fear?: string;
  emotion_sad?: string;
  emotion_neutral?: string;
  emotion_na?: string;
  entry_time?: number;
  exit_time?: number;
  persons_count?: number;
  date?: string;
  entry_hour?: number;
}

export interface PeopleNeutralVideoTrackingRawData {
  mirror_id: string;
  datetime_utc_milis: number;
  person_id: number;
  person_in_roi_duration: number;
  video_name: string;
}
export interface PeopleCounterData {
  name: string;
  persons_count: number;
  date: string;
  ageList: any;
  emotionList: any;
  genderList: any;
}

export interface UserMirror {
  id: number;
  name: string
}

export enum DATE_RANGE {
  TODAY,
  SEVEN_DAYS_AGO,
  THIRDTY_DAYS_AGO,
  SEVEN_DAYS_AGO_TIME,
  THIRDTY_DAYS_AGO_TIME,
  CUSTOM,
  CUSTOM_DAY
}

export enum COUNTER_TYPE {
  PEOPLE,
  VOICEOVER
}

export interface PeopleCounterReportState {
  counterRawData: PeopleCounterRawData[];
  shopNames?: string[];
  shopNameFilter?: string;
  videoTrackingRawData: PeopleNeutralVideoTrackingRawData[];
  mirrorIds: number[];
  dateRange: DATE_RANGE;
  customDate: {
    from: string;
    to: string;
  };
  loading: boolean;
  error: boolean;
  counterType: COUNTER_TYPE;
  groupByHour: boolean;
  total: number;
  exporting?: boolean;
  countByTime?: boolean;
  mirrors?: UserMirror[]
  lastUpdated: string
}
export interface InterfaceParamsFetchPeopleCounter {
  groupByHour: boolean;
  countByTime: boolean;
}

export const initialState: any = {
  counterRawData: [],
  shopNames: [],
  videoTrackingRawData: [],
  mirrorId: '',
  dateRange: DATE_RANGE.TODAY,
  loading: false,
  error: false,
  shopNameFilter: '',
  customDate: {
    from: moment().clone().startOf('day').format('YYYY-MM-DD'),
    to: moment().clone().endOf('day').format('YYYY-MM-DD')
  },
  counterType: COUNTER_TYPE.PEOPLE,
  groupByHour: false,
  exporting: false,
  countByTime: false,
  mirrors: [],
  lastUpdated: ''
};

const peopleCounterReportSlice = createSlice({
  name: 'peopleCounterReport',
  initialState,
  reducers: {
    resetState: () => initialState,
    setDateRange(
      state: PeopleCounterReportState,
      action: PayloadAction<DATE_RANGE>
    ) {
      state.dateRange = action.payload;
    },
    setCustomDate(
      state: PeopleCounterReportState,
      action: PayloadAction<{ from: string; to: string }>
    ) {
      state.customDate = action.payload;
    },
    setMirrorIds(
      state: PeopleCounterReportState,
      action: PayloadAction<number[]>
    ) {
      state.mirrorIds = action.payload;
    },
    fetchPeopleCounter(
      state: PeopleCounterReportState,
      action: PayloadAction<InterfaceParamsFetchPeopleCounter>
    ) {
      state.error = false;
      state.loading = true;
      state.counterRawData = [];
      state.groupByHour = action.payload.groupByHour;
      state.countByTime = action.payload.countByTime
        ? action.payload.countByTime
        : false;
    },
    peopleCounterRawDataFetched(
      state: PeopleCounterReportState,
      action: PayloadAction<{ total: number; result: PeopleCounterRawData[], lastUpdated: string }>
    ) {
      state.total = action.payload.total;
      state.lastUpdated = action.payload.lastUpdated;
      const selectedHour = [
        DATE_RANGE.SEVEN_DAYS_AGO_TIME,
        DATE_RANGE.THIRDTY_DAYS_AGO_TIME,
        DATE_RANGE.CUSTOM,
        DATE_RANGE.TODAY
      ].includes(state.dateRange);
      const byTime = state.customDate.from === state.customDate.to;
      if (selectedHour || byTime) {
        const arr = [];
        for (let i = 1; i <= 24; i++) {
          arr.push(i);
        }
        state.counterRawData = arr.map((x, index) => {
          const indexFind = action.payload.result.findIndex((item) => {
            return index === item.entry_hour;
          });
          if (indexFind != -1) {
            return {
              ...action.payload.result[indexFind],
              entry_time: moment(
                action.payload.result[indexFind].entry_date,
                'YYYYMMDD'
              )
                .utc()
                .valueOf(),
              exit_time: moment().endOf('date').utc().valueOf(),
              persons_count: Number(action.payload.result[indexFind].total),
              date: `${state.customDate.from} - ${state.customDate.to}`
            };
          }
          return x;
        });
      } else {
        const listCounter = action.payload.result.map((x) => {
          return {
            ...x,
            entry_time: moment(x.entry_date, 'YYYYMMDD').utc().valueOf(),
            exit_time: moment().endOf('date').utc().valueOf(),
            persons_count: Number(x.total),
            date: `${moment(x.entry_date, 'YYYYMMDD')
              .startOf('date')
              .format('YYYY-MM-DD')} - ${state.customDate.to}`
          };
        });
        if (listCounter?.length === 1) {
          // if listCounter just 1 item add more 1 mock data
          const mockDataPrevDay = listCounter?.[0];
          const entry_date = Number(
            moment(mockDataPrevDay.entry_date, 'YYYYMMDD')
              .add(1, 'day')
              .startOf('day')
              .format('YYYYMMDD')
          );
          listCounter.push({
            age_1: '0',
            age_2: '0',
            age_3: '0',
            age_4: '0',
            age_5: '0',
            age_na: '0',
            emotion_angry: '0',
            emotion_disgusty: '0',
            emotion_fear: '0',
            emotion_happy: '0',
            emotion_na: '0',
            emotion_neutral: '0',
            emotion_sad: '0',
            emotion_surprise: '0',
            gender_famale: '0',
            gender_male: '0',
            gender_na: '0',
            persons_count: 0,
            total: '0',
            entry_date: entry_date,
            entry_time: moment(mockDataPrevDay.entry_date, 'YYYYMMDD')
              .add(1, 'day')
              .utc()
              .valueOf(),
            exit_time: moment(mockDataPrevDay.entry_date, 'YYYYMMDD')
              .add(1, 'day')
              .endOf('day')
              .valueOf(),
            date: `${moment(entry_date, 'YYYYMMDD')
              .startOf('date')
              .format('YYYY-MM-DD')} - ${state.customDate.to}`
          });
        }
        const sortedData = listCounter.sort(function (a, b) {
          return a.entry_date - b.entry_date;
        });
        state.counterRawData = sortedData;
      }
      state.loading = false;
    },
    peopleCounterFetchedError(state: PeopleCounterReportState) {
      state.loading = false;
      state.error = true;
    },
    setCounterType(
      state: PeopleCounterReportState,
      action: PayloadAction<COUNTER_TYPE>
    ) {
      state.counterType = action.payload;
    },
    fetchPeopleNeutralVideoTracking(state: PeopleCounterReportState) {
      state.videoTrackingRawData = [];
      state.loading = true;
    },
    peopleNeutralVideoFetched(
      state: PeopleCounterReportState,
      action: PayloadAction<PeopleNeutralVideoTrackingRawData[]>
    ) {
      state.videoTrackingRawData = action.payload;
      state.loading = false;
    },
    peopleNeutralVideoFetchedError(state: PeopleCounterReportState) {
      state.error = true;
      state.loading = false;
    },
    exportCSV(
      state: PeopleCounterReportState,
      action: PayloadAction<{
        groupByHour?: boolean;
        isShowTotal?: boolean;
        // mirrorNames?: string[];
      }>
    ) {
      state.exporting = true;
    },
    csvExported(state: PeopleCounterReportState) {
      state.exporting = false;
    },
    exportNeutralVideoTracking(state: PeopleCounterReportState) {
      state.exporting = true;
    },
    exportedNeutralVideoTracking(state: PeopleCounterReportState) {
      state.exporting = false;
    },
    fetchFilters(state: PeopleCounterReportState) {},
    filtersFetched(
      state: PeopleCounterReportState,
      action: PayloadAction<FiltersPeopleCounterResult>
    ) {
      state.shopNames = action.payload.shopNames;
      state.mirrors = action.payload.mirrors;
    },
    fetchFiltersError(state: PeopleCounterReportState) {
      state.error = true;
    },
    setShopNameFilter(
      state: PeopleCounterReportState,
      action: PayloadAction<string>
    ) {
      state.shopNameFilter = action.payload;
    }
  }
});

export const { actions, reducer, name: sliceKey } = peopleCounterReportSlice;
