import {
  useContext,
  useEffect,
  useState,
  memo,
  Fragment,
  useMemo
} from 'react';
import { ListSubheader, Box, List, styled, Divider } from '@mui/material';
import { matchPath, useLocation } from 'react-router-dom';
import { MenuItem, MenuItems, menuItems } from './items';
import SidebarMenuItem from './item';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuthUser } from 'src/containers_v2/auth/store/selector';
import { UserRoles, USER_ROLE } from 'src/utils/constants';
import { actions as mirrorActions } from '../../../../containers/mirror/store/slice';
import versionApi from '../../../../api_v2/version/version-api';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { messages } from 'src/locales/messages';
import { SidebarContext } from 'src/contexts/SidebarContext';

const MenuWrapper = styled(List)(
  ({ theme }) => `
    margin-bottom: ${theme.spacing(1)};
    padding: 0;

    & > .MuiList-root {
      padding: 0 ${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(1)};
    }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.sidebar.menuItemHeadingColor};
      padding: ${theme.spacing(0.8, 2)};
      line-height: 1.4;
    }
`
);

const SubMenuWrapper = styled(List)(
  ({ theme }) => `
    &.MuiList-root {
      padding: 0;

      .MuiList-root .MuiList-root .MuiListItem-root .MuiButton-root {
        font-weight: normal !important;
      }

      .MuiListItem-root {
        padding: 2px ${theme.spacing(1)};
    
        .MuiButton-root {
          display: flex;
          color: ${theme.sidebar.menuItemColor};
          background-color: ${theme.sidebar.menuItemBg};
          width: 100%;
          justify-content: flex-start;
          font-size: ${theme.typography.pxToRem(13)};
          padding-top: ${theme.spacing(0.8)};
          padding-bottom: ${theme.spacing(0.8)};
          position: relative;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(4)};

            .MuiBadge-standard {
              background: ${theme.colors.primary.main};
              font-size: ${theme.typography.pxToRem(9)};
              font-weight: bold;
              text-transform: uppercase;
              color: ${theme.palette.primary.contrastText};
            }
          }
    
          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            font-size: ${theme.typography.pxToRem(26)};
            margin-right: ${theme.spacing(1.5)};
            color: ${theme.sidebar.menuItemIconColor};
          }
          
          .MuiButton-endIcon {
            margin-left: auto;
            font-size: ${theme.typography.pxToRem(22)};
          }

          &.Mui-active,
          &:hover {
            background-color: ${theme.sidebar.menuItemBgActive};
            color: ${theme.sidebar.menuItemColorActive};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
                color: ${theme.sidebar.menuItemIconColorActive};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;
          line-height: 1;
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px ${theme.spacing(0)};

            .MuiButton-root {
              font-size: ${theme.typography.pxToRem(13)};
              padding: ${theme.spacing(0.5, 2, 0.5, 4)};

              &.Mui-active,
              &:hover {
                background-color: ${theme.sidebar.menuItemBg};
              }
            }
          }
        }
      }
    }
`
);

const renderSidebarMenuItems = ({
  items,
  path,
  count
}: {
  items: MenuItem[];
  path: string;
  count: number;
}): JSX.Element => (
  <SubMenuWrapper>
    {items.reduce(
      (ev, item) => reduceChildRoutes({ ev, item, path, count }),
      []
    )}
  </SubMenuWrapper>
);

const reduceChildRoutes = ({
  ev,
  path,
  item,
  count = 1
}: {
  ev: JSX.Element[];
  path: string;
  item: MenuItem;
  collapseHeader?: boolean;
  count?: number;
}): Array<JSX.Element> => {
  const name = typeof item.name === 'string' ? item.name : i18n.t(item.name());
  const exactMatch = item.link
    ? !!matchPath(
        {
          path: item.link,
          end: true
        },
        path
      )
    : false;

  if (item.items) {
    const partialMatch = item.link
      ? !!matchPath(
          {
            path: item.link,
            end: false
          },
          path
        )
      : false;
    ev.push(
      <SidebarMenuItem
        key={name}
        active={partialMatch}
        open={partialMatch}
        name={name}
        icon={item.icon}
        link={item.link}
        badge={item.badge}
        roles={item.roles}
        count={count++}
      >
        {renderSidebarMenuItems({
          path,
          items: item.items,
          count: count
        })}
      </SidebarMenuItem>
    );
  } else {
    ev.push(
      <SidebarMenuItem
        key={name}
        active={exactMatch}
        name={name}
        link={item.link}
        badge={item.badge}
        icon={item.icon}
        roles={item.roles}
        count={count++}
      />
    );
  }

  return ev;
};

interface Props {}

const SidebarMenu: React.FC<Props> = () => {
  const { open } = useContext(SidebarContext);
  const [versionDate, setVersionDate] = useState({ releaseDate: '' });

  const location = useLocation();
  const dispatch = useDispatch();
  const role = useSelector(selectAuthUser).role;
  const mirrorIds = useSelector(selectAuthUser).mirrorIds;
  const isAdmin = role.includes(UserRoles.toString(UserRoles.Admin));
  const isMirrorOwner = role.includes(
    UserRoles.toString(UserRoles.Mirrors_owner)
  );
  const isMirrorViewer = role.includes(
    UserRoles.toString(UserRoles.Mirror_viewer)
  );
  const isAR =
    USER_ROLE.AR_owner === USER_ROLE.AR_owner ||
    USER_ROLE.AR_admin === USER_ROLE.AR_admin;

  useEffect(() => {
    if (!isAdmin && !isAR) dispatch(mirrorActions.fetchAllMirrors());
    (async () => {
      const res = await versionApi.fetchVersion();
      setVersionDate(res);
    })();
  }, []);

  useEffect(() => {
    if (!isAdmin && !isAR) dispatch(mirrorActions.fetchAllMirrors());
    (async () => {
      const res = await versionApi.fetchVersion();
      setVersionDate(res);
    })();
  }, []);

  const roleMenuItems = [];
  menuItems.forEach((item) => {
    roleMenuItems.push({
      heading: item.heading,
      items: item.items
        .map((value) => {
          if (value.roles.some((item1) => role.includes(item1))) {
            if ('items' in value)
              return {
                ...value,
                items: value.items.filter((x) =>
                  x.roles.some((item) =>
                    role.some((item1) => x.roles.includes(item1))
                  )
                )
              };
            return value;
          }
        })
        .filter((x) => x) // filter the menu with undefined items
    });
  });

  const { t } = useTranslation();

  return (
    <>
      {(isMirrorOwner || isMirrorViewer) && mirrorIds?.length === 0 ? (
        <Box />
      ) : (
        roleMenuItems
          .filter((x) => x.items.length > 0)
          .map((menu: MenuItems, index: number) => {
            return (
              <MenuWrapper
                key={index}
                subheader={
                  menu.heading && open ? (
                    <ListSubheader component="div" disableSticky>
                      {t(menu.heading())}
                    </ListSubheader>
                  ) : (
                    <Divider />
                  )
                }
              >
                {renderSidebarMenuItems({
                  items: menu.items,
                  path: location.pathname,
                  count: 1
                })}
              </MenuWrapper>
            );
          })
      )}

      {open && versionDate?.releaseDate && isAdmin && (
        <MenuWrapper
          style={{
            display: !open ? 'flex' : 'none',
            alignItems: 'end',
            flexWrap: 'wrap',
            alignContent: 'end'
          }}
        >
          <ListSubheader
            component="div"
            disableSticky
            style={{ width: '100%' }}
          >
            {process.env.NODE_ENV === 'production'
              ? t(messages.main.version['prod']())
              : t(messages.main.version['staging']())}
          </ListSubheader>
          <Box
            sx={{
              marginLeft: '16px',
              color: '#242E6F',
              fontSize: '1rem',
              width: '100%'
            }}
          >
            {t(messages.main.version.title())}:{' '}
            {moment(versionDate.releaseDate).format('YYYYMMDD')}
          </Box>
        </MenuWrapper>
      )}
    </>
  );
};

export default memo(SidebarMenu);
