import { AxiosResponse } from 'axios';
import agent from 'src/api_v2/base';
import { QrImageType } from 'src/containers_v2/categories/qr-images/qr-image-list/store/slice';
import { FetchQrImagesParams } from './types';

class QrImageApi {
  async fetchQrImages(
    params: FetchQrImagesParams
  ): Promise<{ data: QrImageType[]; totalItems: number }> {
    try {
      const response: AxiosResponse = await agent.get(
        `/qr-image?${params.toString()}`
      );

      if (response.status === 200) {
        return response.data;
      }

      return undefined;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async deleteQrImage(id: number) {
    try {
      const response: AxiosResponse = await agent.delete(`/qr-image/${id}`);

      if (response.status !== 200) {
        return false;
      }

      return true;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async saveQrImage(payload: QrImageType) {
    try {
      const response: AxiosResponse = await agent.post(`/qr-image`, payload);

      if (response.status !== 200 && response.status !== 201) {
        return false;
      }

      return true;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }


  async addQrImage(videos: FormData) {
    try {
      const response: AxiosResponse = await agent.post(
        `/qr-image/upload-qr-image`,
        videos
      );

      if(response.status !== 200 && response.status !== 201) {
        return {
          valid : false,
          data: response
        }
      }

      return {
        valid : true,
        data: response
      }
    } catch (err) {
      return {
        valid : false,
        data: err
      };
    }
  }

  async updateQrImage(qrImage: FormData) {
    try {
      const response: AxiosResponse = await agent.put(
        `/qr-image`,
        qrImage
      );

      if(response.status !== 200 && response.status !== 204) {
        return {
          valid : false,
          data: response
        }
      }

      return {
        valid : true,
        data: response
      }
    } catch (err) {
      return {
        valid : false,
        data: err
      };
    }
  }

  async getQrImages(id: string): Promise<any> {
    try {
      const response: AxiosResponse = await agent.get(
        `/qr-image/${id}`
      );

      if (response.status === 200) {
        return response.data;
      }

      return undefined
    } catch (err) {
      throw err;
    }
  }
}

const qrImageApi = new QrImageApi();
export default qrImageApi;
