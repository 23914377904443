import { AxiosResponse } from 'axios';
import agent from 'src/api_v2/base';
import { VideosConfigurationType } from 'src/containers_v2/categories/videos/videos-configuration/store/slice';
import { VideosType } from 'src/containers_v2/categories/videos/videos-list/store/slice';
import { FetchVideosParams } from './types';

class VideosApi {
  async fetchVideos(
    params: FetchVideosParams
  ): Promise<{ data: VideosType[]; totalItems: number }> {
    try {
      const response: AxiosResponse = await agent.get(
        `/videos?${params.toString()}`
      );

      if (response.status === 200) {
        return response.data;
      }

      return undefined;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async deleteVideos(id: number) {
    try {
      const response: AxiosResponse = await agent.delete(`/videos/${id}`);

      if (response.status !== 200) {
        return false;
      }

      return true;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async saveVideos(payload: VideosType) {
    try {
      const response: AxiosResponse = await agent.post(`/videos`, payload);

      if (response.status !== 200 && response.status !== 201) {
        return false;
      }

      return true;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }


  async addVideos(videos: FormData) {
    try {
      const response: AxiosResponse = await agent.post(
        `/videos/upload-video`,
        videos
      );

      if(response.status !== 200 && response.status !== 201) {
        return {
          valid : false,
          data: response
        }
      }

      return {
        valid : true,
        data: response
      }
    } catch (err) {
      return {
        valid : false,
        data: err
      };
    }
  }

  async updateVideos(videos: FormData) {
    try {
      const response: AxiosResponse = await agent.put(
        `/videos`,
        videos
      );

      if(response.status !== 200 && response.status !== 204) {
        return {
          valid : false,
          data: response
        }
      }

      return {
        valid : true,
        data: response
      }
    } catch (err) {
      return {
        valid : false,
        data: err
      };
    }
  }

  async getVideos(id: string): Promise<any> {
    try {
      const response: AxiosResponse = await agent.get(
        `/videos/${id}`
      );

      if (response.status === 200) {
        return response.data;
      }

      return undefined
    } catch (err) {
      throw err;
    }
  }
}

const videosApi = new VideosApi();
export default videosApi;
