import { AxiosResponse } from "axios";
import agent from "../base";
import { ARPamphletListPageResult, FetchARPamphletParams } from "./types";
import moment from 'moment'
import { helpers } from "src/utils/helpers";

class ARPamphletApi {
  async fetchList(params: FetchARPamphletParams) {
    try {
      const response: AxiosResponse = await agent.get(
        `/arprojects?${params.toString()}`
      );

      if (response.status === 200) {
        return response.data as ARPamphletListPageResult;
      }

      return [];
    } catch (err) {
      throw new Error(err);
    }
  }

  async deleteItem(id: number) {
    try {
      const response: AxiosResponse = await agent.delete(`/arprojects/${id}`);

      if (response.status !== 200) {
        return false;
      }

      return true;
    } catch (err) {
      throw err;
    }
  }

  async updateAndInsert(data: FormData) {
    try {
      const response: AxiosResponse = await agent.post(`/arprojects`, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if (response.status !== 200 && response.status !== 201) {
        return undefined;
      }

      return response.data;
    } catch (err) {
      return undefined;
    }
  }

  async get(id: string): Promise<any> {
    try {
      const response: AxiosResponse = await agent.get(`/arprojects/${id}`);

      if (response.status === 200) {
        return response.data;
      }

      return undefined;
    } catch (err) {
      throw err;
    }
  }

  async getQRcodeDynamic(id: string): Promise<any> {
    try {
      const response: AxiosResponse = await agent.get(
        `/arprojects/${id}/8wall-link`
      );

      if (response.status === 200) {
        return response.data;
      }

      return undefined;
    } catch (err) {
      throw err;
    }
  }

  async exportArPamphletCsv(params: FetchARPamphletParams) {
    try {
      const response = await agent.get(
        `/arprojects/export-csv/ar-pamphlet?${params.toString()}`,
        {
          responseType: 'blob'
        }
      );

      if (response.status === 200) {
        helpers.exportCSV(
          response.data,
          `ar-pamphlet-${moment().format("YYYY-MM-DD, HH-mm")}.csv`
        );

        return true;
      }
      return false;
    } catch (err) {
      throw err;
    }
  }

  async updateStatus(
    id: number,
    status: boolean
  ): Promise<boolean> {
    try {
      const response: AxiosResponse = await agent.put(
        `/arprojects/${id}/update-status`,
        {
          status
        }
      );

      if (response.status === 200) {
        return true;
      }

      return false;
    } catch (err) {
      return false
    }
  }
}

const arPamphletApi = new ARPamphletApi();
export default arPamphletApi;