import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { authRoutes, unAuthRoutes } from './router';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import ThemeProvider from './theme/ThemeProvider';
import { CssBaseline } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setAuth, setUser } from './redux/store/user/slice';
import './hooks/useRouteRoles';
import useRouteRoles from './hooks/useRouteRoles';
import jwt_decode from 'jwt-decode';
import { authActions } from './containers_v2/auth/store/slice';
import { selectAuthUser } from './containers_v2/auth/store/selector';
import GlobalStyles from './theme/globalStyles';
import { SnackbarProvider } from 'notistack';
import { UIActions } from './containers_v2/ui-configuration/store/slice';
import { isArray } from 'lodash';
import appConfig from 'src/config';

const App = () => {
  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authUser = useSelector(selectAuthUser);

  useEffect(() => {
    if(authUser?.id) {
      dispatch(authActions.checkUserArProjectCreationExpired())
    }
  }, [authUser])

  // const condition
  const mainRoutes = !token ? unAuthRoutes : authRoutes(authUser)
  const routes = useRouteRoles(
    mainRoutes,
    authUser.role
  );

  useEffect(() => {
    const unsubscribe = window.addEventListener('resize', () => {
      dispatch(UIActions.calculate());
    });

    return unsubscribe;
  }, [navigate]);

  useEffect(() => {
    if (!token) {
      return;
    }
    
    const decoded: any = jwt_decode(token);

    if (!isArray(decoded.role)) {
      localStorage.removeItem('token');
      localStorage.removeItem('username');
      navigate('/login');
    }
  }, []);

  useEffect(() => {
    if (token) {
      const decoded: any = jwt_decode(token);

      dispatch(
        authActions.loginSuccess({
          username: decoded.uuid,
          email: decoded.email ?? '',
          role: decoded.role,
          id: decoded.id,
          groupId: decoded.groupId,
          mirrorIds: decoded.mirrorIds
        })
      );
      dispatch(
        setUser({
          id: decoded.id,
          uuid: decoded.uuid,
          role: decoded.role
        })
      );
      dispatch(setAuth({ isAuth: true }));
    } else {
      // navigate('/login');
    }
  }, []);

  return (
    <SnackbarProvider maxSnack={3}>
      <ThemeProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <CssBaseline />
          <GlobalStyles />
          {routes}
        </LocalizationProvider>
      </ThemeProvider>
    </SnackbarProvider>
  );
};
export default App;
