import { MirrorType } from 'src/containers_v2/mirror-configuration/mirror/mirror-list/store/slice';
import IPagination from 'src/models/pagination';
import { ProfileVoiceoverResponseType } from '../profile/types';

export type MirrorVoiceoverResponseType = ProfileVoiceoverResponseType & {
  mirrorId: number;
  isOverrideVoiceOver: boolean;
};

export type MirrorListPageResult = {
  data: MirrorType[];
  totalCount: number;
};

export class FetchMirrorListParams implements IPagination {
  page: number;
  pageSize: number;
  search: string;
  sort: string;
  mirrorId: string;
  profile: string;
  status: string;
  shopName: string;

  constructor(
    page: number = 1,
    pageSize: number = 10,
    sort: string = 'name|asc',
    mirrorId: string,
    profile: string,
    status: string,
    shopName: string = ''
  ) {
    this.page = page;
    this.pageSize = pageSize;
    this.sort = sort;
    this.mirrorId = mirrorId;
    this.profile = profile;
    this.status = status;
    this.shopName = shopName;
  }

  toString() {
    return `page=${this.page}&size=${this.pageSize}&sort=${this.sort}&mirrorId=${this.mirrorId}&profileId=${this.profile}&status=${this.status}&shopName=${this.shopName}`;
  }
}
