import { AxiosResponse } from 'axios';
import agent from 'src/api_v2/base';
import { ConfigurationType } from 'src/containers_v2/ar_creator/ARProject/configuration/store/slice';
import {
  FetchARProjectList,
  ARProjectListPageResult,
  FetchARScanStatictisParams,
  ARScanStatictisPageResult
} from './types';
import momemt from 'moment';
import { helpers } from 'src/utils/helpers';
import { ARProjectType } from 'src/containers_v2/monitoring/store/models';
class ARProjecApi {
  async downloadQRCode({ link, name }) {
    try {
      const response: AxiosResponse = await agent.get(
        `/arprojects/downloadqr?link=${link}&name=${name}`,
        {
          responseType: 'blob'
        }
      );
      const blobUrl = URL.createObjectURL(response.data);
      const fileName = response?.headers?.['content-disposition']
        .split('filename=')[1]
        .split(';')[0];
      return {
        fileName,
        blobUrl
      };
    } catch (err) {
      return err;
    }
  }

  async fetchList(params: FetchARProjectList) {
    try {
      const response: AxiosResponse = await agent.get(
        `/arprojects?${params.toString()}`
      );

      if (response.status === 200) {
        return response.data as ARProjectListPageResult;
      }

      return [];
    } catch (err) {
      throw new Error(err);
    }
  }

  async deleteItem(id: number) {
    try {
      const response: AxiosResponse = await agent.delete(`/arprojects/${id}`);

      if (response.status !== 200) {
        return false;
      }

      return true;
    } catch (err) {
      throw err;
    }
  }

  async updateAndInsert(data: FormData) {
    try {
      const response: AxiosResponse = await agent.post(`/arprojects`, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if (response.status !== 200 && response.status !== 201) {
        return undefined;
      }

      return response.data;
    } catch (err) {
      return undefined;
    }
  }

  async update(data: ConfigurationType) {
    try {
      const response: AxiosResponse = await agent.put(`/arprojects`, data);

      if (response.status !== 200 && response.status !== 204) {
        return false;
      }

      return true;
    } catch (err) {
      return false;
    }
  }

  async get(id: string): Promise<any> {
    try {
      const response: AxiosResponse = await agent.get(`/arprojects/${id}`);

      if (response.status === 200) {
        return response.data;
      }

      return undefined;
    } catch (err) {
      throw err;
    }
  }

  async getARProjectTracking(
    id: string,
    from: number,
    to: number
  ): Promise<any> {
    try {
      const response: AxiosResponse = await agent.get(
        `/arprojects/tracking/${id}?from=${from}&to=${to}`
      );

      if (response.status === 200) {
        return response.data;
      }

      return undefined;
    } catch (err) {
      throw err;
    }
  }

  async trackingUnscreenHistory(arId: number, videoLink: string): Promise<any> {
    try {
      const response: AxiosResponse = await agent.post(
        `/arprojects/unscreen-history`,
        {
          arId,
          videoLink
        }
      );

      if (response.status === 200) {
        return response.data;
      }

      return false;
    } catch (err) {
      throw err;
    }
  }

  async exportUnscreenHistory(type: number): Promise<any> {
    try {
      const response: AxiosResponse = await agent.post(
        `/arprojects/export-csv/unscreen-history?type=${type}`,
        {
          responseType: 'blob'
        }
      );

      if (response.status === 201) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.setAttribute(
          'download',
          `ar-unscreen-video-${momemt().format('YYYY-MM-DD, HH-mm')}.csv`
        );
        link.href = url;
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);

        return true;
      }
      return false;
    } catch (err) {
      throw err;
    }
  }

  async getQRcodeDynamic(id: string): Promise<any> {
    try {
      const response: AxiosResponse = await agent.get(
        `/arprojects/${id}/8wall-link`
      );

      if (response.status === 200) {
        return response.data;
      }

      return undefined;
    } catch (err) {
      throw err;
    }
  }

  async searchScanStatictis(
    params: FetchARScanStatictisParams
  ): Promise<ARScanStatictisPageResult> {
    try {
      const response: AxiosResponse = await agent.get(
        `/arprojects/scan-statictis/search?${params.toString()}`
      );

      if (response.status === 200) {
        return response.data as ARScanStatictisPageResult;
      }

      return undefined;
    } catch (err) {
      throw new Error(err);
    }
  }

  async exportScanStatictis(params: FetchARScanStatictisParams) {
    try {
      const response = await agent.post(
        `/arprojects/export-csv/scan-statictis?${params.toString()}`,
        {
          responseType: 'blob'
        }
      );

      if (response.status === 201) {
        helpers.exportCSV(
          response.data,
          `${
            params.type === ARProjectType.NAMECARD ? 'namecard' : 'pamphlet'
          }-scan-statictis-${momemt().format('YYYY-MM-DD, HH-mm')}.csv`
        );

        return true;
      }
      return false;
    } catch (err) {
      throw err;
    }
  }

  async updateStatus(
    id: number,
    status: boolean
  ): Promise<boolean> {
    try {
      const response: AxiosResponse = await agent.put(
        `/arprojects/${id}/update-status`,
        {
          status
        }
      );

      if (response.status === 200) {
        return true;
      }

      return false;
    } catch (err) {
      return false
    }
  }

  async generateAudio(
    text: string,
  ) {
    try {
      const response: AxiosResponse = await agent.get(
        `/arprojects/audio/synthesize?text=${encodeURIComponent(text)}`,
      );

      if (response.status === 200) {
        return response.data;
      }

      return null;
    } catch (err) {
      return null
    }
  }
}

const arProjectApi = new ARProjecApi();
export default arProjectApi;
