import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  FetchUserAuditListParams,
  UserAuditListPageResult
} from 'src/api_v2/user-audit/types';
import userAuditApi from 'src/api_v2/user-audit/user-audit-list-api';
import {
  selectPage,
  selectPageSize,
  selectSearch,
  selectSort
} from './selector';
import { actions } from './slice';

function* fetchUserAuditList() {
  try {
    const page = yield select(selectPage);
    const pageSize = yield select(selectPageSize);
    const search = yield select(selectSearch);
    const sort = yield select(selectSort);
    const queryParams = new FetchUserAuditListParams(page, pageSize, search, sort);
    const result: UserAuditListPageResult = yield call(
      userAuditApi.fetchUserAuditList,
      queryParams
    );

    yield put(actions.userAuditListFetched(result));
  } catch (err) {
    console.log(err);
    yield put(actions.userAuditListFetchedError());
  }
}

function* fetchTotalUserAuditList() {
  try {
    const search = yield select(selectSearch);
    const sort = yield select(selectSort);

    const queryParams = new FetchUserAuditListParams(
      1,
      Number.MAX_SAFE_INTEGER,
      search,
      sort
    );
    const result: UserAuditListPageResult = yield call(
      userAuditApi.fetchUserAuditList,
      queryParams
    );

    yield put(actions.userAuditListFetched(result));
  } catch (err) {
    console.log(err);
    yield put(actions.userAuditListFetchedError());
  }
}

function* exportFile() {
  const page = yield select(selectPage);
  const pageSize = yield select(selectPageSize);
  const search = yield select(selectSearch);
  const sort = yield select(selectSort);
  const queryParams = new FetchUserAuditListParams(page, pageSize, search, sort);

  try {
    yield call(userAuditApi.exportFile,queryParams)

    yield put(actions.unscreenHistoryExported())
  } catch (err) {
    console.log(err);
    yield put(actions.unscreenHistoryExportedFail());
  }
}

export default function* watchUserAuditListSaga() {
  yield takeLatest(actions.fetchUserAuditList.type, fetchUserAuditList);
  yield takeEvery(actions.fetchTotalUserAuditList.type, fetchTotalUserAuditList);
  yield takeEvery(actions.exportFile.type, exportFile);
}
