import { AxiosResponse } from 'axios';
import agent from 'src/api_v2/base';
import { UserConfigurationType } from 'src/containers_v2/user-management/user-configuration/store/slice';
import { FetchUserListParams, UserListPageResult } from './types';

class UserManagementApi {
  async fetchUserList(params: FetchUserListParams) {
    try {
      const response: AxiosResponse = await agent.get(
        `/users?${params.toString()}`
      );

      if (response.status === 200) {
        return response.data as UserListPageResult;
      }

      return [];
    } catch (err) {
      console.log(err);
      throw new Error(err);
    }
  }

  async fetchUserListCustom(params: FetchUserListParams) {
    try {
      const response: AxiosResponse = await agent.get(
        `/users/find-all-custom?${params.toString()}`
      );

      if (response.status === 200) {
        return response.data as UserListPageResult;
      }

      return [];
    } catch (err) {
      console.log(err);
      throw new Error(err);
    }
  }

  async deleteUser(id: number) {
    try {
      const response: AxiosResponse = await agent.delete(`/users/${id}`);

      if (response.status !== 200) {
        return false;
      }

      return true;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async addUser(user: UserConfigurationType) {
    try {
      const response: AxiosResponse = await agent.post(`/users`, user);

      if (response.status !== 200 && response.status !== 201) {
        return {
          data: {},
          status: false
        };;
      }

      return {
        data: response,
        status: true
      };
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async updateUser(user: UserConfigurationType) {
    try {
      const response: AxiosResponse = await agent.put(`/users`, {
        id: user.id,
        email: user.email,
        role: user.role,
        zipCode: user.zipCode,
        isActivated: user.isActivated,
        groupId: user.groupId,
        mirrorIds: user.mirrorIds
      });

      if (response.status !== 200 && response.status !== 204) {
        return {
          data: {},
          status: false
        };;
      }

      return {
        data: response,
        status: true
      };
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async getUser(id: string): Promise<any> {
    try {
      const response: AxiosResponse = await agent.get(`/users/${id}`);

      if (response.status === 200) {
        return response.data;
      }

      return undefined;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async getMirrorsAssigned(id: string): Promise<any> {
    try {
      const response: AxiosResponse = await agent.get(`/users/${id}/assign-mirrors`);

      if (response.status === 200) {
        return response.data;
      }

      return undefined;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async postMirrorsAssigned(data) {
    try {
      const response: AxiosResponse = await agent.post(`/users/assign-mirrors`, data);

      if (response.status !== 200 && response.status !== 201) {
        return false;
      }

      return true;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
}

const userApi = new UserManagementApi();
export default userApi;
