import { AxiosResponse } from 'axios';
import { ResetPasswordType } from 'src/containers_v2/auth/forgot-password/store/slice';
import agent from '../base';

class AuthApi {
  async sendResetPasswordLink(email: string) {
    try {
      const response: AxiosResponse = await agent.post(
        `/account/reset-password/init`,
        { email }
      );

      if (response.status !== 200) {
        return false;
      }

      return true;
    } catch (err) {
      return false
    }
  }

  async resetPassword(payload: ResetPasswordType) {
    try {
      const response: AxiosResponse = await agent.post(
        `/account/reset-password/finish`,
        payload
      );

      if (response.status !== 200) {
        return false;
      }

      return true;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async login(email: string, password: string) {
    try {
      const response: AxiosResponse = await agent.post(`/auth/login`, {
        email,
        password
      });

      if (response.status !== 200 && response.status !== 201) {
        return '';
      }

      return response.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  async changePassword(email: string,oldPassword: string, password: string) {
    try {
      const response: AxiosResponse = await agent.post(`/account/change-password`, {
        email,
        oldPassword,
        password
      });

      if (response.status !== 200 && response.status !== 201) {
        return false;
      }

      return true;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
}

const authApi = new AuthApi();
export default authApi;
